import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Alert, Card, CardBody, Container, Button } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images

import CarouselPage from "./CarouselPage"

import { changePassword, 
   changePassword as onChangePassword ,
   changePasswordSuccessReset as onChancePasswordSuccessReset


} from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

const ResetPassword = props => {
  const dispatch = useDispatch()
  const { error, changePasswordSuccess,data } = useSelector(state => ({
    error: state.changePassword?.error,
    changePasswordSuccess: state.changePassword?.changePasswordSuccess,
    data: state.changePassword?.data,
  }))

  const [show, setShow] = useState(false)

  const {
    match: { params },
  } = props

  useEffect(() => {
   // console.log(params.id, params.hash) 
  }, [dispatch])

  const handleModal = (e, values) => {
    if (show) {
      localStorage.setItem("BranchId", values?.BranchId)
    }
    setShow(!show)
  }

  // handleValidSubmit
  const handleValidSubmit = values => {
    const newPassword = {
      id: params.id,
      hash: params.hash,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    }
   
    dispatch(onChangePassword(newPassword))
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Register 2 | Bu QR'da İş Var</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div >
                    <div className="mb-4 mb-md-5">
                    
                    </div>
                    <div className="my-auto">
                      {!changePasswordSuccess ? (
                        <div className="mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(v)
                            }}
                            
                          >
                             {changePasswordSuccess ? (
                            <SweetAlert
                              success
                              title={props.t("Success")}
                              confirmBtnText={props.t("Ok")}
                              onConfirm={() => {
                                dispatch(onChancePasswordSuccessReset())
                               
                              }}
                            >
                              {props.t("Şifre Değiştirme İşlemi Başarılı")}
                            </SweetAlert>
                          ) : null}
                            {error && error ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}
                              {data?.status === true ? (
                            <Alert color="success">
                              {data?.message}
                            </Alert>
                          ) : null}

                            <div className="mb-3">
                              <AvField
                                name="password"
                                label={props.t("New Password")}
                                type="password"
                                errorMessage={props.t("InvalidInput")}
                                validate={{
                                  required: { value: true },
                                }}
                                value={""}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="passwordConfirm"
                                label={props.t("Confirm New Password")}
                                type="password"
                                errorMessage={props.t("InvalidInput")}
                                validate={{
                                  required: { value: true },
                                }}
                                value={""}
                              />
                            </div>

                            <div className="mt-4">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                {props.t("Edit Password")}
                              </button>
                            </div>
                          </AvForm>

                          <div className="mt-5 text-center">
                            <p>
                              <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                {props.t("Login 2")}
                              </Link>{" "}
                            </p>
                          </div>
                        </div> 
                      ) : (
                        <div className="alert alert-success h3 text-center">
                        {props.t("Şifreniz Başarıyla Değiştirildi")} 
                        <br />
                        <i className="fa fa-check"></i>
                        <hr />
                        <Link to="/Login" className="font-size-13">{props.t("Login")}</Link>
                      </div>
                      )}
                    </div>
                   
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©{" "}
                        <script>
                          document.write(new Date().getFullYear())
                        </script>{" "}
                        2020. Aviyiva Tarafından{" "}
                        <i className="mdi mdi-heart text-danger"></i>{" "}
                        Hazırlanmıştır.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ResetPassword)
