import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input, Form, Label, FormGroup, Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

  InputGroupAddon,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
  addLink as onAddLink,
  addVcard as onAddVCard,
  detailVcard as onDetailVCard
} from "store/actions"
import imgEmpty from "./../../../assets/images/default-user-pic.png";
import phoneImg from "./../../../assets/images/Phone-PNG-File-300x300.png"
import mailImg from "./../../../assets/images/84-840959_email-comments-call-and-email-symbol.png"
import vcardImg from "./../../../assets/images/vcard.png"
import { isEmpty, map, property } from "lodash"
import EmbeddedCard from "./../Cards/EmbeddedCard"
import { Link, withRouter } from "react-router-dom"
import config from "config"
//i18n
import { withTranslation } from "react-i18next"
import Dropzone from "react-dropzone"
import classnames from "classnames"
import InputMask from "react-input-mask"
const AddLinkModal = (props, cb) => {

  const { error, User, Links, VCardInfo } = useSelector(state => ({
    error: state.Dashboard?.error,
    User: state.Dashboard?.user,
    Links: state.Dashboard?.user?.Links,
    VCardInfo: state.VCard?.detail[0]?.Infos

  }))
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [selectedFiles, setselectedFiles] = useState([])
  const [isMenu, setIsMenu] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [selectedEmbedded, setSelectedEmbedded] = useState("")
  const [photoSwitch, setPhotoSwitch] = useState(false)
  const [nameSwitch, setNameSwitch] = useState(false)
  const [phoneSwitch, setPhoneSwitch] = useState(false)
  const [birthdaySwitch, setBirthdaySwitch] = useState(false)
  const [jobInCompanySwitch, setJobInCompanySwitch] = useState(false)
  const [companyNameSwitch, setCompanyNameSwitch] = useState(false)
  const [emailSwitch, setEmailSwitch] = useState(false)
 
  let LocalUser = JSON.parse(localStorage.getItem("authUser"))
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  useEffect(() => {
    setActiveTab(props.activeTab)
  }, [props.toggle]);
  useEffect(() => {
    dispatch(onDetailVCard(LocalUser?.uid))
  }, [User]);
  useEffect(() => {
  console.log(selectedFiles)
  setselectedFiles([])
  }, [props.modal]);


  useEffect(() => {
    if (VCardInfo) {
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("birthday") && Card.permission == true ? (

          setBirthdaySwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("name") && Card.permission == true ? (

          setNameSwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("phone") && Card.permission == true ? (

          setPhoneSwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("companyName") && Card.permission == true ? (

          setCompanyNameSwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("jobInCompany") && Card.permission == true ? (

          setJobInCompanySwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("email") && Card.permission == true ? (

          setEmailSwitch(true)
        ) : null

      ))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("photo") && Card.permission == true ? (

          setPhotoSwitch(true)
        ) : null

      ))

    }

  }, [VCardInfo]);

  useEffect(() => {


    if (props.LinkName == "VCard") {
      setSelectedEmbedded("vCard")
    } else {
      setSelectedEmbedded("")
    }
  }, [props.toggle])

  const handleValidLinkSubmit = (e, values) => {
    if (selectedEmbedded == "vCard") {
      let CardData = [{ name: "photo", permission: photoSwitch }, { name: "birthday", permission: birthdaySwitch }, { name: "name", permission: nameSwitch }, { name: "phone", permission: phoneSwitch },{ name: "companyName", permission: companyNameSwitch },{ name: "jobInCompany", permission: jobInCompanySwitch },{ name: "email", permission: emailSwitch },{ name: "photo", permission: photoSwitch }];
      const Data = {
        UserId: LocalUser?.uid,
        Infos: JSON.stringify(CardData)

      } 
      dispatch(onAddVCard(Data))

    }
    if (props.isEdit) {
      Links[props.editLink[0]?.index] = { name: values.title, link: values.url, photo: values?.photo, count: props.editLink[0].count }
      const Link = {
        _id: user.uid,
        index: props.editLink[0]?.index + 1,
        links: JSON.stringify(Links)
      }

      dispatch(onAddLink(Link, selectedFiles))
      setSelectedEmbedded("")
    } else {
      if (selectedEmbedded == "Phone" ? Links.push.apply(Links, [{ name: values.title, link: `tel:+90${values.url}` }])
        : selectedEmbedded == "Mail" ? Links.push.apply(Links, [{ name: values.title, link: `mailto:${values.url}` }])
          : selectedEmbedded == "vCard" ? Links.push.apply(Links, [{ name: values.vCardTitle, link: ` `, isType: "VCard" }])
            : Links.push.apply(Links, [{ name: values.title, link: values.url }]))

        console.log(values)
      const Link = {
        _id: user.uid,
        index: Links.length,
        links: JSON.stringify(Links)
      }
      dispatch(onAddLink(Link, selectedFiles))
      setSelectedEmbedded("")
    }
    props.save(true)
  }
  const toggleMenu = () => {
    setIsMenu(!isMenu)
  }
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files);
   
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <Modal isOpen={props.modal} size="lg" className="modal-width" toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag="h4">
        <Nav tabs className="nav-tabs-custom" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              {props.t("Add Link")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              {props.t("Add Embedded Link")}
            </NavLink>
          </NavItem>
        </Nav>

      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="12">


            <TabContent
              activeTab={activeTab}

            >
              <TabPane tabId="1" id="buy">
                <AvForm onValidSubmit={handleValidLinkSubmit} autoComplete={"off"}>
                  <Row form>

                    {selectedEmbedded == "vCard" ? (

                      <div>
                        <h4 style={{ fontSize: "13px" }}>Vcard içerisinde Olmasını İstediğiniz Bilgileri Seçin</h4>
                        <hr />
                        <Col md="10">
                          <div className="mb-3">
                            <AvField
                              name="vCardTitle"
                              label={props.t("Title")}
                              type="text"
                              errorMessage={props.t("InvalidInput")}

                              validate={{
                                required: { value: true },
                              }}

                              value={selectedEmbedded == "Phone" ? "Telefon" : selectedEmbedded == "Mail" ? "Mail" : selectedEmbedded == "vCard" ? "Kartvizit" : props.editLink[0]?.name || ""}
                            />
                          </div>
                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="name"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Name")}
                          </label>
                          <input
                            type="checkbox"
                            name="name"
                            id="name"
                            className="form-check-input"
                            checked={nameSwitch}
                            onChange={(e) => {
                              setNameSwitch(!nameSwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="phone"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Phone Number")}
                          </label>
                          <input
                            type="checkbox"
                            name="phone"
                            id="phone"
                            className="form-check-input"
                            checked={phoneSwitch}
                            onChange={(e) => {
                              setPhoneSwitch(!phoneSwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="Birthday"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Birthday")}
                          </label>
                          <input
                            type="checkbox"
                            name="Birthday"
                            id="Birthday"
                            className="form-check-input"
                            checked={birthdaySwitch}
                            onChange={(e) => {
                              setBirthdaySwitch(!birthdaySwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="jobInCompany"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Job In the Company")}
                          </label>
                          <input
                            type="checkbox"
                            name="jobInCompany"
                            id="cashSale"
                            className="form-check-input"
                            checked={jobInCompanySwitch}
                            onChange={(e) => {
                              setJobInCompanySwitch(!jobInCompanySwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="companyName"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("companyName")}
                          </label>
                          <input
                            type="checkbox"
                            name="companyName"
                            id="cashSale"
                            className="form-check-input"
                            checked={companyNameSwitch}
                            onChange={(e) => {
                              setCompanyNameSwitch(!companyNameSwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="email"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Email")}
                          </label>
                          <input
                            type="checkbox"
                            name="email"
                            id="email"
                            className="form-check-input"
                            checked={emailSwitch}
                            onChange={(e) => {
                              setEmailSwitch(!emailSwitch);

                            }}
                          />

                        </Col>
                        <Col
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="photo"
                            style={{ marginRight: '54px' }}
                          >
                            {props.t("Photo")}
                          </label>
                          <input
                            type="checkbox"
                            name="photo"
                            id="photo"
                            className="form-check-input"
                            checked={photoSwitch}
                            onChange={(e) => {
                              setPhotoSwitch(!photoSwitch);

                            }}
                          />

                        </Col>

                      </div>


                    ) : <Row>
                      <Col md="12">
                        <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                          <div>
                            {props.editLink[0]?.photo ? 
                           
                            <img src={config.baseImg + props.editLink[0]?.photo} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                             :  <img src={imgEmpty} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                            }

                          </div>
                          {/* {selectedEmbedded=="Phone"? handleAcceptedFiles(acceptedFiles):null} */}
                          <div style={{ width: "65%", border: "1px solid #ced4da", borderRadius: "10px", height: "150px" }} >
                            <Dropzone

                              multiple={false}
                              accept="image/jpeg, image/png"
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                                console.log("phone", phoneImg)
                              }}

                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone-min" style={{ wordBreak: "break-all" }} >
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick" style={{ padding: "0px" }}>
                                      <div>
                                        <i className="display-5 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>{props.t("Drop Image")}</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews"
                              id="file-previews"
                              style={{ position: "absolute", zIndex: 99, left: 0, top: -5 }}
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <div className="align-items-center">
                                        <div className="col-12">
                                          <img
                                            data-dz-thumbnail=""
                                            style={{ width: "182px", height: "108px", objectFit: "contain" }}
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>

                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <AvField
                            name="title"
                            label={props.t("Title")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}

                            validate={{
                              required: { value: true },
                            }}

                            value={selectedEmbedded == "Phone" ? "Telefon" : selectedEmbedded == "Mail" ? "Mail" : selectedEmbedded == "vCard" ? "VCard" : props.editLink[0]?.name || ""}
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <div className="mb-3">
                          {selectedEmbedded == "Phone" || selectedEmbedded == "vCard" ? (
                            <AvField
                              name="url"
                              label={props.t("Phone Number")}
                              type="text"
                              mask="(999) 999-9999"
                              maskChar="-"
                              tag={[Input, InputMask]}
                              placeholder="(999) 999-9999"
                              errorMessage={props.t("InvalidInput")}
                              validate={{
                                required: { value: true },
                              }}
                              value={"tel:"}
                            />
                          ) : <AvField
                            name="url"
                            label={selectedEmbedded == "Mail" ? props.t("Mail") : props.t("Url")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: true },
                            }}
                            value={props.editLink[0]?.link || ""}
                          />}

                        </div>
                      </Col>
                    </Row>}


                    <Row className="text-end">
                      <Col md="12">
                        <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                          type="submit"
                          className="btn  save-user me-3"
                        >
                          {props.t("Save")}
                        </button>
                        {!!props.isEdit ? (<button style={{ backgroundColor: "#rgb(255, 81, 81)", borderRadius: "7px" }}
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            props.confirmAlert(true)

                          }}
                        >
                          {props.t("Delete")}
                        </button>
                        ) : null}
                      </Col>
                    </Row>
                  </Row>
                </AvForm>

              </TabPane>

              <TabPane tabId="2">
                <Row>
                  <Col md="3" sm="6" xs="5">
                    <EmbeddedCard name={"Telefon"} photo={phoneImg}
                      onClickFunc={(e) => {
                        setSelectedEmbedded("Phone")
                        toggleTab("1")

                      }} />
                  </Col>
                  <Col md="3" sm="6" xs="5">
                    <EmbeddedCard name={"Mail"} photo={mailImg}
                      onClickFunc={(e) => {
                        setSelectedEmbedded("Mail")
                        toggleTab("1")

                      }} />
                  </Col>
                  <Col md="3" sm="6" xs="5">
                    <EmbeddedCard name={"Kartvizit"} photo={vcardImg}
                      onClickFunc={(e) => {
                        setSelectedEmbedded("vCard")
                        toggleTab("1")

                      }} />
                  </Col>

                </Row>


              </TabPane>
            </TabContent>



          </Col>
        </Row>
      </ModalBody>

    </Modal>

  )

}


AddLinkModal.propTypes = {

  modal: PropTypes.bool,
  isEdit: PropTypes.bool,
  editLink: PropTypes.array,
  continue: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,
  save: PropTypes.func,
  confirmAlert: PropTypes.func,
  LinkName: PropTypes.string,
  activeTab :PropTypes.any
}


export default withRouter(
  (withTranslation()(AddLinkModal))
)
