import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { UPDATE_PROFILE,GET_USER,ADD_LINK,ADD_SOCIAL_LINK,CHECK_USER_NAME,GET_BY_USERNAME,UPDATE_THEME,GET_LIST,UPDATE_PROFILE_PHOTO,ADD_PROFILE_INFO,ADD_STYLE,
  GET_PROFILES,UPDATE_BACKGROUND_PHOTO,ADD_PROFILE,ADD_GUEST,GET_CORPORATE_LIST,GET_PARENT_DATA,UPDATE_GUEST,DELETE_GUEST,
  COMBINE_PROFILE,COMBINE,UPDATE_PLAN_ACTIVE} from "./actionTypes";
import {
   updateProfileSuccess, updateProfileFail,
   getUserSuccess,getUserFail,
   addLinkSuccess,addLinkFail,
   addSocialLinkSuccess,addSocialLinkFail,
   checkUserNameSuccess,checkUserNameFail,
   getByUsernameSuccess,getByUsernameFail,
   updateThemeSuccess,updateThemeFail,
   getListSuccess,getListFail,
   updateProfilePhotoSuccess,updateProfilePhotoFail,
   addProfileInfoSuccess,addProfileInfoFail,
   addStyleSuccess,addStyleFail, 
   updateBackgroundPhotoSuccess,updateBackgroundPhotoFail,
   addProfileSuccess,addProfileFail,
   getProfilesSuccess,getProfilesFail,
   addGuestSuccess,addGuestFail,
   getCorporateListSuccess,getCorporateListFail,
   getParentDataSuccess,getParentDataFail,
   updateGuestSuccess,updateGuestFail,
   deleteGuestSuccess,deleteGuestFail,
   combineProfileSuccess,combineProfileFail,
   combineSuccess,combineFail,
   updatePlanActiveSuccess,updatePlanActiveFail
  
  } from "./actions";

//Include Both Helper File with needed methods 
import {
    updateProfile,
    getUserById,
    addLink,
    addSocialLink,
    checkUserName,
    getUserByUsername,
    updateTheme,
    updateProfilePhoto,
    getUser,
    addProfileInfo,
    addStyle,
    backGroundPhotoUpdate,addProfile,getProfile,
    addGuest,
    getGuest,getUserParent
    ,updateGuest,delGuest,
    combineProfileMail,
    combineF,updatePlanActive

     //API CONNECTION
   
  } from "../../helpers/fakebackend_helper"
  function* onUpdatePlanActive({payload:plan}){
    try{
        const response = yield call(updatePlanActive, plan)
        yield put(updatePlanActiveSuccess(response));
    } catch (error) {
        yield put(updatePlanActiveFail(error));
    }
} 
  function* fetchGuest({ payload: guest, file: file}) {
    try {
        const response = yield call(addGuest,guest,file)
        yield put(addGuestSuccess(response));
    } catch (error) {
        yield put(addGuestFail(error));
    }
}
function* combine({ payload: email}) {
  try {
      const response = yield call(combineF,email)
      yield put(combineSuccess(response));
  } catch (error) {
      yield put(combineFail(error));
  }
}
function* combineProfile({ payload: email}) {
  try {
      const response = yield call(combineProfileMail,email)
      yield put(combineProfileSuccess(response));
  } catch (error) {
      yield put(combineProfileFail(error));
  }
}
function* onDeleteGuest({payload:id}){
  try{
      const response = yield call(delGuest, id)
      yield put(deleteGuestSuccess(response));
  } catch (error) {
      yield put(deleteGuestFail(error));
  }
}
function* fetchUpdateGuest({ payload: guest, file: file}) {
  try {
    console.log("saga gekdşn")
      const response = yield call(updateGuest,guest,file)
      yield put(updateGuestSuccess(response));
  } catch (error) {
      yield put(updateGuestFail(error));
  }
}
function* fetchCorporateList({ payload: user}) {
  try { 
      const response = yield call(getGuest,user)
      yield put(getCorporateListSuccess(response));
  } catch (error) {
      yield put(getCorporateListFail(error));
  }
}
  function* getProfiles({ payload: mail}) {
    try {
        const response = yield call(getProfile,mail)
        yield put(getProfilesSuccess(response));
    } catch (error) {
        yield put(getProfilesFail(error));
    }
}
  function* PostProfile({ payload: profile}) {
    try {
        const response = yield call(addProfile,profile)
        yield put(addProfileSuccess(response));
    } catch (error) {
        yield put(addProfileFail(error));
    }
}
  function* fetchUserByUsername({ payload: user}) {
    try {
        const response = yield call(getUserByUsername,user)
        yield put(getByUsernameSuccess(response));
    } catch (error) {
        yield put(getByUsernameFail(error));
    }
}
function* fetchParentData({ payload: user}) {
  try {
      const response = yield call(getUserParent,user)
      yield put(getParentDataSuccess(response));
  } catch (error) {
      yield put(getParentDataFail(error));
  }
}
  function* fetchUser({ payload: user}) {
    try {
        const response = yield call(getUserById,user)
        yield put(getUserSuccess(response));
    } catch (error) {
        yield put(getUserFail(error));
    }
}
function* getList({ payload: user}) {
  try {
      const response = yield call(getUser,user)
      yield put(getListSuccess(response));
  } catch (error) {
      yield put(getListFail(error));
  }
}
function* onUpdateTheme({ payload: companyId}) {
  try {
    const response = yield call(updateTheme, companyId)
    yield put(updateThemeSuccess(response))
  } catch (error) {
    yield put(updateThemeFail(error))
  }
}
function* onUpdateBackGroundPhoto({ payload: companyId, file: file}) {
  try {
    const response = yield call(backGroundPhotoUpdate, companyId,file)
    yield put(updateBackgroundPhotoSuccess(response))
  } catch (error) {
    yield put(updateBackgroundPhotoFail(error))
  }
}
function* onUpdateProfilePhoto({ payload: companyId, file: file}) {
  try {
    const response = yield call(updateProfilePhoto, companyId,file)
    yield put(updateProfilePhotoSuccess(response))
  } catch (error) {
    yield put(updateProfilePhotoFail(error))
  }
}
function* onUpdateProfile({ payload: companyId, file: file}) {
    try {
      const response = yield call(updateProfile, companyId,file)
      yield put(updateProfileSuccess(response))
    } catch (error) {
      yield put(updateProfileFail(error))
    }
  }
  function* onAddLink({ payload: link, file: file}) {
    try {
      const response = yield call(addLink, link,file)
      yield put(addLinkSuccess(response))
    } catch (error) {
      yield put(addLinkFail(error))
    }
  }
  function* onAddProfileInfo({ payload: link}) {
    try {
      const response = yield call(addProfileInfo, link)
      yield put(addProfileInfoSuccess(response))
    } catch (error) {
      yield put(addProfileInfoFail(error))
    }
  }
  function* onAddStyle({ payload: link}) {
    try {
      const response = yield call(addStyle, link)
      yield put(addStyleSuccess(response))
    } catch (error) {
      yield put(addStyleFail(error))
    }
  }
  function* onAddSocialLink({ payload: link}) {
    try {
      const response = yield call(addSocialLink, link)
      yield put(addSocialLinkSuccess(response))
    } catch (error) {
      yield put(addSocialLinkFail(error))
    }
  }
  function* onCheckUserName({ payload: link}) {
    try {
      const response = yield call(checkUserName, link)
      yield put(checkUserNameSuccess(response))
    } catch (error) {
      yield put(checkUserNameFail(error))
    }
  }

export function* dashboardSaga() {
  yield takeEvery(GET_BY_USERNAME, fetchUserByUsername)
    yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
    yield takeEvery(GET_USER, fetchUser)
    yield takeEvery(ADD_LINK, onAddLink)
    yield takeEvery(ADD_SOCIAL_LINK, onAddSocialLink)
    yield takeEvery(CHECK_USER_NAME, onCheckUserName)
    yield takeEvery(UPDATE_THEME, onUpdateTheme)
    yield takeEvery(GET_LIST, getList),
    yield takeEvery(UPDATE_PROFILE_PHOTO, onUpdateProfilePhoto),
    yield takeEvery(ADD_PROFILE_INFO, onAddProfileInfo),
    yield takeEvery(ADD_STYLE, onAddStyle)
    yield takeEvery(UPDATE_BACKGROUND_PHOTO, onUpdateBackGroundPhoto),
    yield takeEvery(ADD_PROFILE, PostProfile),
    yield takeEvery(GET_PROFILES, getProfiles)
    yield takeEvery(ADD_GUEST, fetchGuest)
    yield takeEvery(GET_CORPORATE_LIST, fetchCorporateList)
    yield takeEvery(GET_PARENT_DATA, fetchParentData)
    yield takeEvery(UPDATE_GUEST, fetchUpdateGuest)
    yield takeEvery(DELETE_GUEST, onDeleteGuest)
    yield takeEvery(COMBINE_PROFILE, combineProfile)
    yield takeEvery(COMBINE, combine)
    yield takeEvery(UPDATE_PLAN_ACTIVE, onUpdatePlanActive);
    
}

export default dashboardSaga;
