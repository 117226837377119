import React from "react"

import PropTypes from 'prop-types'
import {
    Card,
    CardBody,

  } from "reactstrap"

 
const StyleCard = props => {
  return ( 
    <React.Fragment>
       <Card className="me-3 card-height"  style={{backgroundImage:`url(${props.backgroundImage})` , borderRadius: "12px", wordBreak: "break-all", height: "118px",backgroundPosition: "center", backgroundSize: "contain",backgroundRepeat:"no-repeat" ,cursor:"pointer",border:props.border,borderColor:props.borderColor,boxShadow:"0px 3px 6px"}}>
                      <CardBody onClick={() => props.onClickFunc(true)}>
                        
                      </CardBody>
                     
                    </Card>
    </React.Fragment>
  )
} 


StyleCard.propTypes = {
  props: PropTypes.func,
  backgroundImage: PropTypes.string,
  border: PropTypes.string,
  borderRadius:PropTypes.string,
  borderColor: PropTypes.string,
  price: PropTypes.string,
  fontFamily:PropTypes.string, 
  onClickFunc : PropTypes.func
}


export default StyleCard;
