import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    Label,
    ModalBody,
   
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getList as onGetUser
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import moment from 'moment'
import "./datatables.scss"
import config from "config"
import imgEmpty from "./../../assets/images/empty.png"
const DevUser = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [role, setRole] = useState();
    const [selectedInfoSell, setSelectedInfoSell] = useState();
    const [checkModal, setCheckModal] = useState(false);
    const [checkProfileModal, setCheckProfileModal] = useState(false);
    const Users = [""]
    const { error, User, Links, SocialLinks, ProfileSuccess } = useSelector(state => ({
        error: state.Dashboard?.error,
        ProfileSuccess: state.Dashboard?.profileUpdateStatus,
        User: state.Dashboard?.Users,
        Links: state.Dashboard?.user[0]?.Links,
        SocialLinks: state.Dashboard?.user[0]?.SocialLinks
    }))
    let user = JSON.parse(localStorage.getItem("authUser"))
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }
    const checkProfileToggle = () => {
        setCheckProfileModal(!checkProfileModal)
    }
    useEffect(() => {
        dispatch(onGetUser());

    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const columns = [
        {
            dataField: "photo",
            text: props.t("Photo"),
            sort: false,
            formatter: (cell, row) => (
                <div>
                    {cell ? (
                        <img
                            src={config.baseImg + cell}
                           
                            style={{ width: "75px", height: "75px", objectFit: "contain" }}
                        />
                    ) : (
                        <img
                            src={imgEmpty}
                           
                            style={{ width: "75px", height: "75px", objectFit: "contain" }}
                        />
                    )}
                </div>
            ),
        },
        {
            dataField: 'name',
            text: props.t("Name"),
            sort: true,
            formatter: (cell, row) => (
                <a style={{cursor:"pointer",color:"blue"}} onClick={()=> window.open(User?.domain?User.domain+"/qr/"+cell:"https://card.gotodo.link/qr/"+cell)}>{cell}</a>
               )

        }, 
        {
            dataField: 'fullName',
            text: props.t("Full Name"),
            sort: true
        },
        {
            dataField: 'email',
            text: props.t("Email"),
        },
        {
            dataField: 'dummy',
            dummy: true,
            text: props.t("Phone"),
            formatter: (cell, row) => (
                row.profileInfo.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "phone" ? (
                        <span>{name?.info}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy2',
            dummy: true,
            text: props.t("Adres"),
            formatter: (cell, row) => (
                row.profileInfo.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "address" ? (
                        <span>{name?.info}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy3',
            dummy: true,
            text: props.t("Şirket İsmi"),
            formatter: (cell, row) => (
                row.profileInfo.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "companyName" ? (
                        <span>{name?.info}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy4',
            dummy: true,
            text: props.t("Şirketteki pozisyon"),
            formatter: (cell, row) => (
                row.profileInfo.map((name, key) => (
                    <div key={name?.info+name.name+"key"}>
                       { name?.name == "jobInCompany" ? (
                        <span>{name?.info}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy5',
            dummy: true,
            text: props.t("Doğum Günü"),
            formatter: (cell, row) => (
               
                row.profileInfo.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "birthDay" ? (
                        <span>{name?.info}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy6',
            dummy: true,
            text: props.t("Instagram"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Instagram" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy7',
            dummy: true,
            text: props.t("Twitter"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Twitter" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy8',
            dummy: true,
            text: props.t("Linkedin"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Linkedin" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy9',
            dummy: true,
            text: props.t("Facebook"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Facebook" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy10',
            dummy: true,
            text: props.t("Discord"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Discord" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy11',
            dummy: true,
            text: props.t("Youtube"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Youtube" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy12',
            dummy: true,
            text: props.t("Pinterest"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Pinterest" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy13',
            dummy: true,
            text: props.t("Snapchat"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Snapchat" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy14',
            dummy: true,
            text: props.t("Twitch"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name.info+"key"}>
                       { name.name == "Twitch" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        {
            dataField: 'dummy15',
            dummy: true,
            text: props.t("Github"),
            formatter: (cell, row) => (
               
                row.SocialLinks.map((name, key) => (
                    <div key={name?.info+"key"}>
                       { name?.name == "Github" ? (
                        <span>{name?.link}</span>
                    ) : null}
                    </div>
                   )))
        },
        
      
        {
            dataField: 'bio',
            text: props.t("Bio"),
        },
      
        {
            dataField: 'createdAt',
            text: props.t("CreatedDate"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )

        },


       
    ];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        // totalSize: Branchs ? Branchs.length : 0, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        //{ text: 'All', value: Branchs ? Branchs.length : 0 }
    ];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    console.log("User",User)
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Users")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Users")} breadcrumbItem={props.t("Users")} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={User}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={User}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        {/* <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div> */}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                noDataIndication={props.t("You Don't Have a Users Yet")}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Modal isOpen={checkProfileModal} size="lg" toggle={checkProfileToggle}>
                                                    <ModalHeader toggle={checkProfileToggle} tag="h4">
                                                        {props.t("Kişisel Bilgiler")}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <Row>
                                                          
                                                        {selectedInfoSell?.profileInfo?.map((item, index) => (
                                             
                                            
                                                 <div className="mt-2">
                                                     <p> {props.t(item.name)}:   {item.info}</p>
                                                     <hr/>
                                                   
                                                    
                                                   

                                                 </div>
                                             
                                          ))}

                                                        </Row>


                                                        <Row>
                                                            <Col>
                                                                <div className="text-end">

                                                                    &nbsp;&nbsp;
                                                                    <Button onClick={() => { checkProfileToggle() }}>
                                                                        {props.t("Cancel")}
                                                                    </Button>

                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </ModalBody>
                                                </Modal>
                                                <Modal isOpen={checkModal} size="lg" toggle={checkToggle}>
                                                    <ModalHeader toggle={checkToggle} tag="h4">
                                                        {props.t("Sosyal Medya Hesapları")}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <Row>
                                                          
                                                        {selectedInfoSell?.SocialLinks?.map((item, index) => (
                                             
                                            
                                                 <div className="mt-2">
                                                     <p> {item.name}:   {item.link}</p>
                                                     <hr/>
                                                   
                                                    
                                                   

                                                 </div>
                                             
                                          ))}

                                                        </Row>


                                                        <Row>
                                                            <Col>
                                                                <div className="text-end">

                                                                    &nbsp;&nbsp;
                                                                    <Button onClick={() => { checkToggle() }}>
                                                                        {props.t("Cancel")}
                                                                    </Button>

                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </ModalBody>
                                                </Modal>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>


                        </CardBody>
                    </Card>





                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DevUser))
