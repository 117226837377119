import React from "react"

import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input
} from "reactstrap"
import { property } from "lodash"
import dots from "./../../../assets/images/dots.png";
import dotsHorizontal from "./../../../assets/images/dotsHorizontal.png";
import imgEmpty from "./../../../assets/images/default-user-pic.png";
import chart from "./../../../assets/images/chart.png";
import config from "config"
const LinkCard = props => {
  return (
    <React.Fragment>
      <Col md="12">
        <Card className="mini-stats-wid" style={{ backgroundColor: "rgb(247 247 247)", borderRadius: "12px", wordBreak: "break-all", height: "70px" }}>
          <CardBody>
            <Row md="12" lg="12" sm="12" xs="12" style={{ position: "relative", bottom: "15px", right: "15px" }}>
              <Media
                onClick={() => {
                  if (props.link) {
                    props.addLink(true)
                  } else {

                    props.addHeader(true)
                  }
                }}>
                <Col md="1" lg="1" sm="1" xs="1" xl="1">
                  <div >
                    {props.photo ? <img src={config.baseImg + props.photo} style={{ width: "60px", height: "60px", objectFit: "cover", borderRadius: "6px" }} />
                      : null}
                  </div>
                </Col>
                <Col md="8" lg="8" sm="8" xs="6" xl="8">
                  {props.link ? (
                    <Row>
                      <Col className="card-position" md="10" lg="10" sm="10" xs="9" xl="12" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                        <Media body style={{ position: "relative", top: "7px" }}>
                          <b className="max-line-1">
                            {props.name}
                            <br />
                          </b>
                          <p className="max-line-1" style={{ fontWeight: "500" }}> {props.link}</p>
                        </Media>
                      </Col>


                    </Row>
                  ) : <Col md="12" lg="12" sm="12" xs="12" xl="12" >
                    <Media body style={{ position: "relative", top: "19px" }}>

                      <p className="text-center">
                        <b> {props.name}</b>
                      </p>
                      <p > {props.link}</p>
                      <p > {props.link}</p>

                    </Media>
                  </Col>}
                </Col>
                
                <Col md="1" lg="1" sm="1" xs="2" xl="1" className="link-chart-pos"> 
                  {props.link ? (
                    <div className="" style={{ backgroundImage: `url(${chart})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",height:"21px"}}>
                    
                    </div>
                  ) : null}

                </Col>
                <Col  md="1" lg="1" sm="1" xs="2" xl="1" className="link-count-pos" >
                  {props.link ? (
                   
                      <b style={{ position: "relative",  }}>{props.count}</b>
                  
                  ) : null}

                </Col>
               
                
                <Col md="2" lg="2" sm="2" xs="2" xl="2" className="text-end ">
                  {props.link ? (
                    <div
                      style={{
                        backgroundColor: "#AF05E1", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "70px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(175, 5, 225)",

                      }}
                      className="btn btn-success btn-sm me-2"
                    >
                      <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "16px" }}> </div>
                    </div>
                  ) :
                    <div
                      style={{
                        backgroundColor: "rgb(93, 62, 188)", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "70px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(93, 62, 188)"
                      }}
                      className="btn btn-success btn-sm me-2"
                    >
                      <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "16px" }}> </div>
                    </div>}
                </Col>
              </Media>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}


LinkCard.propTypes = {
  props: PropTypes.func,
  name: PropTypes.string,
  count: PropTypes.number,
  link: PropTypes.string,
  photo: PropTypes.string,
  _id: PropTypes.string,
  addLink: PropTypes.func,
  addHeader: PropTypes.func,

}


export default LinkCard;
