import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  Form,
  Input,
  Tooltip,
  InputGroup,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText
} from "reactstrap"
import InputMask from "react-input-mask"
import {
  checkUserName as onCheckUserName,
  resetDashboard as onResetDashboard

} from "store/actions"
import classnames from "classnames"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/dijital-kartvizit.png"
import logolight from "../../assets/images/dijital-kartvizit.png"
import CarouselPage from "./CarouselPage"

//i18n
import { withTranslation } from "react-i18next"

// action
import { registerUser, apiError, registerSuccessReset } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  const dispatch = useDispatch()
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [rememberCheck, setRememberCheck] = useState(false)
  const [tbottom, settbottom] = useState(false)
  const [corporateSwitch, setCorporateSwitch] = useState(false)
  const { user, message, registrationError, loading, registerSuccess, error } = useSelector(state => ({
    error: state.Dashboard?.error,
    message: state.Dashboard?.message,
    user: state.Account.user,
    registerSuccess: state.Account.registerSuccess,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    let isCorporate
    let uName
    if (customActiveTab == 1) {
      uName =document.getElementById("userName").value,
      isCorporate = false
    } else {
      uName =document.getElementById("userNameCor").value,
      isCorporate = true
    }
    const registerModel = {
      name: uName,
      password: values.password,
      email: values.email,
      passwordConfirm: values.confirmPassword,
      corporate: isCorporate
    }
    dispatch(registerUser(registerModel))
    

  }

  useEffect(() => {
    dispatch(apiError(""))
    settbottom(false)
  }, [])
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div>

        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">

                    </div>
                    <div className="my-auto">
                      {!registerSuccess ? (


                        <div>
                          <Row>
                            <Col className="col-5 mb-5">
                              <h5 style={{ color: "#AF05E1", position: "relative", top: "4px" }}>Ücresiz Kayıt</h5>
                            </Col>


                            {/* <Col
                              className="form-check form-switch form-switch-lg mb-3 col-7"
                            >
                              <label
                                className="form-check-label"
                                htmlFor="corporate"
                                style={{ marginRight: '54px' }}
                              >
                                {corporateSwitch ? "Kurumsal" : "Bireysel"}
                              </label>
                              <input
                                type="checkbox"
                                name="corporate"
                                id="corporate"
                                className="form-check-input"
                                checked={corporateSwitch}
                                onChange={(e) => {
                                  setCorporateSwitch(!corporateSwitch);

                                }}
                              />

                            </Col> */}
                          </Row>

                          <Col lg={12}>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1")
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                  </span>
                                  <span className="d-none d-sm-block">Bireysel</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "2",
                                  })}
                                  onClick={() => {
                                    toggleCustom("2")
                                  }}
                                >
                                  <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                  </span>
                                  <span className="d-none d-sm-block">Kurumsal</span>
                                </NavLink>
                              </NavItem>

                            </Nav>

                            <TabContent
                              activeTab={customActiveTab}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="1">
                                <Row>
                                  <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                      handleValidSubmit(v)
                                    }}
                                  >


                                    {user && user ? (
                                      <Alert color="success">
                                        Register User Successfully
                                      </Alert>
                                    ) : null}

                                    {registrationError && registrationError ? (
                                      <Alert color="danger">{registrationError}</Alert>
                                    ) : null}





                                    <div className="mb-3">
                                      <AvField
                                        name="email"

                                        className="form-control"
                                        placeholder={props.t("Email")}
                                        type="email"
                                        required
                                      />
                                    </div>

                                    <InputGroup className="mb-3">
                                      <div className="input-group-text">bio.gotodo.Link/</div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="userName"
                                        placeholder={props.t("UserName")}
                                        onChange={e => {
                                          //const myTimeout = setTimeout(myGreeting, 5000);

                                          dispatch(onResetDashboard())

                                          const userData = {
                                            name: e.target.value
                                          }
                                          dispatch(onCheckUserName(userData))
                                        }}
                                      />
                                      {error && document.getElementById("userName")?.value ? (

                                        <Alert id="TooltipBottom" color="danger"

                                          style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>

                                          <i className="bx bx-error-circle"></i>

                                        </Alert>
                                      ) : message && document.getElementById("userName")?.value ? (
                                        <Alert id="TooltipBottom" color="success" style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>
                                          <i className="bx bx-comment-check"></i>
                                        </Alert>
                                      ) : <Alert id="TooltipBottom" color="danger"

                                        style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>

                                        <i className="bx bx-error-circle"></i>

                                      </Alert>}
                                    </InputGroup>
                                    {error ? (
                                      <Tooltip
                                        placement="bottom"
                                        isOpen={tbottom}
                                        target="TooltipBottom"
                                        toggle={() => {
                                          settbottom(!tbottom)
                                        }}
                                      >
                                        {!document.getElementById("userName").value ? "Bu Alan Zorunludur!" : error ? error : "Kullanıcı Adı Uygundur"}
                                      </Tooltip>
                                    ) : null}

                                    <div className="mb-3">
                                      <AvField
                                        name="password"

                                        type="password"
                                        required
                                        placeholder={props.t("Password")}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="confirmPassword"
                                        type="password"
                                        required
                                        placeholder={props.t("Password Confirm")}
                                      />
                                    </div>

                                    <div className="mt-4">
                                      <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                        className="btn save-user btn-block "
                                        type="submit"
                                        disabled={error?true:false}
                                      >
                                        Kayıt Ol
                                      </button>
                                    </div>
                                  </AvForm>
                                </Row>
                              </TabPane>
                              <TabPane tabId="2">
                                <Row>
                                  <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                      handleValidSubmit(v)
                                    }}
                                  >


                                    {user && user ? (
                                      <Alert color="success">
                                        Register User Successfully
                                      </Alert>
                                    ) : null}

                                    {registrationError && registrationError ? (
                                      <Alert color="danger">{registrationError}</Alert>
                                    ) : null}





                                    <div className="mb-3">
                                      <AvField
                                        name="email"

                                        className="form-control"
                                        placeholder={props.t("Email")}
                                        type="email"
                                        required
                                      />
                                    </div>

                                    <InputGroup className="mb-3">
                                      <div className="input-group-text">bio.gotodo.Link/</div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="userNameCor"
                                        placeholder={props.t("UserName")}
                                        onChange={e => {
                                          //const myTimeout = setTimeout(myGreeting, 5000);

                                          dispatch(onResetDashboard())

                                          const userData = {
                                            name: e.target.value
                                          }
                                          dispatch(onCheckUserName(userData))
                                        }}
                                      />
                                      {error && document.getElementById("userName")?.value ? (

                                        <Alert id="TooltipBottom" color="danger"

                                          style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>

                                          <i className="bx bx-error-circle"></i>

                                        </Alert>
                                      ) : message && document.getElementById("userName")?.value ? (
                                        <Alert id="TooltipBottom" color="success" style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>
                                          <i className="bx bx-comment-check"></i>
                                        </Alert>
                                      ) : <Alert id="TooltipBottom" color="danger"

                                        style={{ position: "absolute", height: "36px", right: "0px", zIndex: "99", cursor: "pointer" }}>

                                        <i className="bx bx-error-circle"></i>

                                      </Alert>}
                                    </InputGroup>
                                    {error ? (
                                      <Tooltip
                                        placement="bottom"
                                        isOpen={tbottom}
                                        target="TooltipBottom"
                                        toggle={() => {
                                          settbottom(!tbottom)
                                        }}
                                      >
                                        {!document.getElementById("userName").value ? "Bu Alan Zorunludur!" : error ? error : "Kullanıcı Adı Uygundur"}
                                      </Tooltip>
                                    ) : null}

                                    <div className="mb-3">
                                      <AvField
                                        name="password"

                                        type="password"
                                        required
                                        placeholder={props.t("Password")}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="confirmPassword"
                                        type="password"
                                        required
                                        placeholder={props.t("Password Confirm")}
                                      />
                                    </div>

                                    <div className="mt-4">
                                      <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                        className="btn save-user btn-block "
                                        type="submit"
                                        disabled={error?true:false}
                                      >
                                        Kayıt Ol
                                      </button>
                                    </div>
                                  </AvForm>
                                </Row>
                              </TabPane>

                            </TabContent>
                          </Col>

                          {/* <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(v)
                            }}
                          >


                            {user && user ? (
                              <Alert color="success">
                                Register User Successfully
                              </Alert>
                            ) : null}

                            {registrationError && registrationError ? (
                              <Alert color="danger">{registrationError}</Alert>
                            ) : null}





                            <div className="mb-3">
                              <AvField
                                name="email"

                                className="form-control"
                                placeholder={props.t("Email")}
                                type="email"
                                required
                              />
                            </div>

                            <InputGroup className="mb-3">
                              <div className="input-group-text">bio.gotodo.Link/</div>
                              <input
                                type="text"
                                className="form-control"
                                id="userName"
                                placeholder={props.t("UserName")}
                                onChange={e => {
                                  //const myTimeout = setTimeout(myGreeting, 5000);

                                  dispatch(onResetDashboard())

                                  const userData = {
                                    name: e.target.value
                                  }
                                  dispatch(onCheckUserName(userData))
                                }}
                              />
                              {error && document.getElementById("userName").value ? (

                                <Alert id="TooltipBottom" color="danger"

                                  style={{ position: "absolute", height: "36px", left: "330px", zIndex: "99", cursor: "pointer" }}>

                                  <i className="bx bx-error-circle"></i>

                                </Alert>
                              ) : message && document.getElementById("userName").value ? (
                                <Alert id="TooltipBottom" color="success" style={{ position: "absolute", height: "36px", left: "330px", zIndex: "99" }}>
                                  <i className="bx bx-comment-check"></i>
                                </Alert>
                              ) : <Alert id="TooltipBottom" color="danger"

                                style={{ position: "absolute", height: "36px", left: "330px", zIndex: "99", cursor: "pointer" }}>

                                <i className="bx bx-error-circle"></i>

                              </Alert>}
                            </InputGroup>
                            {error ? (
                              <Tooltip
                                placement="bottom"
                                isOpen={tbottom}
                                target="TooltipBottom"
                                toggle={() => {
                                  settbottom(!tbottom)
                                }}
                              >
                                {!document.getElementById("userName").value ? "Bu Alan Zorunludur!" : error ? error : "Kullanıcı Adı Uygundur"}
                              </Tooltip>
                            ) : null}

                            <div className="mb-3">
                              <AvField
                                name="password"

                                type="password"
                                required
                                placeholder={props.t("Password")}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="confirmPassword"
                                type="password"
                                required
                                placeholder={props.t("Password Confirm")}
                              />
                            </div>

                            <div className="mt-4">
                              <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                className="btn save-user btn-block "
                                type="submit"
                              >
                                Kayıt Ol
                              </button>
                            </div>
                          </AvForm> */}

                          <div className="mt-5 text-center">
                            <p>

                              <Link
                                to="login"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Giriş Yap
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      ) : <div className="alert alert-success h3 text-center">
                        {props.t("Kayıt Olma İşlemi Başarılı")}
                        <br />
                        <i className="fa fa-check"></i>
                        <hr />
                        <Link to="/Login" className="font-size-13">{props.t("Devam Et")}</Link>
                      </div>}

                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Aviyiva. Tarafından{" "}
                        <i className="mdi mdi-heart text-danger"></i>
                        hazırlanmıştır.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withTranslation()(Register))
