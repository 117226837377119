import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input, Form, Label, FormGroup
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

    updateProfilePhoto as onUpdateProfilePhoto,

} from "store/actions"
import Dropzone from "react-dropzone"
import config from "config"
import imgEmpty from "./../../../assets/images/default-user-pic.png";
import { isEmpty, map, property } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const AddPhotoModal = (props, cb) => {

    const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo } = useSelector(state => ({
        error: state.Dashboard?.error,
        ProfileSuccess: state.Dashboard?.profileUpdateStatus,
        User: state.Dashboard?.user,
        Links: state.Dashboard?.user?.Links,
        SocialLinks: state.Dashboard?.user?.SocialLinks,
        profileInfo: state.Dashboard?.user?.profileInfo,
    }))
    const dispatch = useDispatch()
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [selectedFiles, setselectedFiles] = useState([])
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);

    const handleValidProfilePhotoSubmit = (e, values) => {
        const updateProfile = {
          _id: user.uid,
          photo: values["photo"],
        }
        dispatch(onUpdateProfilePhoto(updateProfile, selectedFiles))
        props.save(true)
      }
      function handleAcceptedFiles(files) {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        })
        setselectedFiles(files);
      }
    
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

    return (
        <Modal isOpen={props.modal} size="lg" className="modal-width" toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">

          {props.t("Edit Profile Photo")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidProfilePhotoSubmit} autoComplete={"off"}>
            <Row form>
              <Col md="12">
                <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                  <div>

                    {User?.photo ? <img src={imgEmpty} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                      : <img src={imgEmpty} style={{ width: "182px", height: "108px", objectFit: "contain" }} />}
                  </div>
                  <div style={{ width: "65%", border: "1px solid #ced4da", borderRadius: "10px", height: "150px" }} >
                    <Dropzone
                      multiple={false}
                      accept="image/jpeg, image/png"
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone-min" style={{ wordBreak: "break-all" }}>
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick" style={{ padding: "0px" }}>
                              <div>
                                <i className="display-5 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>{props.t("Drop Image")}</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div
                      className="dropzone-previews"
                      id="file-previews"
                      style={{ position: "absolute", zIndex: 99, left: 0, top: -5 }}
                    >
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <div className="align-items-center">
                                <div className="col-12">
                                  <img
                                    data-dz-thumbnail=""
                                    style={{ width: "182px", height: "140px", objectFit: "contain" }}
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </div>

                              </div>
                            </div>
                          </Card>
                        )
                      })}
                    </div>

                  </div>
                </div>
              </Col>

              <Row className="text-end">
                <Col md="12">
                  <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                    type="submit"
                    className="btn  save-user me-3"
                  >
                    {props.t("Save")}
                  </button>
                 
                </Col>


              </Row>



            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    )

}


AddPhotoModal.propTypes = {

    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    editLink: PropTypes.array,
    continue: PropTypes.func,
    payment: PropTypes.func,
    back: PropTypes.func,
    t: PropTypes.any,
    toggle: PropTypes.func,
    save: PropTypes.func,
    confirmAlert: PropTypes.func
}


export default withRouter((withTranslation()(AddPhotoModal)))
