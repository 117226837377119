import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Confirm from "../pages/Authentication/Confirm"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import ResetPassword from "../pages/Authentication/ResetPassword"
import CombineProfile from "../pages/Authentication/CombineProfile"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Dashboard
//import Profile from "../pages/Dashboard/Profile"

//Dev User
import DevUser from "../pages/DevUser/list"

import Profile from "../pages/Profile/Profile"
import CompanyInfo from "../pages/CompanyInfo/list"


const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },
   
 // //Dev USer
 { path: "/DevUser", component: DevUser },
 
 { path: "/companyInfo", component: CompanyInfo },
 

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login }, 
  { path: "/confirm/:id/:hash", component: Confirm },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/changePassword/:id/:hash", component: ResetPassword },
  { path: "/combineProfile/:id/:hash/:email", component: CombineProfile },
  
  { path: "/qr/:id", component: Profile },
]

export { userRoutes, authRoutes }
