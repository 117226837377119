/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const ADD_LINK = "ADD_LINK";
export const ADD_LINK_SUCCESS = "ADD_LINK_SUCCESS";
export const ADD_LINK_FAIL = "ADD_LINK_FAIL";

export const ADD_SOCIAL_LINK = "ADD_SOCIAL_LINK";
export const ADD_SOCIAL_LINK_SUCCESS = "ADD_SOCIAL_LINK_SUCCESS";
export const ADD_SOCIAL_LINK_FAIL = "ADD_SOCIAL_LINK_FAIL";  

export const RESET_DASHBOARD = "RESET_DASHBOARD"; 

export const CHECK_USER_NAME = "CHECK_USER_NAME";
export const CHECK_USER_NAME_SUCCESS = "CHECK_USER_NAME_SUCCESS"; 
export const CHECK_USER_NAME_FAIL = "CHECK_USER_NAME_FAIL"; 

export const GET_BY_USERNAME = "GET_BY_USERNAME";
export const GET_BY_USERNAME_SUCCESS = "GET_BY_USERNAME_SUCCESS";
export const GET_BY_USERNAME_FAIL = "GET_BY_USERNAME_FAIL"; 

export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_FAIL = "UPDATE_THEME_FAIL"; 

export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_FAIL = "GET_LIST_FAIL"; 

export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO";
export const UPDATE_PROFILE_PHOTO_SUCCESS = "UPDATE_PROFILE_PHOTO_SUCCESS"; 
export const UPDATE_PROFILE_PHOTO_FAIL = "UPDATE_PROFILE_PHOTO_FAIL"; 

export const ADD_PROFILE_INFO = "ADD_PROFILE_INFO"; 
export const ADD_PROFILE_INFO_SUCCESS = "ADD_PROFILE_INFO_SUCCESS";
export const ADD_PROFILE_INFO_FAIL = "ADD_PROFILE_INFO_FAIL"; 

export const ADD_STYLE = "ADD_STYLE"; 
export const ADD_STYLE_SUCCESS = "ADD_STYLE_SUCCESS";
export const ADD_STYLE_FAIL = "ADD_STYLE_FAIL"; 

export const UPDATE_BACKGROUND_PHOTO = "UPDATE_BACKGROUND_PHOTO"; 
export const UPDATE_BACKGROUND_PHOTO_SUCCESS = "UPDATE_BACKGROUND_PHOTO_SUCCESS";
export const UPDATE_BACKGROUND_PHOTO_FAIL = "UPDATE_BACKGROUND_PHOTO_FAIL"; 

export const ADD_PROFILE = "ADD_PROFILE"; 
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_FAIL = "ADD_PROFILE_FAIL"; 

export const GET_PROFILES = "GET_PROFILES"; 
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL"; 

export const ADD_GUEST = "ADD_GUEST"; 
export const ADD_GUEST_SUCCESS = "ADD_GUEST_SUCCESS";
export const ADD_GUEST_FAIL = "ADD_GUEST_FAIL"; 

export const GET_CORPORATE_LIST = "GET_CORPORATE_LIST"; 
export const GET_CORPORATE_LIST_SUCCESS = "GET_CORPORATE_LIST_SUCCESS";
export const GET_CORPORATE_LIST_FAIL = "GET_CORPORATE_LIST_FAIL";  

export const GET_PARENT_DATA = "GET_PARENT_DATA"; 
export const GET_PARENT_DATA_SUCCESS = "GET_PARENT_DATA_SUCCESS";
export const GET_PARENT_DATA_FAIL = "GET_PARENT_DATA_FAIL";  

export const UPDATE_GUEST = "UPDATE_GUEST"; 
export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";
export const UPDATE_GUEST_FAIL = "UPDATE_GUEST_FAIL";  

export const DELETE_GUEST = "DELETE_GUEST"; 
export const DELETE_GUEST_SUCCESS = "DELETE_GUEST_SUCCESS";
export const DELETE_GUEST_FAIL = "DELETE_GUEST_FAIL";  

export const COMBINE_PROFILE = "COMBINE_PROFILE"; 
export const COMBINE_PROFILE_SUCCESS = "COMBINE_PROFILE_SUCCESS";
export const COMBINE_PROFILE_FAIL = "COMBINE_PROFILE_FAIL";  

export const COMBINE = "COMBINE"; 
export const COMBINE_SUCCESS = "COMBINE_SUCCESS";
export const COMBINE_FAIL = "COMBINE_FAIL";  

export const UPDATE_PLAN_ACTIVE = "UPDATE_PLAN_ACTIVE"; 
export const UPDATE_PLAN_ACTIVE_SUCCESS = "UPDATE_PLAN_ACTIVE_SUCCESS";
export const UPDATE_PLAN_ACTIVE_FAIL = "UPDATE_PLAN_ACTIVE_FAIL";  
 