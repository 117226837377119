import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input, Form, Label, FormGroup
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

    addSocialLink as onAddSocialLink,

} from "store/actions"

import { isEmpty, map, property } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const AddSocialLinkModal = (props, cb) => {

    const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo } = useSelector(state => ({
        error: state.Dashboard?.error,
        ProfileSuccess: state.Dashboard?.profileUpdateStatus,
        User: state.Dashboard?.user,
        Links: state.Dashboard?.user?.Links,
        SocialLinks: state.Dashboard?.user?.SocialLinks,
        profileInfo: state.Dashboard?.user?.profileInfo,
    }))
    const dispatch = useDispatch()
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);

    let instagram = SocialLinks?.filter(function(media) {
      return media.name == "Instagram"; });
    let twitter = SocialLinks?.filter(function(media) {
      return media.name == "Twitter"; });
    let youtube = SocialLinks?.filter(function(media) {
      return media.name == "Youtube"; })
    let facebook = SocialLinks?.filter(function(media) {
      return media.name == "Facebook"; });
    let github = SocialLinks?.filter(function(media) {
        return media.name == "Github"; });
    let spotify = SocialLinks?.filter(function(media) {
      return media.name == "Spotify"; });
    let discord = SocialLinks?.filter(function(media) {
        return media.name == "Discord"; });
    let linkedin = SocialLinks?.filter(function(media) {
       return media.name == "Linkedin"; });
    let telegram = SocialLinks?.filter(function(media) {
         return media.name == "Telegram"; });
    let twitch = SocialLinks?.filter(function(media) {
        return media.name == "Twitch"; });
    let snapchat = SocialLinks?.filter(function(media) {
        return media.name == "Snapchat"; });
    let pinterest = SocialLinks?.filter(function(media) {
        return media.name == "Pinterest"; });

    const handleValidSocialLinkSubmit = (e, values) => {
      const usersSocialMedias =[]
        const SocialLinks = [
          {
            name: "Instagram",
            link: document.getElementById("instagramName").value,
            icon: "bx bxl-instagram",
            url: "https://www.instagram.com/" + document.getElementById("instagramName").value
          },
          {
            name: "Twitter",
            link: document.getElementById("twitterName").value,
            icon: "bx bxl-twitter",
            url: "https://twitter.com/" + document.getElementById("twitterName").value
          },
          {
            name: "Youtube",
            link: document.getElementById("youtubeName").value,
            icon: "bx bxl-youtube",
            url: document.getElementById("youtubeName").value
          },
          {
            name: "Facebook",
            link: document.getElementById("facebookName").value,
            icon: "bx bxl-facebook",
            url: document.getElementById("facebookName").value
    
          },
          {
            name: "Github",
            link: document.getElementById("githubName").value,
            icon: "bx bxl-github",
            url:"https://github.com/" + document.getElementById("githubName").value
    
          },
          {
            name: "Spotify",
            link: document.getElementById("spotifyName").value,
            icon: "bx bxl-spotify",
            url:document.getElementById("spotifyName").value
    
          },
          {
            name: "Discord",
            link: document.getElementById("discordName").value,
            icon: "bx bxl-discord",
            url:document.getElementById("discordName").value
    
          },
          {
            name: "Linkedin",
            link: document.getElementById("linkedinName").value,
            icon: "bx bxl-linkedin",
            url:document.getElementById("linkedinName").value
    
          },
          {
            name: "Telegram",
            link: document.getElementById("telegramName").value,
            icon: "bx bxl-telegram",
            url:document.getElementById("telegramName").value
     
          },
          {
            name: "Twitch",
            link: document.getElementById("twitchName").value,
            icon: "bx bxl-twitch",
            url: "https://twitch.tv/" +document.getElementById("twitchName").value
    
          },
          {
            name: "Snapchat",
            link: document.getElementById("snapchatName").value,
            icon: "bx bxl-snapchat",
            url: "https://www.snapchat.com/add/" +document.getElementById("snapchatName").value
    
          },
          {
            name: "Pinterest",
            link: document.getElementById("pinterestName").value,
            icon: "bx bxl-pinterest",
            url: "https://tr.pinterest.com/" +document.getElementById("pinterestName").value
    
          },
    
        ]
        SocialLinks.filter(x => x.link)?.map((Links, key) => (
          usersSocialMedias.push(Links)
          
          ))
        const SocialLink = {
          _id: user.uid,
          SocialLinks: JSON.stringify(usersSocialMedias)
        }
        dispatch(onAddSocialLink(SocialLink))
         props.save(true)
      }

    return (
        <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("Social Media Accounts")}

        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSocialLinkSubmit} autoComplete={"off"}>
            <Row form>
              <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"><i className="bx bxl-instagram"></i></div>
                  <input type="text" className="form-control" id="instagramName" placeholder="Instagram Username"
                    defaultValue={instagram ? (
                      instagram[0]?.link
                    ) : ""} />
                </InputGroup>
              </div>
              <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-twitter"></i></div>
                  <input type="text" className="form-control" id="twitterName" placeholder="Twitter Username"
                    defaultValue={twitter ? (
                      twitter[0]?.link
                    ) : ""} />
                </InputGroup>

              </div>
              <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-youtube"></i></div>
                  <input type="text" className="form-control" id="youtubeName" placeholder="https://youtube.com/channel/channelurl"
                    defaultValue={youtube ? (
                      youtube[0]?.link
                    ) : ""} />
                </InputGroup>

              </div>
              <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-facebook"></i></div>
                  <input type="text" className="form-control" id="facebookName" placeholder="https://facebook.com/pageurl"
                    defaultValue={facebook ? (
                      facebook[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-github"></i></div>
                  <input type="text" className="form-control" id="githubName" placeholder="Github Username"
                    defaultValue={github ? (
                      github[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-spotify"></i></div>
                  <input type="text" className="form-control" id="spotifyName" placeholder="https://open.spotify.com/user/username"
                    defaultValue={spotify ? (
                      spotify[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-discord"></i></div>
                  <input type="text" className="form-control" id="discordName" placeholder="https://discord.com/invite/username"
                    defaultValue={discord ? (
                      discord[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-linkedin"></i></div>
                  <input type="text" className="form-control" id="linkedinName" placeholder="https://www.linkedin.com/in/username"
                    defaultValue={linkedin ? (
                      linkedin[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-telegram"></i></div>
                  <input type="text" className="form-control" id="telegramName" placeholder="https://t.me/"
                    defaultValue={telegram ? (
                      telegram[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-twitch"></i></div>
                  <input type="text" className="form-control" id="twitchName" placeholder="Twitch Username"
                    defaultValue={twitch ? (
                      twitch[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-snapchat"></i></div>
                  <input type="text" className="form-control" id="snapchatName" placeholder="Snapchat Username"
                    defaultValue={snapchat ? (
                      snapchat[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               <div className="mb-3">
                <InputGroup>
                  <div className="input-group-text"> <i className="bx bxl-pinterest"></i></div>
                  <input type="text" className="form-control" id="pinterestName" placeholder="Pinterest Username"
                    defaultValue={pinterest ? (
                      pinterest[0]?.link
                    ) : ""} />
                </InputGroup>
               </div>
               
              <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                type="submit"
                className="btn save-user"
              >
                {props.t("Save")}
              </button>


            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    )

}


AddSocialLinkModal.propTypes = {

    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    editLink: PropTypes.array,
    continue: PropTypes.func,
    payment: PropTypes.func,
    back: PropTypes.func,
    t: PropTypes.any,
    toggle: PropTypes.func,
    save: PropTypes.func,
    confirmAlert: PropTypes.func
}


export default withRouter((withTranslation()(AddSocialLinkModal)))
