import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input, Form, Label, FormGroup
} from "reactstrap"
import { isEmpty, map, stubFalse } from "lodash"
import OutsideClickHandler from 'react-outside-click-handler';
//Import Task Cards
import { ToastContainer, toast } from 'react-toastify';
import "@fontsource/dm-sans";
import 'typeface-pt-sans'
import "@fontsource/quicksand";
import 'typeface-eb-garamond'
import classnames from "classnames"
import "./../datatables.scss"
import { ChromePicker } from 'react-color';
import { SketchPicker } from 'react-color';
import QrCodeModal from "./../Modals/QrCodeModal.js"
import AddHeaderModal from "./../Modals/AddHeader.js"
import SocialLinkModal from "./../Modals/AddSocialLinkModal.js"
import AddPhotoModal from "./../Modals/AddPhotoModal.js"
import AddBackgroundModal from "./../Modals/AddBackgroundModal"
import AddInfoModal from "./../Modals/AddInfoModal"
import AddUserModal from "./../Modals/AddUserModal"
import AddLinkModal from "./../Modals/AddLinkModal"
import CombineProfileModal from "../Modals/CombineProfileModal"
import UserTable from "./../Modals/userTable"
import 'react-toastify/dist/ReactToastify.css';
import zoom2 from "./../../../assets/images/zoom2.jpg"
//i18n 
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { authorize, Role } from "./../../../components/helpers/authorize"
import Dropzone from "react-dropzone"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import imgEmpty from "./../../../assets/images/default-user-pic.png";
import styleImg from "./../../../assets/images/style.png";
import styleImg2 from "./../../../assets/images/style2.png";
import styleImg3 from "./../../../assets/images/style3.png";
import colorPalette from "./../../../assets/images/renkPaleti3.png";
import dots from "./../../../assets/images/dots.png";
import chart from "./../../../assets/images/chart.png";
import dotsHorizontal from "./../../../assets/images/dotsHorizontal.png";
import config from "config"
import vCardsJS from "vcards-js";
import { 
  updateProfile as onUpdateProfile,
  addLink as onAddLink,
  updateTheme as onUpdateTheme,
  addStyle as onAddStyle,
  addSocialLink as onAddSocialLink,
  addProfileInfo as onAddProfileInfo,
  getProfiles as onGetProfiles,
  updateBackgroundPhoto as onUpdateBackgroundPhoto,
  getParentData as onGetParentData
} from "store/actions"
import ThemeCard from "./ThemeCard"
import FontCard from "./FontCard"
import ButtonCard from "./ButtonCard"
import StyleCard from "./StyleCard"
import ShadowCard from "./ShadowCard"
import HeightCard from "./ButtonHeightCard"
import LinkCard from "./LinkCard"
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QrCodeModal2 from "../Modals/QrCodeModal2"

const CashCard = props => {
  const dispatch = useDispatch()
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const [deleteIndex, setDeleteIndex] = useState()
  const [selectedFiles, setselectedFiles] = useState([])
  const [addLinkModal, setAddLinkModal] = useState(false)
  const [addHeaderModal, setAddHeaderModal] = useState(false)
  const [addSocialLinkModal, setAddSocialLinkModal] = useState(false)
  const [combineProfileModal, setCombineProfileModal] = useState(false)
  const [addPhotoModal, setAddPhotoModal] = useState(false)
  const [addInfoModal, setAddInfoModal] = useState(false)
  const [addUserModal, setAddUserModal] = useState(false)
  const [addBackgroundModal, setAddBackgroundModal] = useState(false)
  const [editLink, setEditLink] = useState([])
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [buttonColor, setButtonColor] = useState({
    h: 250,
    s: 0,
    l: 0.2,
    a: 1
  });
  const [backgroundColor, setBackgroundColor] = useState("Lightblue")
  const [backgroundColor2, setBackgroundColor2] = useState("Lightblue")
  const [backgroundColor3, setBackgroundColor3] = useState("Lightblue")
  const [backgroundColor4, setBackgroundColor4] = useState("Lightblue")
  const [backgroundColor5, setBackgroundColor5] = useState("Lightblue")
  const [textColor, setTextColor] = useState("Lightblue")
  const [borderColor, setBorderColor] = useState("Lightblue")
  const [dynamic_description, setdynamic_description] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [modalQr, setModalQr] = useState(false);
  const [modalQr2, setModalQr2] = useState(false);
  const [buttonColorPicker, setButtonColorPicker] = useState(false)
  const [textColorPicker, setTextColorPicker] = useState(false)
  const [backgroundColorPicker, setBackgroundColorPicker] = useState(false)
  const [backgroundColorPicker2, setBackgroundColorPicker2] = useState(false)
  const [backgroundColorPicker3, setBackgroundColorPicker3] = useState(false)
  const [backgroundColorPicker4, setBackgroundColorPicker4] = useState(false)
  const [backgroundColorPicker5, setBackgroundColorPicker5] = useState(false)
  const [borderColorPicker, setBorderColorPicker] = useState(false)
  const [selectedButton, setSelectedButton] = useState("1")
  const [characters, updateCharacters] = useState();
  const [profileInfos, updateProfileInfos] = useState();
  const [socialMedias, updateSocialMedias] = useState();
  const [LinkName, setLinkName] = useState();
  const [TabForLink, setTabForLink] = useState();
  const divRef = useRef() 

  const SortableItem = SortableElement(({ value }) => <li>
    <Col md="2" xs="2" lg="2" xl="2" sm="2">
      <Card className="mini-stats-wid" style={{ background: "#f7f7f7", borderRadius: "12px", wordBreak: "break-all", height: "90px", width: "70px", cursor: "pointer" }}>
        <CardBody onClick={() => addSocialLinkToggle()}>
          <Row  >
            <Media>
              <i className=
                {value}
                style={{ fontSize: "30px", position: "relative", top: "-2px" }} ></i>

            </Media>
          </Row>
        </CardBody>
        <p className="text-center mt-5" style={{ position: "relative", top: "10px", fontFamily: props.fontFamily }}>{props.Name}</p>
      </Card>
      <div style={{ backgroundColor: "rgb(93 62 188)", borderEndStartRadius: "12px", borderEndEndRadius: "12px", height: "30px", width: "70px", position: "relative", bottom: "46px", borderColor: "rgb(93, 62, 188)" }}
        type="button"
        className="btn btn-success btn-sm me-2"
        onClick={() => {
          addSocialLinkToggle()
        }} >
        <div style={{ backgroundImage: `url(${dotsHorizontal})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "38px", position: "relative", top: "-9px" }}> </div>
      </div>
    </Col>
  </li>);
  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className="socialMedias">
        {items?.map((value, index) => (
          <SortableItem key={`item-${value?.icon}`} index={index} value={value?.icon} />
        ))}
        <button style={{ backgroundColor: "#5D3EBC", borderRadius: "7px", color: "white", width: "70px", height: "70px", position: "relative", top: "-14px" }}
          type="button"
          className="btn save-user btn-sm mb-2 mt-3"
          onClick={() => addSocialLinkToggle()}
        ><b style={{ fontSize: "62px", fontWeight: "100", position: "relative", top: "-16px" }}>+</b>

        </button>
      </ul>
    );
  });

  let user = JSON.parse(localStorage.getItem("authUser"))
  const { error, User, Links, SocialLinks,ParentData, ProfileSuccess, profileInfo, userAnalytics,Profiles } = useSelector(state => ({
    error: state.Dashboard?.error,
    ProfileSuccess: state.Dashboard?.profileUpdateStatus,
    User: state.Dashboard?.user,
    Links: state.Dashboard?.user?.Links,
    SocialLinks: state.Dashboard?.user?.SocialLinks,
    profileInfo: state.Dashboard?.user?.profileInfo,
    userAnalytics: state.UserAnalytics,
    Profiles: state.Dashboard?.profiles,
    ParentData: state.Dashboard?.ParentData 

  }))

  useEffect(() => {
    if(isEmpty(Profiles)){
     dispatch(onGetProfiles(User?.email))
    }
    if (User?.corporateId) {
      dispatch(onGetParentData(User?.corporateId))
    }
   }, [User]);
  useEffect(() => {
    if (!isEmpty(Links)) {
      updateCharacters(Links)
    }
  }, [Links])
  useEffect(() => {
    if (!isEmpty(profileInfo)) {
      updateProfileInfos(profileInfo)
    }
  }, [profileInfo])
  useEffect(() => {
    {
      User?.isPhoto ? (
        setSelectedButton("4")
      ) : User?.backGroundColor?.includes("180deg") ?
        setSelectedButton("3")
        : User?.backGroundColor?.includes("to right") ?
          setSelectedButton("2") : setSelectedButton("1")


    }
  }, [User])


  useEffect(() => {
    if (!isEmpty(SocialLinks)) {
      updateSocialMedias(SocialLinks)
    }
  }, [SocialLinks])

  const handleChangeComplete = data => {


    setButtonColor(data.rgb);

    const theme = {
      _id: user.uid,
      font: User?.font,
      backGroundColor: User.backGroundColor,
      cardColor: `rgb(${buttonColor.r},${buttonColor.g},${buttonColor.b},${buttonColor.a})`,

      fontColor: User.fontColor,
      cardRadius: User.cardRadius,
      border: User.border,
      borderColor: User.borderColor,
      isPhoto: User?.isPhoto
    }
    dispatch(onUpdateTheme(theme))


  };

  function handleOnDragEnd(result) {

    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
    const Link = {
      _id: user.uid,
      links: JSON.stringify(items)
    }
    dispatch(onAddLink(Link))
  }
  function handleOnDragProfileInfosEnd(result) {

    if (!result.destination) return;

    const items = Array.from(profileInfos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateProfileInfos(items);
    const Infos = {
      _id: user.uid,
      ProfileInfo: JSON.stringify(items)
    }
    dispatch(onAddProfileInfo(Infos))
  }
  function handleOnDragSocialMediasEnd(result) {

    if (!result.destination) return;

    const items = Array.from(socialMedias);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateSocialMedias(items);
    const SocialLink = {
      _id: user.uid,
      SocialLinks: JSON.stringify(items)
    }
    dispatch(onAddSocialLink(SocialLink))
  }

  function handleOnDragSocialMediasEnd_v2(result) {
    console.log("ilk", result.oldIndex, "son", result.newIndex)
    if (result.oldIndex == result.newIndex) {
      addSocialLinkToggle()
    }
    const items = Array.from(socialMedias);
    const [reorderedItem] = items.splice(result.oldIndex, 1);
    items.splice(result.newIndex, 0, reorderedItem);

    updateSocialMedias(items);
    const SocialLink = {
      _id: user.uid,
      SocialLinks: JSON.stringify(items)
    }
    dispatch(onAddSocialLink(SocialLink))
  }


  const handleValidProfileSubmit = (e, values) => {
    const updateProfile = {
      _id: user.uid,
      fullName: values.fullName,
      bio: values["bio"],
      photo: values["photo"],
      phone: values["phone"],
      profileMail: values["profileMail"]
    }
    dispatch(onUpdateProfile(updateProfile, selectedFiles))
  }
  const deleteItem = () => {
    Links.splice(deleteIndex, 1)
    const Link = {
      _id: user.uid,
      links: JSON.stringify(Links)
    }
    dispatch(onAddLink(Link))
  }
  const addLinkToggle = () => {
    setAddLinkModal(!addLinkModal)
  }
  const addHeaderToggle = () => {
    setAddHeaderModal(!addHeaderModal)
  }
  const addSocialLinkToggle = () => {
    setAddSocialLinkModal(!addSocialLinkModal)
  }
  const combineProfileToggle = () => {
    setCombineProfileModal(!combineProfileModal)
  }
  const addPhotoToggle = () => {
    setAddPhotoModal(!addPhotoModal)
  }
  const addInfoToggle = () => {
    setAddInfoModal(!addInfoModal)
  }
  const addUserToggle = () => {
    setAddUserModal(!addUserModal)
  }
  const addBackgroundToggle = () => {
    setAddBackgroundModal(!addBackgroundModal)
  }
  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  const resetColorPalette = () => {
    setBackgroundColorPicker(false)
    setBackgroundColorPicker2(false)
    setBackgroundColorPicker3(false)
    setBackgroundColorPicker4(false)
    setBackgroundColorPicker5(false)
    setTextColorPicker(false)
    setButtonColorPicker(false)
    setBorderColorPicker(false)
  }
  const toggleQr = () => {
    setModalQr(!modalQr);
  }
  const toggleQr2 = () => {
    setModalQr2(!modalQr2);
  }
  const copyToClipboard = (txt) => {
    var textField = document.createElement('textarea')
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }
  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    try {
      const shareLink =User.domain?User.domain+"/qr/"+ user.userName: "https://card.gotodo.link/qr/" + user.userName

      console.log("Congrats! Your browser supports Web Share API", shareLink)
      await navigator
        .share({
          title: "Dijital Kartvizit",
          text: "Dijital Kartvizit",
          url: shareLink,
        })
        .then(() => {
          console.log("Sharing successfull")
        })
        .catch(() => {
          console.log("Sharing failed")
        })
    } catch (err) {
      console.log("Sorry! Your browser does not support Web Share API", err)
    }
  }


  return (
    <React.Fragment>
   
    
      
      {User.corporate && User.corporate == true ? (
         <Row style={{ position: "relative", bottom: "00px", paddingRight: "28px" }}>
         <Col md="2" sm="10" xs="10">
           <Row>
             <Col md="3" sm="1" xs="1"> <div className="text-center" style={{ backgroundImage: `url(${chart})`, backgroundPosition: "center", top: "3px", backgroundRepeat: "no-repeat", position: "relative", height: "33px", width: "25px" }}>
       
             </div></Col>
             <Col md="9" sm="5" xs="9">
               <b  style={{ position: "relative", top: "12px",left:"2px"}}>{userAnalytics?.user?.profileVisit}</b>
             </Col>
           </Row>
       
       
         </Col>
         <Col md="1" sm="1" xs="1" className="icons-pos">
           <div className="text-end"><a onClick={async () => { await handleShareButton() }}>
             <i style={{ color: "#AF05E1", fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-share-alt"></i></a> </div>
         </Col>
         {/* <Col md="1" sm="1" xs="1" className="icons-pos">
           <div className="text-end"><a onClick={() => { toggleQr() }}>
             <i style={{ color: "#AF05E1", fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-grid-alt "></i></a> </div>
         </Col>  */}
         <Col md="1" sm="1" xs="1" className="icons-pos">
           <div className="text-end"><a onClick={() => { toggleQr2() }}>
             <i style={{ color: "#AF05E1", fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-grid-alt "></i></a> </div>
         </Col> 

         <Col md="1" sm="2" xs="2" className="icons-pos">
           <div style={{ color: "#AF05E1", fontSize: "30px" }}><a onClick={() => { copyToClipboard(User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName) }}>
             <i style={{ color: "#AF05E1", fontSize: "30px" }} className="bx bx-copy"></i></a> </div>
         </Col>
         <Col md="7" sm="10" xs="10" className="profile-chart-pos" >
           <div className="mb-4"><Link style={{ color: "#AF05E1", fontSize: "14px", fontWeight: "700" }} target={"_blank"} to={{ pathname: User.domain?User.domain+"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName }}>
             {User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName}
           </Link><a className="me-1" style={{ fontSize: 24, fontWeight: "700" }} onClick={() => { copyToClipboard(User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName) }}>
             </a>
             <b style={{ color: "#000DC2", fontWeight: "200", fontSize: "14px" }}>{props.t("'s Links")}</b>
       
           </div>
         </Col>
       </Row>
      ):(
  <Row style={{ position: "relative", bottom: "00px", paddingRight: "28px" }}>
  <Col md="2" sm="10" xs="10">
    <Row>
      <Col md="3" sm="1" xs="1"> <div className="text-center" style={{ backgroundImage: `url(${chart})`, backgroundPosition: "center", top: "3px", backgroundRepeat: "no-repeat", position: "relative", height: "33px", width: "25px" }}>

      </div></Col>
      <Col md="9" sm="5" xs="9">
        <b  style={{ position: "relative", top: "12px",left:"2px" }}>{userAnalytics?.user?.profileVisit}</b>
      </Col>
    </Row>


  </Col>
  <Col md="1" sm="1" xs="1" className="icons-pos">
    <div className="text-end"><a onClick={async () => { await handleShareButton() }}>
      <i style={{ color: "#AF05E1", fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-share-alt"></i></a> </div>
  </Col>
  
 <Col md="1" sm="1" xs="1" className="icons-pos">
    <div className="text-end"><a onClick={() => { toggleQr2() }}>
      <i style={{ color: "#AF05E1", fontSize: "30px", position: "relative", top: "5px" }} className="bx bx-grid-alt "></i></a> </div>
  </Col>
  <Col md="1" sm="2" xs="2" className="icons-pos">
    <div style={{ color: "#AF05E1", fontSize: "30px" }}><a onClick={() => { copyToClipboard(User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName) }}>
      <i style={{ color: "#AF05E1", fontSize: "30px" }} className="bx bx-copy"></i></a> </div>
  </Col>
  <Col md="7" sm="10" xs="10" className="profile-chart-pos mb-4" > 
    <div className="LinkPos mb-4"><Link style={{ color: "#AF05E1", fontSize: "14px", fontWeight: "700" }} target={"_blank"} to={{ pathname:User.domain?User.domain+"/qr+"+ user.userName: "https://card.gotodo.link/qr/" + user.userName }}>
      {User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName}
    </Link><a className="me-1" style={{ fontSize: 24, fontWeight: "700" }} onClick={() => { copyToClipboard(User.domain?User.domain +"/qr/"+ user.userName:"https://card.gotodo.link/qr/" + user.userName) }}>
      </a>
      <b style={{ color: "#000DC2", fontWeight: "200", fontSize: "14px" }}>{props.t("'s Links")}</b>

    </div>
  </Col>
</Row>
      )}
    
      {authorize([Role.User, Role.Dev]) ?
        (<Card style={{ borderRadius: "22px", position: "relative", bottom: "0px" }} >
          <CardBody>
            <Nav className="icon-tab nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customIconActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleIconCustom("1")
                  }}
                >
                  <span className="d-none d-sm-block">
                    <i className="fas fa-home"></i><b>{props.t("Links")} </b>
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customIconActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleIconCustom("2")
                  }}
                >
                  <span className="d-none d-sm-block" >
                    <i className="bx bx-message-square-x"></i><b>{props.t("Design")} </b>
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="bx bx-message-square-x"></i>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customIconActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleIconCustom("3")
                  }}
                >
                  <span className="d-none d-sm-block">
                    <i className="fas fa-user"></i><b>{props.t("Profile")}</b>
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="fas fa-user"></i>
                  </span>
                </NavLink>
              </NavItem>

            </Nav>

            <TabContent
              activeTab={customIconActiveTab}
              className="text-muted"
            >
              <TabPane tabId="1">
                <div className="mb-4 App">

                </div>
                <ToastContainer style={{ position: "absolute", top: "39px", left: "1229px" }}
                  autoClose={3000}
                />

                <Row >
                  <Col  >
                    <Row>
                      <SortableList items={socialMedias} onSortEnd={handleOnDragSocialMediasEnd_v2} axis={"xy"} />
                    </Row>
                    <Row style={{ position: "relative" ,bottom:"30px"}}>


                      < Col md={12}>
                        <Row className="mb-4">
                          <Col md="4" lg="4" sm="6" xs="6" xl="4">
                            <button
                              type="button"
                              className="btn-add-link btn save-user me-3"
                              onClick={() => {
                                setIsEdit(false);
                                setEditLink([{ link: "", name: "", photo: "", count: "" }])
                                setselectedFiles([]);
                                addLinkToggle()
                                setLinkName("")
                                setTabForLink("1")

                              }}
                            ><b style={{ color: "white", fontSize: "15px" }}> +{props.t("Add Link")}</b>

                            </button>
                          </Col>
                          <Col md="4" lg="4" sm="6" xs="6" xl="4">
                            <button
                              type="button"
                              className="btn-add-link btn save-user me-3"
                              onClick={() => {
                                setIsEdit(false);
                                setEditLink([{ link: "", name: "", photo: "", count: "" }])
                                setselectedFiles([]);
                                addLinkToggle()
                                setLinkName("")
                                setTabForLink("2")
                              }}
                            ><b style={{ color: "white", fontSize: "15px" }}> +{props.t("Add Module")}</b>

                            </button>
                          </Col>
                          <Col md="4" lg="4" sm="12" xs="12" xl="4">
                            <button
                              type="button"
                              className="addheader-pos btn-add-header btn save-user  me-3"
                              onClick={() => {
                                setIsEdit(false);
                                setEditLink([{ link: "", name: "", photo: "", count: "" }])
                                addHeaderToggle()
                                setLinkName("")

                              }}
                            ><b>+{props.t("Add Header")}</b>
                            </button>
                          </Col>
                        </Row>

                        {!isEmpty(characters) ? <DragDropContext onDragEnd={handleOnDragEnd}  >
                          <Droppable droppableId="characters">
                            {(provided) => (
                              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                {characters.map(({ _id, name, link, photo, count, isType }, index) => {
                                  return (
                                    !isEmpty(_id) ?
                                      <Draggable key={_id} draggableId={_id} index={index}>
                                        {(provided) => (
                                          <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <LinkCard modal={addLinkModal} link={link} name={name} photo={photo} count={count} _id={_id} isType={isType}
                                              addLink={(e) => {
                                                if (e == true) {
                                                  setDeleteIndex(index)
                                                  setIsEdit(true);
                                                  setEditLink([{ link: link, name: name, _id: _id, index: index, photo: photo, count: count }])

                                                  setLinkName(isType)
                                                  addLinkToggle()
                                                  setTabForLink("1")
                                                }
                                              }}
                                              addHeader={(e) => {
                                                if (e == true) {
                                                  setDeleteIndex(index)
                                                  setIsEdit(true);
                                                  setEditLink([{ link: link, name: name, _id: _id, index: index, photo: photo, count: count }])
                                                  addHeaderToggle()
                                                }
                                              }}
                                            />

                                          </li>
                                        )}
                                      </Draggable> : null
                                  );
                                })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext> : null}


                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <div style={{ overflowY: "scroll", height: "650px", overflowX: "hidden" }}>
                  <Row>
                    <h4>{props.t("Hazır Tasarım Seç")}</h4>
                    <Col md="4" sm="6" xs="6" >
                      <ThemeCard mainColor={"#fffff"} Name={"Kar Beyazı"} cardColor={"#fffff"} borderRadius={"0px"} isPhoto={false} fontColor={"#4b4b4b"}
                      fontFamily={"Poppins"} borderColor={"transparent"} border={"0px solid transparent"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "white",
                            cardColor: "#fffff",
                            cardRadius: "56px",
                            font: "Poppins",
                            borderColor: "transparent",
                            border: "0px solid transparent",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            isPhoto: false,
                            fontColor: "#4b4b4b",
                           
                          }
                          dispatch(onUpdateTheme(theme))
                        }} />
                    </Col>

                    <Col md="4" sm="6" xs="6" >
                      <ThemeCard mainColor={"#0E1422"} Name={"Neon"} isPhoto={false} cardColor={"transparent"} borderRadius={"13px"} fontFamily={"Poppins"} borderColor={"#BF30C7"} border={"2px solid #BF30C7"} fontColor={"#DEF5FD"}
                        onClickFunc={(e) => {
                          const theme = { 
                            _id: user.uid,
                            backGroundColor: "#0E1422",
                            cardColor: "transparent",
                            cardRadius: "13px",
                            font: "Poppins",
                            border: "2px solid #BF30C7",
                            borderColor: "#BF30C7",
                            cardShadow: "inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)",
                            isPhoto: false,
                            fontColor: "#DEF5FD",
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>
                    <Col md="4" sm="6" xs="6">
                    <ThemeCard mainColor={"linear-gradient(to right, #6AA5FF,#FF00EB)"} fontColor={"#FBC6FF"} isPhoto={false} Name={"İnstagram"} cardColor={"transparent"} fontFamily={"Quicksand"} borderColor={"#F88BFA"} border={"1px solid #F88BFA"} borderRadius={"13px"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(to right, #6AA5FF,#FF00EB)",
                            cardColor: "transparent",
                            cardRadius: "13px",
                            border: "1px solid #F88BFA",
                            cardShadow: "inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)",
                            font: "Quicksand",
                            borderColor: "#F88BFA",
                            isPhoto: false,
                            fontColor: "#FBC6FF",
                          }
                          dispatch(onUpdateTheme(theme))
                        }} />
                    </Col>
                   

                    <Col md="4" sm="6" xs="6">
                      <ThemeCard mainColor={"linear-gradient(#ffffff, #FDB6FF)"} fontColor={"#A09F9F"} isPhoto={false} Name={"Pamuk Şeker"} cardColor={"#ffffff"} fontFamily={"EB Garamond"} borderColor={"#F88BFA"} border={"1px solid #F88BFA"} borderRadius={"56px"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(#ffffff, #FDB6FF)",
                            cardColor: "#ffffff",
                            cardRadius: "56px",
                            border: "1px solid #F88BFA",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            font: "EB Garamond",
                            borderColor: "#F88BFA",
                            isPhoto: false,
                            fontColor: "#A09F9F",
                          }
                          dispatch(onUpdateTheme(theme))
                        }} />
                    </Col>

                    <Col md="4" sm="6" xs="6">
                    <ThemeCard mainColor={"linear-gradient(#ffffff, #DCF7FF)"} fontColor={"#A09F9F"} isPhoto={false} Name={"Bulut Mavisi"} cardColor={"#ffffff"} fontFamily={"Quicksand"} borderColor={"#99E8FF"} border={"1px solid #99E8FF"} borderRadius={"56px"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(#ffffff, #DCF7FF)",
                            cardColor: "#ffffff",
                            cardRadius: "56px",
                            border: "1px solid #99E8FF",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            font: "Quicksand",
                            borderColor: "#99E8FF",
                            isPhoto: false,
                            fontColor: "#A09F9F",
                          }
                          dispatch(onUpdateTheme(theme))
                        }} />
                    </Col>

                    <Col md="4" sm="6" xs="6">
                      <ThemeCard mainColor={"#000000"} isPhoto={false} Name={"Gece Yarısı"} fontColor={"#9d9d9d"} cardColor={"#000000"} fontFamily={"DM Sans"} borderColor={"#9d9d9d"} border={"1px solid #9d9d9d"} borderRadius={"0px"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "#000000",
                            cardColor: "#000000",
                            cardRadius: "0px",
                            border: "1px solid #9d9d9d",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            font: "DM Sans",
                            borderColor: "#9d9d9d",
                            isPhoto: false,
                            fontColor: "#9d9d9d",
                          }
                          dispatch(onUpdateTheme(theme))
                        }} />
                    </Col>

                   
                    <Col md="4" sm="6" xs="6">
                      <ThemeCard mainColor={"linear-gradient(red, yellow)"} isPhoto={true} fontColor={"#F1BAFF"} Name={"Işın Kılıcı"} cardColor={"transparent"} borderRadius={"56px"} fontFamily={"Quicksand"} backgroundImg={"1665409062673-zoom2.jpg"} borderColor={"#F07BFC"} border={"1px solid #F07BFC"}
                        onClickFunc={(e) => { 

                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(red, yellow)",
                            cardColor: "transparent",
                            cardRadius: "56px",
                            font: "Quicksand",
                            border: "1px solid #F07BFC",
                            cardShadow: "inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)",
                            borderColor: "F07BFC",
                            isPhoto: true,
                            fontColor: "#F1BAFF",
                          }
                          dispatch(onUpdateTheme(theme))
                          const themeV2 = {
                            _id: user.uid,
                            backgroundImage: zoom2 
                        }
                        dispatch(onUpdateBackgroundPhoto(themeV2))
                        }} /> 
                    </Col>

                    <Col md="4" sm="6" xs="6">
                      <ThemeCard mainColor={"linear-gradient(to right, #ccffcc,#ff99cc)"} fontColor={"#black"} isPhoto={false} Name={"Lunapark"} cardColor={"rgb(255 247 247)"} borderRadius={"56px"} fontFamily={"Poppins"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(to right, #ccffcc,#ff99cc)",
                            cardColor: "rgb(255 247 247)",
                            cardRadius: "56px",
                            font: "Poppins",
                            border: "2px solid black",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            borderColor: "black",
                            isPhoto: false,
                            fontColor: "black",
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>
                    <Col md="4" sm="6" xs="6">
                      <ThemeCard mainColor={"linear-gradient(to right, rgb(255, 177, 153), rgb(255, 8, 68))"} isPhoto={false} Name={"Uğur Böceği"} cardColor={"rgb(222 222 223)"} fontFamily={"EB Garamond"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            backGroundColor: "linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))",
                            cardColor: "rgb(222 222 223)",
                            border: "2px solid black",
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                            borderColor: "black",
                            cardRadius: "0px",
                            isPhoto: false,
                            font: "EB Garamond",
                            fontColor: "black"
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>
                  </Row>

                  <Row className="mb-3 mt-2">
                    <h4 style={{ color: "#5D3EBC" }}>{props.t("Create Your Own Design")}</h4>
                    <Col md="8" sm="12" xs="12">
                      <Row>
                        <Col md="12" sm="12" xs="12" className="mt-2">
                          <OutsideClickHandler onOutsideClick={() => {
                            setBackgroundColorPicker(false)
                            setBackgroundColorPicker4(false)
                          }}>
                            {selectedButton == "1" || selectedButton == "3" ? (
                              <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer", }}>
                                {selectedButton == "1" ? (
                                  <CardBody onClick={() => setBackgroundColorPicker(!backgroundColorPicker)}>

                                  </CardBody>
                                ) : <CardBody onClick={() => setBackgroundColorPicker4(!backgroundColorPicker4)}>

                                </CardBody>}
                              </Card>
                            ) : <Card className="mini-stats-wid m-auto" style={{ background: "white", borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", }}>
                            </Card>}

                            {backgroundColorPicker &&

                              <div style={{ position: "absolute", top: "62px", zIndex: "99", left: "112px" }}><ChromePicker color={backgroundColor} disableAlpha={true} onChangeComplete={(data) => {
                                setBackgroundColor(data.rgb)
                                const theme = {
                                  _id: user.uid,
                                  font: User?.font,
                                  backGroundColor: `rgb(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`,
                                  fontColor: User.fontColor,
                                  cardColor: User.cardColor,
                                  cardRadius: User.cardRadius,
                                  border: User.border,
                                  cardShadow: User.cardShadow,
                                  borderColor: User.borderColor,
                                  isPhoto: false
                                }
                                dispatch(onUpdateTheme(theme))
                              }} /></div>

                            }
                            {backgroundColorPicker4 && <div style={{ position: "absolute", top: "51px", zIndex: "99", left: "118px" }}><ChromePicker disableAlpha={true} color={backgroundColor4} onChange={(updatedColor) => {
                              setBackgroundColor4(updatedColor.hex)
                              const theme = {
                                _id: user.uid,
                                font: User?.font,
                                backGroundColor: `linear-gradient(180deg, ${backgroundColor4},${backgroundColor5})`,
                                cardColor: User.cardColor,
                                fontColor: User.fontColor,
                                cardRadius: User.cardRadius,
                                border: User.border,
                                borderColor: User.borderColor,
                                isPhoto: false,
                                cardShadow: User.cardShadow,
                              }
                              dispatch(onUpdateTheme(theme))
                            }} /></div>}
                          </OutsideClickHandler>
                        </Col>
                        <Col md="4" sm="3" xs="3" className="mt-2">
                          <OutsideClickHandler onOutsideClick={() => { setBackgroundColorPicker2(false) }}>
                            {selectedButton == "2" ? (
                              <OutsideClickHandler onOutsideClick={() => { }}>
                                <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer", position: "absolute", top: "50%", left: "100%", transform: "translate(-100%,-50%" }}>
                                  <CardBody onClick={() => setBackgroundColorPicker2(!backgroundColorPicker2)}>
                                    <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                                  </CardBody>

                                </Card>
                              </OutsideClickHandler>
                            ) :
                              <Card className="mini-stats-wid m-auto" style={{ background: "white", borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", position: "absolute", top: "50%", left: "100%", transform: "translate(-100%,-50%" }}>
                              </Card>}
                            {backgroundColorPicker2 &&

                              <div style={{ position: "absolute", top: "131px", zIndex: "99", left: "17px" }}><ChromePicker color={backgroundColor2} disableAlpha={true} onChange={(updatedColor) => {
                                setBackgroundColor2(updatedColor.hex)
                                const theme = {
                                  _id: user.uid,
                                  font: User?.font,
                                  backGroundColor: `linear-gradient(to right, ${backgroundColor2},${backgroundColor3})`,
                                  cardColor: User.cardColor,
                                  fontColor: User.fontColor,
                                  cardRadius: User.cardRadius,
                                  border: User.border,
                                  borderColor: User.borderColor,
                                  isPhoto: false,
                                  cardShadow: User.cardShadow,
                                }
                                dispatch(onUpdateTheme(theme))
                              }}



                              /></div>

                            }
                          </OutsideClickHandler>

                        </Col>
                        <Col md="4" sm="6" xs="6" className="mt-2">
                          <Card className="mini-stats-wid m-auto" style={User?.isPhoto ?
                            { border: "5px solid", overflow: "hidden", borderRadius: "20px", height: "220px", width: "120px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${config.baseImg + User?.backgroundImage})` } :
                            { border: "5px solid", overflow: "hidden", borderRadius: "20px", height: "220px", width: "120px", background: User.backGroundColor }} >
                            <CardBody onClick={() => console.log("a")}>
                              <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                            </CardBody>

                          </Card>
                        </Col>
                        <Col md="4" sm="3" xs="3" className="mt-2">
                          <OutsideClickHandler onOutsideClick={() => { setBackgroundColorPicker3(false) }}>
                            {selectedButton == "2" ? (

                              <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer", position: "absolute", top: "50%", left: "0%", transform: "translate(-0%,-50%" }}>
                                <CardBody onClick={() => setBackgroundColorPicker3(!backgroundColorPicker3)}>
                                  <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                                </CardBody>

                              </Card>

                            ) :
                              <Card className="mini-stats-wid m-auto" style={{ background: "white", borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", position: "absolute", top: "50%", left: "0%", transform: "translate(-0%,-50%" }}>

                              </Card>
                            }
                            {backgroundColorPicker3 &&

                              <div style={{ position: "absolute", top: "131px", zIndex: "99", left: "-54px" }}><ChromePicker color={backgroundColor3} disableAlpha={true} onChange={(updatedColor) => {
                                setBackgroundColor3(updatedColor.hex)
                                const theme = {
                                  _id: user.uid,
                                  font: User?.font,
                                  backGroundColor: `linear-gradient(to right, ${backgroundColor2},${backgroundColor3})`,
                                  cardColor: User.cardColor,
                                  fontColor: User.fontColor,
                                  cardRadius: User.cardRadius,
                                  border: User.border,
                                  borderColor: User.borderColor,
                                  isPhoto: false,
                                  cardShadow: User.cardShadow,
                                }
                                dispatch(onUpdateTheme(theme))
                              }} /></div>
                            }
                          </OutsideClickHandler>
                        </Col>
                        <Col md="12" sm="12" xs="12" className="mt-2">
                          <OutsideClickHandler onOutsideClick={() => { setBackgroundColorPicker5(false) }}>
                            {selectedButton == "3" ? (

                              <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer", }}>
                                <CardBody onClick={() =>
                                  setBackgroundColorPicker5(!backgroundColorPicker5)

                                }>
                                  <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                                </CardBody>

                              </Card>

                            ) :
                              <Card className="mini-stats-wid m-auto" style={{ background: "white", borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", }}>
                              </Card>
                            }
                            {backgroundColorPicker5 &&

                              <div style={{ position: "absolute", top: "56px", zIndex: "99", left: "112px" }}><ChromePicker color={backgroundColor5} disableAlpha={true} onChange={(updatedColor) => {
                                setBackgroundColor5(updatedColor.hex)
                                const theme = {
                                  _id: user.uid,
                                  font: User?.font,
                                  backGroundColor: `linear-gradient(180deg, ${backgroundColor4},${backgroundColor5})`,
                                  cardColor: User.cardColor,
                                  fontColor: User.fontColor,
                                  cardRadius: User.cardRadius,
                                  border: User.border,
                                  borderColor: User.borderColor,
                                  isPhoto: false
                                }
                                dispatch(onUpdateTheme(theme))
                              }} /></div>
                            }
                          </OutsideClickHandler>
                        </Col>

                      </Row>
                    </Col>
                    <Col md="4" sm="12" xs="12" className="text-center" style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                      <Row className="mt-5" style={{ position: "relative", top: "-27px" }}>
                        <Col md="12">
                          {selectedButton == "1" ? (
                            <button style={{ backgroundColor: "#AF05E1", borderRadius: "17px", color: "#5D3EBC", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)" }}
                              className="btn save-user mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("1")
                              }}
                            >
                              <b style={{ color: "white", fontSize: "15px" }}> {props.t("Straight")}</b>

                            </button>
                          ) :
                            <button style={{ backgroundColor: "white", borderRadius: "17px", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)", color: "#5D3EBC", fontSize: "15px", fontWeight: "600" }}
                              className="btn-add-background btn save-user mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("1")
                              }}
                            >

                              <b style={{}}>{props.t("Straight")}</b>
                            </button>
                          }

                        </Col>
                        <Col md="12">
                          {selectedButton == "2" ? (
                            <button style={{ backgroundColor: "#AF05E1", borderRadius: "17px", color: "#5D3EBC", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)" }}
                              className="btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("2")
                              }}
                            >
                              <b style={{ color: "white", fontSize: "15px" }}> {props.t("Horizontal Transition")}</b>
                            </button>
                          ) :
                            <button style={{ backgroundColor: "white", borderRadius: "17px", width: "100%", height: "50px", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)", color: "#5D3EBC", fontSize: "15px", fontWeight: "600" }}
                              className="btn-add-background btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("2")
                              }}
                            >
                              <b style={{}}> {props.t("Horizontal Transition")}</b>
                            </button>}

                        </Col>

                        <Col md="12">
                          {selectedButton == "3" ? (
                            <button style={{ backgroundColor: "#AF05E1", borderRadius: "17px", color: "#5D3EBC", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)", color: "white", fontSize: "15px" }}
                              className="btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("3")
                              }}
                            >
                              <b style={{ color: "white", fontSize: "15px" }}> {props.t("Vertical Transition")}</b>

                            </button>
                          ) :
                            <button style={{ backgroundColor: "white", borderRadius: "17px", width: "100%", height: "50px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)", color: "#5D3EBC", fontSize: "15px" }}
                              className="btn-add-background btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                setSelectedButton("3")
                              }}
                            >
                              <b style={{}}> {props.t("Vertical Transition")}</b>

                            </button>

                          }

                        </Col>
                        <Col md="12">
                          {selectedButton == "4" ? (
                            <button style={{ backgroundColor: "#AF05E1", borderRadius: "17px", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)" }}
                              className="btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                addBackgroundToggle()
                              }}
                            >

                              <b style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>  {props.t("Photo")}</b>
                            </button>
                          ) :
                            <button style={{ backgroundColor: "white", borderRadius: "17px", width: "100%", height: "50px", fontSize: "19px", fontWeight: "500", boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)", color: "#5D3EBC", fontSize: "15px", fontWeight: "600" }}
                              className="btn-add-background btn save-user btn-block mt-3 "
                              type="button"
                              onClick={() => {
                                resetColorPalette()
                                addBackgroundToggle() 
                              }}
                            >

                              <b style={{}}>  {props.t("Photo")}</b>
                            </button>
                          }


                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-3 mt-2 " style={{ paddingLeft: "5px", paddingRight: "5px" }} >
                    <b className="mb-3 mt-3" style={{ color: "#5D3EBC", fontSize: "16px" }}>{props.t("Button Type")}</b>
                    {User?.cardRadius == "56px" ? (
                      <Col md="4" sm="6" xs="4" className="text-center">
                        <ButtonCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"56px"} border={"2px solid black"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4" className="text-center">
                      <ButtonCard Name={"monospace"} cardColor={"transparent"} borderRadius={"56px"} border={"2px solid black"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: "56px",
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User.cardShadow,

                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                    {User?.cardRadius == "13px" ? (
                      <Col md="4" sm="6" xs="4">
                        <ButtonCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"2px solid black"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ButtonCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"2px solid black"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: "13px",
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User.cardShadow,
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.cardRadius == "0px" ? (
                      <Col md="4" sm="6" xs="4">
                        <ButtonCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"0px"} border={"2px solid black"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ButtonCard Name={"monospace"} cardColor={"transparent"} borderRadius={"0px"} border={"2px solid black"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: "0px",
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User.cardShadow,
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                  </Row>
                  <Row className="mb-3 mt-2 " style={{ paddingLeft: "5px", paddingRight: "5px" }} >
                    <b className="mb-3 mt-3" style={{ color: "#5D3EBC", fontSize: "16px" }}>{props.t("Shadow Type")}</b>
                    {User?.cardShadow == "0px 3px 6px rgb(0 0 0 / 30%)" ? (
                      <Col md="4" sm="6" xs="4" className="text-center">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"0px 3px 6px rgb(0 0 0 / 30%)"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4" className="text-center">
                      <ShadowCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"0px 3px 6px rgb(0 0 0 / 30%)"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User?.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: "0px 3px 6px rgb(0 0 0 / 30%)"

                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                    {User?.cardShadow == "inset 0px 3px 6px rgb(0 0 0 / 30%)" ? (
                      <Col md="4" sm="6" xs="4">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"inset 0px 3px 6px rgb(0 0 0 / 30%)"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ShadowCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"inset 0px 3px 6px rgb(0 0 0 / 30%)"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User?.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: "inset 0px 3px 6px rgb(0 0 0 / 30%)"
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.cardShadow == "inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)" ? (
                      <Col md="4" sm="6" xs="4">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ShadowCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"0px solid black"} boxShadow={"inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: User?.border,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: "inset 0px 3px 6px rgb(0 0 0 / 30%) , 0px 3px 6px rgb(0 0 0 / 30%)"
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                  </Row>

                  <Row className="mb-3 mt-2 " style={{ paddingLeft: "5px", paddingRight: "5px" }} >
                    <b className="mb-3 mt-3" style={{ color: "#5D3EBC", fontSize: "16px" }}>{props.t("Border Type")}</b>
                    {User?.border == "0px solid black" ? (
                      <Col md="4" sm="6" xs="4" className="text-center">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"0px solid black"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4" className="text-center">
                      <ShadowCard Name={"monospace"} cardColor={"rgb(223 223 223)"} borderRadius={"13px"} border={"0px solid black"}
                        onClickFunc={(e) => {
                          const theme = { 
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: `0px solid ${User?.borderColor}`,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow

                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                    {User?.border == "2px solid black" ? (
                      <Col md="4" sm="6" xs="4">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"2px solid black"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ShadowCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"2px solid black"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: `2px solid ${User?.borderColor}`,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.border == "4px solid" ? (
                      <Col md="4" sm="6" xs="4">
                        <ShadowCard Name={"monospace"} cardColor={"#af05e1"} borderRadius={"13px"} border={"4px solid black"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="4">
                      <ShadowCard Name={"monospace"} cardColor={"transparent"} borderRadius={"13px"} border={"4px solid black"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            cardRadius: User.cardRadius,
                            cardColor: User?.cardColor,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            border: `4px solid ${User?.borderColor}`,
                            borderColor: User?.borderColor,
                            fontColor: User.fontColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                  </Row>
                  <Row className="mb-3 mt-2" >
                    <Col md="4" sm="4" xs="4" >
                      <OutsideClickHandler onOutsideClick={() => { setButtonColorPicker(false) }}>
                        <p className="text-center" style={{ color: "#5D3EBC", fontSize: "14px", fontWeight: "600" }}>{props.t("Button")}</p>
                        <Row>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer" }}>
                              <CardBody onClick={() =>
                                setButtonColorPicker(!buttonColorPicker)
                              }>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>

                            </Card>
                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto mt-2" style={{ background: User?.cardColor, borderRadius: "0px", wordBreak: "break-all", height: "45px", width: "45px", cursor: "pointer", border: "1px solid" }}>
                              <CardBody onClick={() =>
                                setButtonColorPicker(!buttonColorPicker)
                              }>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>

                            </Card>
                          </Col>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                        </Row>



                        {buttonColorPicker &&

                          <div className="colorPicker-position" style={{ position: "absolute", top: "105px", zIndex: "99" }} >
                            <ChromePicker color={buttonColor} onChange={data => {
                              setButtonColor(data.rgb);

                              const theme = {
                                _id: user.uid,
                                font: User?.font,
                                backGroundColor: User.backGroundColor,
                                cardColor: `rgb(${buttonColor.r},${buttonColor.g},${buttonColor.b},${buttonColor.a})`,
                                fontColor: User.fontColor,
                                cardRadius: User.cardRadius,
                                border: User.border,
                                borderColor: User.borderColor,
                                isPhoto: User?.isPhoto,
                                cardShadow: User?.cardShadow
                              }
                              dispatch(onUpdateTheme(theme))
                            }} />
                          </div>
                        }
                      </OutsideClickHandler>
                    </Col>
                    <Col md="4" sm="4" xs="4" >
                      <OutsideClickHandler onOutsideClick={() => { setBorderColorPicker(false) }}>
                        <p className="text-center" style={{ color: "#5D3EBC", fontSize: "14px", fontWeight: "600" }}>{props.t("Frame")}</p>
                        <Row>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer" }}>
                              <CardBody onClick={() =>
                                setBorderColorPicker(!borderColorPicker)
                              }>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>

                            </Card>
                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto mt-2" style={{ background: User?.borderColor, borderRadius: "0px", wordBreak: "break-all", height: "45px", width: "45px", cursor: "pointer", border: "1px solid" }}>
                              <CardBody onClick={() =>

                                setBorderColorPicker(!borderColorPicker)
                              }>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                        </Row>



                        {borderColorPicker &&

                          <div className="colorPicker-position" style={{ position: "absolute", top: "105px", zIndex: "99" }}><ChromePicker color={borderColor} onChange={data => {
                            setBorderColor(data.rgb)
                            const theme = {
                              _id: user.uid,
                              font: User?.font,
                              backGroundColor: User.backGroundColor,
                              cardColor: User.cardColor,
                              fontColor: User.fontColor,
                              cardRadius: User.cardRadius,
                              border: User.border,
                              cardShadow: User?.cardShadow,

                              borderColor: `rgb(${borderColor.r},${borderColor.g},${borderColor.b},${borderColor.a})`,
                              isPhoto: User?.isPhoto
                            }
                            dispatch(onUpdateTheme(theme))


                          }} /></div>
                        }
                      </OutsideClickHandler>
                    </Col>

                    <Col md="4" sm="4" xs="4" >
                      <OutsideClickHandler onOutsideClick={() => { setTextColorPicker(false) }}>
                        <p className="text-center" style={{ color: "#5D3EBC", fontSize: "14px", fontWeight: "600" }}>{props.t("Font")}</p>
                        <Row>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto" style={{ backgroundImage: `url(${colorPalette})`, borderRadius: "120px", wordBreak: "break-all", height: "60px", width: "60px", cursor: "pointer" }}>
                              <CardBody onClick={() =>
                                setTextColorPicker(!textColorPicker)}>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>

                            </Card>
                          </Col>
                          <Col md="4" sm="12" xs="12" lg="4" xl="4" >
                            <Card className="mini-stats-wid m-auto mt-2" style={{ background: User?.fontColor, borderRadius: "0px", wordBreak: "break-all", height: "45px", width: "45px", cursor: "pointer", border: "1px solid" }}>
                              <CardBody onClick={() =>
                                setTextColorPicker(!textColorPicker)}>
                                <p className="text-center" style={{ fontFamily: props.fontFamily }}>{props.Name}</p>
                              </CardBody>

                            </Card>
                          </Col>
                          <Col md="2" sm="12" xs="0" lg="2" xl="2" >

                          </Col>
                        </Row>



                        {textColorPicker && <div className="colorPicker-position" style={{ position: "absolute", top: "105px", zIndex: "99", right: "30px" }}><ChromePicker color={textColor} onChange={data => {
                          setTextColor(data.rgb)
                          const theme = {
                            _id: user.uid,
                            font: User?.font,
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            fontColor: `rgb(${textColor.r},${textColor.g},${textColor.b},${textColor.a})`,
                            cardRadius: User.cardRadius,
                            border: User.border,
                            borderColor: User.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))


                        }
                        } /></div>}
                      </OutsideClickHandler>
                    </Col>
                  </Row>

                  <Row className="mb-3 mt-2" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                    <b className="mb-3" style={{ color: "#5D3EBC", fontSize: "16px" }}>{props.t("Font")}</b>
                    {User?.font == "Poppins" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"Poppins"} cardColor={"#AF05E1"} fontFamily={"Poppins"} fontColor={"white"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) :
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"Poppins"} cardColor={"white"} fontFamily={"Poppins"} fontColor={"#AF05E1"}
                          onClickFunc={(e) => {
                            const theme = {
                              _id: user.uid,
                              font: "Poppins",
                              backGroundColor: User.backGroundColor,
                              cardColor: User.cardColor,
                              cardRadius: User.cardRadius,
                              fontColor: User.fontColor,
                              border: User.border,
                              borderColor: User?.borderColor,
                              isPhoto: User?.isPhoto,
                              cardShadow: User?.cardShadow
                            }
                            dispatch(onUpdateTheme(theme))

                          }} />
                      </Col>}
                    {User?.font == "Inter" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"Inter"} cardColor={"#AF05E1"} fontFamily={"Inter"} fontColor={"white"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="6">
                      <FontCard Name={"Inter"} cardColor={"white"} fontFamily={"Inter"} fontColor={"#AF05E1"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            font: "Inter",
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            cardRadius: User.cardRadius,
                            fontColor: User.fontColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.font == "DM Sans" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"DM Sans"} cardColor={"#AF05E1"} fontFamily={"DM Sans"} fontColor={"white"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="6">
                      <FontCard Name={"DM Sans"} cardColor={"white"} fontFamily={"DM Sans"} fontColor={"#AF05E1"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            font: "DM Sans",
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            cardRadius: User.cardRadius,
                            fontColor: User.fontColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.font == "PT Sans" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"PT Sans"} cardColor={"#AF05E1"} fontFamily={"PT Sans"} fontColor={"white"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="6">
                      <FontCard Name={"PT Sans"} cardColor={"white"} fontFamily={"PT Sans"} fontColor={"#AF05E1"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            font: "PT Sans",
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            cardRadius: User.cardRadius,
                            fontColor: User.fontColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.font == "Quicksand" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"Quicksand"} cardColor={"#AF05E1"} fontFamily={"Quicksand"} fontColor={"white"}
                          onClickFunc={(e) => {

                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="6">
                      <FontCard Name={"Quicksand"} cardColor={"white"} fontFamily={"Quicksand"} fontColor={"#AF05E1"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            font: "Quicksand",
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            cardRadius: User.cardRadius,
                            fontColor: User.fontColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}
                    {User?.font == "EB Garamond" ? (
                      <Col md="4" sm="6" xs="6">
                        <FontCard Name={"EB Garamond"} cardColor={"#AF05E1"} fontFamily={"EB Garamond"} fontColor={"white"}
                          onClickFunc={(e) => {


                          }} />
                      </Col>
                    ) : <Col md="4" sm="6" xs="6">
                      <FontCard Name={"EB Garamond"} cardColor={"white"} fontFamily={"EB Garamond"} fontColor={"#AF05E1"}
                        onClickFunc={(e) => {
                          const theme = {
                            _id: user.uid,
                            font: "EB Garamond",
                            backGroundColor: User.backGroundColor,
                            cardColor: User.cardColor,
                            cardRadius: User.cardRadius,
                            fontColor: User.fontColor,
                            border: User.border,
                            borderColor: User?.borderColor,
                            isPhoto: User?.isPhoto,
                            cardShadow: User?.cardShadow
                          }
                          dispatch(onUpdateTheme(theme))

                        }} />
                    </Col>}

                  </Row>

                </div>

              </TabPane>
              <TabPane tabId="3">
             
                <ToastContainer style={{ position: "absolute", top: "39px", left: "1229px" }}
                  autoClose={3000}
                />

                {User.corporate && User.corporate == true ? (
                   <Col md="12" >
                   <AvForm
                     onValidSubmit={handleValidProfileSubmit}
                     autoComplete="off"
                   >
                     <Row>
                        <Col md="3" sm="5" xs="5" className="mb-3">
                          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                            <div>
                              {User?.photo ? <img src={config.baseImg + User?.photo} style={{ width: "140px", height: "140px", objectFit: "contain", cursor: "pointer" }} onClick={() => {

                                addPhotoToggle()
                              }} />
                                : <img src={imgEmpty} style={{ width: "140px", height: "140px", objectFit: "contain", cursor: "pointer" }} onClick={() => {

                                  addPhotoToggle()
                                }} />}
                            </div>


                          </div>
                        </Col>

                        <Col md="9" sm="7" xs="12" className="mt-3" >
                          <div className="mb-3" >
                            <AvField
                              name="fullName"
                              placeholder={props.t("Full Name")}
                              type="text"
                              disabled
                              value={User.fullName}

                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="bio"
                              placeholder={props.t("Your Message")}
                              type="text"
                              disabled
                              value={User.bio}
                            />
                          </div>
                        </Col>

                        {!isEmpty(profileInfo) ? <DragDropContext onDragEnd={handleOnDragProfileInfosEnd}>
                          <Droppable droppableId="profileInfos">
                            {(provided) => (
                              <ul className="profileInfos" {...provided.droppableProps} ref={provided.innerRef}>

                                {profileInfo.map(({ _id, name, info, icon }, index) => {
                                  return (
                                    <Draggable key={_id} draggableId={_id} index={index}>

                                      {(provided) => (
                                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <Col md="12">
                                            <Card className="mini-stats-wid" style={{ backgroundColor: "rgb(247 247 247)", borderRadius: "12px", wordBreak: "break-all", height: "55px" }}>
                                              <CardBody>
                                                <Row md="12" lg="12" sm="12" xs="12" style={{ position: "relative", bottom: "15px", right: "15px" }}>
                                                  <Media
                                                    onClick={() => {
                                                      addInfoToggle()
                                                    }}>
                                                    <Col md="4" lg="4" sm="4" xs="5" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "12px", left: "-5px" }}>
                                                        <b className="max-line-1">

                                                          {props.t(name)}
                                                          <br />
                                                        </b>

                                                      </Media>
                                                    </Col>
                                                    <Col className="card-position" md="4" lg="4" sm="4" xs="4" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "20px" }}>

                                                        <i className=
                                                          {icon}
                                                          style={{ fontSize: "30px", position: "relative", top: "-15px", left: "-41px" }} ></i>
                                                        <p className="max-line-1" style={{ fontWeight: "500", position: "relative", left: "-6px", top: "-41px" }}> {info}</p>
                                                      </Media>
                                                    </Col>


                                                    <Col md="2" lg="2" sm="2" xs="2" xl="2" className="text-end ">

                                                      <div
                                                        style={{
                                                          backgroundColor: "#AF05E1", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "55px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(175, 5, 225)",

                                                        }}
                                                        className="btn btn-success btn-sm me-2"
                                                      >
                                                        <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "9px" }}> </div>
                                                      </div>

                                                    </Col>

                                                  </Media>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>

                                        </li>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext> : null}
                      </Row>
                     <Row>
                     

                       
                 
                       
                     </Row>
                     <Col md="12">
                            <div className=" d-grid text-end">
                              <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                className="btn save-user"
                                type="button"
                                onClick={() => {
                                  addInfoToggle() 
                                }}
                              >
                                +{props.t("Add Information")}
                              </button>
                            </div>
                          </Col>



                     <Col md="12">
                       <Row>

                        
                         <Row className="mt-3" >
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "1" ? (
                               <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg} borderColor={"rgb(175, 5, 225)"}

                                 onClickFunc={(e) => {

                                   const style = {
                                     _id: user.uid,
                                     style: "1"
                                   }
                                   dispatch(onAddStyle(style))


                                 }} />

                             ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg} border={"4px solid"} borderColor={"white"}
                               onClickFunc={(e) => {

                                 const style = {
                                   _id: user.uid,
                                   style: "1"
                                 }
                                 dispatch(onAddStyle(style))


                               }} />
                             }
                           </Col>
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "2" ? (
                               <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg2} borderColor={"rgb(175, 5, 225)"}
                                 onClickFunc={(e) => {
                                   const style = {
                                     _id: user.uid,
                                     style: "2"
                                   }
                                   dispatch(onAddStyle(style))

                                 }} />) :
                               <StyleCard borderRadius={"1px"} backgroundImage={styleImg2} border={"4px solid"} borderColor={"white"}
                                 onClickFunc={(e) => {
                                   const style = {
                                     _id: user.uid,
                                     style: "2"
                                   }
                                   dispatch(onAddStyle(style))

                                 }} />}
                           </Col>
                          
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "3" ? (
                               <StyleCard borderRadius={"1px"} border={"9px solid"} backgroundImage={styleImg3} borderColor={"rgb(175, 5, 225)"}

                                 onClickFunc={(e) => {

                                   const style = {
                                     _id: user.uid,
                                     style: "3"
                                   }
                                   dispatch(onAddStyle(style))


                                 }} />

                             ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg3} border={"9px solid"} borderColor={"white"}
                               onClickFunc={(e) => {

                                 const style = {
                                   _id: user.uid,
                                   style: "3"
                                 }
                                 dispatch(onAddStyle(style))


                               }} />
                             }
                           </Col>





                         </Row>
                         <Col md="12">
                           <div className=" d-grid text-end">
                             <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                               className="btn save-user"
                               type="button"
                               onClick={() => {
                                 addUserToggle()
                               }}
                             >
                               +{props.t("Add User")}
                             </button>
                           </div>
                         </Col>
                        
                       </Row>

                     </Col>
                   </AvForm>
                  
                 </Col>
                ) : (
                  <Col md="12" >
                    <AvForm
                      onValidSubmit={handleValidProfileSubmit}
                      autoComplete="off"
                    >
                      <Row>
                        <Col md="3" sm="5" xs="5" className="mb-3">
                          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                            <div>
                              {User?.photo ? <img src={config.baseImg + User?.photo} style={{ width: "140px", height: "140px", objectFit: "contain", cursor: "pointer" }} onClick={() => {

                                addPhotoToggle()
                              }} />
                                : <img src={imgEmpty} style={{ width: "140px", height: "140px", objectFit: "contain", cursor: "pointer" }} onClick={() => {

                                  addPhotoToggle()
                                }} />}
                            </div>


                          </div>
                        </Col>

                        <Col md="9" sm="7" xs="12" className="mt-3" >
                          <div className="mb-3" >
                            <AvField
                              name="fullName"
                              placeholder={props.t("Full Name")}
                              type="text"
                              disabled
                              value={User.fullName}

                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="bio"
                              placeholder={props.t("Your Message")}
                              type="text"
                              disabled
                              value={User.bio}
                            />
                          </div>
                        </Col>

                        {!isEmpty(profileInfo) ? <DragDropContext onDragEnd={handleOnDragProfileInfosEnd}>
                          <Droppable droppableId="profileInfos">
                            {(provided) => (
                              <ul className="profileInfos" {...provided.droppableProps} ref={provided.innerRef}>

                                {profileInfo.map(({ _id, name, info, icon }, index) => {
                                  return (
                                    <Draggable key={_id} draggableId={_id} index={index}>

                                      {(provided) => (
                                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <Col md="12">
                                            <Card className="mini-stats-wid" style={{ backgroundColor: "rgb(247 247 247)", borderRadius: "12px", wordBreak: "break-all", height: "55px" }}>
                                              <CardBody>
                                                <Row md="12" lg="12" sm="12" xs="12" style={{ position: "relative", bottom: "15px", right: "15px" }}>
                                                  <Media
                                                    onClick={() => {
                                                      addInfoToggle()
                                                    }}>
                                                    <Col md="4" lg="4" sm="4" xs="5" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "12px", left: "-5px" }}>
                                                        <b className="max-line-1">

                                                          {props.t(name)}
                                                          <br />
                                                        </b>

                                                      </Media>
                                                    </Col>
                                                    <Col className="card-position" md="4" lg="4" sm="4" xs="4" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "20px" }}>

                                                        <i className=
                                                          {icon}
                                                          style={{ fontSize: "30px", position: "relative", top: "-15px", left: "-41px" }} ></i>
                                                        <p className="max-line-1" style={{ fontWeight: "500", position: "relative", left: "-6px", top: "-41px" }}> {info}</p>
                                                      </Media>
                                                    </Col>


                                                    <Col md="2" lg="2" sm="2" xs="2" xl="2" className="text-end ">

                                                      <div
                                                        style={{
                                                          backgroundColor: "#AF05E1", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "55px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(175, 5, 225)",

                                                        }}
                                                        className="btn btn-success btn-sm me-2"
                                                      >
                                                        <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "9px" }}> </div>
                                                      </div>

                                                    </Col>

                                                  </Media>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>

                                        </li>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext> : null}
                      </Row>




                      <Col md="12">
                        <Row>

                          <Col md="12">
                            <div className=" d-grid text-end">
                              <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                className="btn save-user"
                                type="button"
                                onClick={() => {
                                  addInfoToggle()
                                }}
                              >
                                +{props.t("Add Information")}
                              </button>
                            </div>
                          </Col>
                          <Row className="mt-3" >
                            <Col md="4" sm="6" xs="6" >
                              {User?.style == "1" ? (
                                <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg} borderColor={"rgb(175, 5, 225)"}

                                  onClickFunc={(e) => {

                                    const style = {
                                      _id: user.uid,
                                      style: "1"
                                    }
                                    dispatch(onAddStyle(style))


                                  }} />

                              ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg} border={"4px solid"} borderColor={"white"}
                                onClickFunc={(e) => {

                                  const style = {
                                    _id: user.uid,
                                    style: "1"
                                  }
                                  dispatch(onAddStyle(style))


                                }} />
                              }
                            </Col>
                            <Col md="4" sm="6" xs="6" >
                              {User?.style == "2" ? (
                                <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg2} borderColor={"rgb(175, 5, 225)"}
                                  onClickFunc={(e) => {
                                    const style = {
                                      _id: user.uid,
                                      style: "2"
                                    }
                                    dispatch(onAddStyle(style))

                                  }} />) :
                                <StyleCard borderRadius={"1px"} backgroundImage={styleImg2} border={"4px solid"} borderColor={"white"}
                                  onClickFunc={(e) => {
                                    const style = {
                                      _id: user.uid,
                                      style: "2"
                                    }
                                    dispatch(onAddStyle(style))

                                  }} />}
                            </Col>
                           
                            <Col md="4" sm="6" xs="6" >
                              {User?.style == "3" ? (
                                <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg3} borderColor={"rgb(175, 5, 225)"}

                                  onClickFunc={(e) => {

                                    const style = {
                                      _id: user.uid,
                                      style: "3"
                                    }
                                    dispatch(onAddStyle(style))


                                  }} />

                              ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg3} border={"4px solid"} borderColor={"white"}
                                onClickFunc={(e) => {

                                  const style = {
                                    _id: user.uid,
                                    style: "3"
                                  }
                                  dispatch(onAddStyle(style))


                                }} />
                              }
                            </Col>





                          </Row>

                          {/* <Col md="12" className="text-end">
          <div className="mt-1 d-grid text-end">
            <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
              className="btn save-user"
              type="submit"
            >
              {props.t("Save")}
            </button>
          </div>
        </Col> */}
                        </Row>

                      </Col>
                    </AvForm>
                  </Col>
                )}


              </TabPane>

            </TabContent>
          </CardBody>
        </Card>) : (
          <div style={{ position: "relative", top: "-50px" }}>
            
            <ToastContainer style={{ position: "absolute", top: "39px", left: "1229px" }}
                  autoClose={3000}
                />

                {User.corporate && User.corporate == true ? (
                   <Col md="12" >
                   <AvForm
                     onValidSubmit={handleValidProfileSubmit}
                     autoComplete="off"
                   >
                    
                     <Row>
                     

                       
                 
                       {!isEmpty(profileInfo) ? <DragDropContext onDragEnd={handleOnDragProfileInfosEnd}>
                         <Droppable droppableId="profileInfos">
                           {(provided) => (
                             <ul className="profileInfos" {...provided.droppableProps} ref={provided.innerRef}>

                               {profileInfo.map(({ _id, name, info, icon }, index) => {
                                 return (
                                   <Draggable key={_id} draggableId={_id} index={index}>

                                     {(provided) => (
                                       <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                         <Col md="12">
                                           <Card className="mini-stats-wid" style={{ backgroundColor: "rgb(247 247 247)", borderRadius: "12px", wordBreak: "break-all", height: "55px" }}>
                                             <CardBody>
                                               <Row md="12" lg="12" sm="12" xs="12" style={{ position: "relative", bottom: "15px", right: "15px" }}>
                                                 <Media
                                                   onClick={() => {
                                                     addInfoToggle()
                                                   }}>
                                                   <Col md="4" lg="4" sm="4" xs="5" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                     <Media body style={{ position: "relative", top: "12px", left: "-5px" }}>
                                                       <b className="max-line-1">

                                                         {props.t(name)}
                                                         <br />
                                                       </b>

                                                     </Media>
                                                   </Col>
                                                   <Col className="card-position" md="4" lg="4" sm="4" xs="4" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                     <Media body style={{ position: "relative", top: "20px" }}>

                                                       <i className=
                                                         {icon}
                                                         style={{ fontSize: "30px", position: "relative", top: "-15px", left: "-41px" }} ></i>
                                                       <p className="max-line-1" style={{ fontWeight: "500", position: "relative", left: "-6px", top: "-41px" }}> {info}</p>
                                                     </Media>
                                                   </Col>


                                                   <Col md="2" lg="2" sm="2" xs="2" xl="2" className="text-end ">

                                                     <div
                                                       style={{
                                                         backgroundColor: "#AF05E1", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "55px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(175, 5, 225)",

                                                       }}
                                                       className="btn btn-success btn-sm me-2"
                                                     >
                                                       <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "9px" }}> </div>
                                                     </div>

                                                   </Col>

                                                 </Media>
                                               </Row>
                                             </CardBody>
                                           </Card>
                                         </Col>

                                       </li>
                                     )}
                                   </Draggable>
                                 );
                               })}
                               {provided.placeholder}
                             </ul>
                           )}
                         </Droppable>
                       </DragDropContext> : null}
                     </Row>




                     <Col md="12">
                       <Row>

                        
                         <Row className="mt-3" >
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "1" ? (
                               <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg} borderColor={"rgb(175, 5, 225)"}

                                 onClickFunc={(e) => {

                                   const style = {
                                     _id: user.uid,
                                     style: "1"
                                   }
                                   dispatch(onAddStyle(style))


                                 }} />

                             ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg} border={"4px solid"} borderColor={"white"}
                               onClickFunc={(e) => {

                                 const style = {
                                   _id: user.uid,
                                   style: "1"
                                 }
                                 dispatch(onAddStyle(style))


                               }} />
                             }
                           </Col>
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "2" ? (
                               <StyleCard borderRadius={"1px"} border={"4px solid"} backgroundImage={styleImg2} borderColor={"rgb(175, 5, 225)"}
                                 onClickFunc={(e) => {
                                   const style = {
                                     _id: user.uid,
                                     style: "2"
                                   }
                                   dispatch(onAddStyle(style))

                                 }} />) :
                               <StyleCard borderRadius={"1px"} backgroundImage={styleImg2} border={"4px solid"} borderColor={"white"}
                                 onClickFunc={(e) => {
                                   const style = {
                                     _id: user.uid,
                                     style: "2"
                                   }
                                   dispatch(onAddStyle(style))

                                 }} />}
                           </Col>
                          
                           <Col md="4" sm="6" xs="6" >
                             {User?.style == "3" ? (
                               <StyleCard borderRadius={"1px"} border={"9px solid"} backgroundImage={styleImg3} borderColor={"rgb(175, 5, 225)"}

                                 onClickFunc={(e) => {

                                   const style = {
                                     _id: user.uid,
                                     style: "3"
                                   }
                                   dispatch(onAddStyle(style))


                                 }} />

                             ) : <StyleCard borderRadius={"1px"} backgroundImage={styleImg3} border={"9px solid"} borderColor={"white"}
                               onClickFunc={(e) => {

                                 const style = {
                                   _id: user.uid,
                                   style: "3"
                                 }
                                 dispatch(onAddStyle(style))


                               }} />
                             }
                           </Col>





                         </Row>
                         <Col md="12">
                           <div className=" d-grid text-end">
                             <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                               className="btn save-user"
                               type="button"
                               onClick={() => {
                                 addUserToggle()
                               }}
                             >
                               +{props.t("Add User")}
                             </button>
                           </div>
                         </Col>
                        
                       </Row>

                     </Col>
                   </AvForm>
                  
                 </Col>
                ) : (
                  <Col md="12" > 
                    <AvForm
                      onValidSubmit={handleValidProfileSubmit}
                      autoComplete="off"
                    > 
                    {Profiles?.length <2 ?(
                        <p className="mt-4" style={{fontSize:"16px"}}>Başka Bir Hesabınız mı var?<span style={{color:"rgb(175, 5, 225)",cursor:"pointer"}} onClick={() => combineProfileToggle()}> Hesaplarınızı Birleştirin</span> </p>
                    ):null}
                    
                      <Row className="mt-4">
                      

                       

                        {!isEmpty(profileInfo) ? <DragDropContext onDragEnd={handleOnDragProfileInfosEnd}>
                          <Droppable droppableId="profileInfos">
                            {(provided) => (
                              <ul className="profileInfos" {...provided.droppableProps} ref={provided.innerRef}>

                                {profileInfo.map(({ _id, name, info, icon }, index) => {
                                  return (
                                    <Draggable key={_id} draggableId={_id} index={index}>

                                      {(provided) => (
                                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <Col md="12">
                                            <Card className="mini-stats-wid" style={{ backgroundColor: "rgb(247 247 247)", borderRadius: "12px", wordBreak: "break-all", height: "55px" }}>
                                              <CardBody>
                                                <Row md="12" lg="12" sm="12" xs="12" style={{ position: "relative", bottom: "15px", right: "15px" }}>
                                                  <Media
                                                    >
                                                    <Col md="4" lg="4" sm="4" xs="5" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "12px", left: "-5px" }}>
                                                        <b className="max-line-1">

                                                          {props.t(name)}
                                                          <br />
                                                        </b>

                                                      </Media>
                                                    </Col>
                                                    <Col className="card-position" md="4" lg="4" sm="4" xs="4" xl="4" style={{ position: "relative", left: "14px", wordBreak: "break-all" }} >
                                                      <Media body style={{ position: "relative", top: "20px" }}>

                                                        <i className=
                                                          {icon}
                                                          style={{ fontSize: "30px", position: "relative", top: "-15px", left: "-41px" }} ></i>
                                                        <p className="max-line-1" style={{ fontWeight: "500", position: "relative", left: "-6px", top: "-41px" }}> {info}</p>
                                                      </Media>
                                                    </Col>


                                                    <Col md="2" lg="2" sm="2" xs="2" xl="2" className="text-end ">

                                                      <div
                                                        style={{
                                                          backgroundColor: "#AF05E1", borderEndEndRadius: "12px", borderStartEndRadius: "12px", height: "55px", width: "40px", position: "absolute", top: "-5px", right: "-31px", borderColor: "rgb(175, 5, 225)",

                                                        }}
                                                        className="btn btn-success btn-sm me-2"
                                                      >
                                                        <div style={{ backgroundImage: `url(${dots})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "26px", marginTop: "9px" }}> </div>
                                                      </div>

                                                    </Col>

                                                  </Media>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>

                                        </li>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext> : null}
                      </Row>




                   
                    </AvForm>
                  </Col>
                )}


          </div>
        )}

      {/* <QrCodeModal modal={modalQr} toggle={() => { toggleQr() }} /> */}
      <QrCodeModal2 
      infoQr={User.domain?User.domain+"/p/"+user.userName:`http://card.gotodo.Link/qr/${user.userName}`}
      toggle={()=>{toggleQr2()}}
      modal={modalQr2}
      QRphoto={User.corporateId?config.baseImg +ParentData?.photo: config.baseImg + User?.photo} 
      title={`${user.userName}`}
      user={User}
      />

      < AddHeaderModal modal={addHeaderModal} toggle={() => { addHeaderToggle() }} isEdit={isEdit} editLink={editLink}
        save={(e) => {
          if (e == true) {
            addHeaderToggle()
          }
        }}
        confirmAlert={(e) => {
          if (e == true) {
            setconfirm_alert(true)
          }
        }}
      />
      < SocialLinkModal modal={addSocialLinkModal} toggle={() => { addSocialLinkToggle() }}
        save={(e) => {
          if (e == true) {
            addSocialLinkToggle()
          }
        }} />
      < AddPhotoModal modal={addPhotoModal} size="lg" className="modal-width" toggle={() => { addPhotoToggle() }}
        save={(e) => {
          if (e == true) {
            addPhotoToggle()
          }
        }} />
      < AddBackgroundModal modal={addBackgroundModal} size="lg" className="modal-width" toggle={() => { addBackgroundToggle() }}
        save={(e) => {
          if (e == true) { 
            addBackgroundToggle()
          }
        }} />
      < AddInfoModal modal={addInfoModal} toggle={() => { addInfoToggle()}}
        comeFrom={User.corporate && User.corporate == true? "corporate":"individual"}
        save={(e) => {
          if (e == true) {
            addInfoToggle() 
          }
        }} />
         < AddUserModal modal={addUserModal} toggle={() => { addUserToggle() }}
        save={(e) => {
          if (e == true) {
            addUserToggle()
          }
        }} />
        <CombineProfileModal modal={combineProfileModal} toggle={() => { combineProfileToggle() }}
          save={(e) => {
            if (e == true) {
              combineProfileToggle()
            }
          }}/>
         
      < AddLinkModal modal={addLinkModal} size="lg" LinkName={LinkName} className="modal-width" toggle={() => { addLinkToggle() }} isEdit={isEdit} editLink={editLink} activeTab={TabForLink}
        save={(e) => {
          if (e == true) {
            addLinkToggle()
          }
        }}
        confirmAlert={(e) => {
          if (e == true) {
            setconfirm_alert(true)
          }
        }}
      />
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are You Sure")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Delete")}
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title(props.t("Deleted"));
            deleteItem();
            setAddLinkModal(false)
            setAddHeaderModal(false)


          }}
          onCancel={() => setconfirm_alert(false)}
        >
          {props.t("You won't be able to revert this")}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnText={props.t("Ok")}
          onConfirm={() => {
            setsuccess_dlg(false)

          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}
export default withTranslation()(CashCard)