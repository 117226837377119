import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button, Modal, Alert, ModalHeader, ModalBody, Media
} from "reactstrap"
import classnames from "classnames"
import {
  getUser as onGetUser,
  getUserAnalytics as onGetUserAnalytics,
  getParentData as onGetParentData

} from "store/actions"
//i18n
import emptyPhone from "../../assets/images/iphone.png"
import { withTranslation } from "react-i18next"
import { ToastContainer, toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Dropzone from "react-dropzone"
import imgEmpty from "./../../assets/images/default-user-pic.png";
import gotodo from "./../../assets/images/logooo.png";
import laptop from "./../../assets/images/phone.png";
import CashCard from "./Cards/card"
import config from "config"
import QrCodeModal from "./Modals/QrCodeModal.js"
import UserTable from "./Modals/userTable"
import 'react-toastify/dist/ReactToastify.css';
import "./datatables.scss"
import { authorize, Role } from "./../../components/helpers/authorize"
import { isEmpty, sample } from "lodash"

const Dashboard = props => {
  const dispatch = useDispatch()

  const [modalQr, setModalQr] = useState(false);

  let user = JSON.parse(localStorage.getItem("authUser"))

  const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo, userAnalytics, ParentData } = useSelector(state => ({
    error: state.Dashboard?.error,
    ProfileSuccess: state.Dashboard?.profileUpdateStatus,
    User: state.Dashboard?.user,
    Links: state.Dashboard?.user?.Links,
    SocialLinks: state.Dashboard?.user?.SocialLinks,
    profileInfo: state.Dashboard?.user?.profileInfo,
    userAnalytics: state.UserAnalytics,
    ParentData: state.Dashboard?.ParentData 
  }))

  useEffect(() => { 
    dispatch(onGetUser(user.uid))
    if (User?.corporateId) {
      dispatch(onGetParentData(User?.corporateId))
    }
    dispatch(onGetUserAnalytics(user.uid))

  }, [dispatch, User.bio, User.fullName, User.backGroundColor]);

  const toggleQr = () => {
    setModalQr(!modalQr);

  }
  const onClickInstall = async () => {
    const didInstall = await promptInstall()
    if (didInstall) {
      // User accepted PWA install
    }
  }
 

  return (
    <React.Fragment>

      <Row >
        <ToastContainer style={{ position: "absolute", top: "39px", left: "1229px" }}
          autoClose={3000}
        />

      </Row>

    
      <Container style={{ marginTop: 75 }} >
      <div>
 

   
  </div>
        <br></br> <br></br>
        {authorize(Role.Guest) ?
          <Row >
            <Col id="preview-phone" xl={4} lg={12} md={12} sm={12} className="d-flex justify-content-center">
              <div style={ParentData?.isPhoto ?
                { border: "7px solid", overflow: "hidden", borderRadius: "40px", height: "697px", width: "352px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${config.baseImg + ParentData?.backgroundImage})` } :
                { border: "7px solid", overflow: "hidden", borderRadius: "40px", height: "697px", width: "352px", background: ParentData.backGroundColor }}>

                {ParentData?.style == "1" ? (
                  <div>
                    <div>
                      {ParentData?.photo ? <img className="mt-3 m-auto" src={config.baseImg + ParentData?.photo} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                        : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />}
                    </div>

                    <h4 className="text-center mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                    <h4 className="text-center" style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor, fontWeight: "500" }}>{User?.bio}</h4>

                    <Row style={{ position: "relative", top: "-13px" }}>


                      {profileInfo ? (
                        profileInfo.map((Links, key) => (
                          <Col md="12" key={"_col_" + key} className="text-center">
                            <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>

                              {Links.info ? (
                                <i className=
                                  {Links.icon}

                                  style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                              <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor }}>{Links.info}</b>
                            </a>

                          </Col>
                        ))

                      ) : null}

                    </Row>
                  </div>
                ) : ParentData?.style == "2" ?
                  <Row>
                    <Col md="4" sm="5" xs="5" >

                      {ParentData?.photo ? <img className="mt-3" src={config.baseImg + ParentData?.photo} style={{ width: "80px", height: "80px", display: "block", borderRadius: "50%", position: "relative", top: "22px", left: "32px", objectFit: "cover" }} />
                        : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", position: "relative", top: "19px", left: "16px", objectFit: "cover" }} />}
                    </Col>
                    <Col md="8" sm="7" xs="7" style={{ position: "relative", top: "19px" }}>
                      <div>
                        <h4 className=" mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                        <h4 style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor }}>{User?.bio}</h4>

                        <Row style={{ position: "relative", top: "-12px" }}>


                          {profileInfo ? (
                            profileInfo.map((Links, key) => (
                              <Col md="12" key={"_col_" + key}>

                                <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                                  {Links.info ? (
                                    <i className={Links.icon} style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                                  <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor, position: "relative", left: "2px" }}>{Links.info}</b>
                                </a>
                              </Col>
                            ))

                          ) : null}

                        </Row>
                      </div>
                    </Col>


                  </Row> : (
                    <div>
                      <div>
                        {ParentData?.photo ? <img className="mt-3 m-auto" src={config.baseImg + ParentData?.photo} style={{ objectFit: "contain", width: "100%", height: "96px", display: "block", borderRadius: "13px", paddingRight: "5px", paddingLeft: "5px" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ height: "96px", display: "block", borderRadius: "0%" }} />}
                      </div>

                      <h4 className="text-center mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                      <h4 className="text-center" style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor, fontWeight: "500" }}>{User?.bio}</h4>

                      <Row style={{ position: "relative", top: "-13px" }}>


                        {profileInfo ? (
                          profileInfo.map((Links, key) => (
                            <Col md="12" key={"_col_" + key} className="text-center">
                              <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>

                                {Links.info ? (
                                  <i className=
                                    {Links.icon}

                                    style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                                <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor }}>{Links.info}</b>
                              </a>

                            </Col>
                          ))

                        ) : null}

                      </Row>
                    </div>
                  )}
                <div style={User?.style == "1" ? { position: "relative", top: "-17px" } : User?.style == "2" ? { position: "relative", top: "3px", paddingLeft: "20px", paddingRight: "20px" } : { position: "relative", top: "-17px" }}>

                  <Row className={User?.style == "1" || User?.style == "3" ? null : "mb-4 mt-3"} style={{ display: "flex", justifyContent: "center" }}>

                    {ParentData?.SocialLinks ? (
                      ParentData?.SocialLinks.filter(x => x.link)?.map((Links, key) => (
                        <Col md="2" key={"_col_" + key} className="text-center">
                          <span onClick={() => window.open(Links.url)} style={{ cursor: "pointer" }}>
                            {Links.link ? (
                              <i className=

                                {Links.icon}
                                style={{ fontSize: "30px", position: "relative", top: "9px", color: ParentData?.fontColor }} ></i>) : null}
                          </span>

                        </Col>
                      ))

                    ) : null}

                  </Row>

                </div>



                {ParentData?.Links?.map((link, key) => (
                  <Col md="12" className="mt-2" key={"_col_" + key} >
                    {key < 10 ? (
                      <div onClick={() => {
                        if (link.link) {
                          window.open(link?.link)
                        }
                      }


                      }>

                        <Card className={link.link ? "btn-preview-card mini-stats-wid" : "mini-stats-wid"} style={
                          isEmpty(link.link) ? {
                            wordBreak: "break-all", width: "288px", position: "relative", left: "31px", height: "20px", border: "0px solid", borderColor: 'transparent', fontSize: '14px', backgroundColor: "transparent"
                          } :
                            { boxShadow: ParentData?.cardShadow, backgroundColor: ParentData.cardColor, borderRadius: ParentData?.cardRadius, wordBreak: "break-all", width: "288px", position: "relative", left: "31px", height: "50px", cursor: link.link ? "pointer" : null, border: ParentData?.border, borderColor: ParentData?.borderColor }}>
                          <CardBody>
                            <Row md="12">
                              <Media>
                                <Col md="2">
                                  <div >
                                    {link?.photo ? <img src={config.baseImg + link?.photo} style={ParentData.border == "4px solid black" ? { width: "40px", height: "40px", objectFit: "cover", borderRadius: User?.cardRadius, position: "relative", top: "-19px", right: "19px" }
                                      : ParentData.border == "0px solid black" ?
                                        { width: "40px", height: "40px", objectFit: "cover", borderRadius: ParentData?.cardRadius, position: "relative", top: "-15px", right: "15px" } :
                                        { width: "40px", height: "40px", objectFit: "cover", borderRadius: ParentData?.cardRadius, position: "relative", top: "-17px", right: "17px" }} />
                                      : null}
                                  </div>
                                </Col>
                                {link?.link ? (
                                  <Col md="8">
                                    <Media body>
                                      <p style={{ fontFamily: ParentData?.font, position: "relative", top: "-5px", color: ParentData?.fontColor }} className="max-line-1">

                                        {link?.name}
                                      </p>
                                    </Media>
                                  </Col>
                                ) : <Col md="8">
                                  <Media body>
                                    <p style={{ fontFamily: ParentData?.font, position: "relative", top: "-12px", color: ParentData?.fontColor, fontWeight: "600" }} className="fw-medium text-center">

                                      {link?.name}
                                    </p>
                                  </Media>
                                </Col>}

                                <div >

                                </div>
                              </Media>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                    ) : null} 


                  </Col>

                ))}


              </div>


            </Col>
            <Col xl={8}>
              <CashCard />
            </Col>
          </Row> : (
            <Row >
              <Col id="preview-phone" xl={4} lg={12} md={12} sm={12} className="d-flex justify-content-center">
                <div style={User?.isPhoto ?
                  { border: "7px solid", overflow: "hidden", borderRadius: "40px", height: "697px", width: "352px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${config.baseImg + User?.backgroundImage})` } :
                  { border: "7px solid", overflow: "hidden", borderRadius: "40px", height: "697px", width: "352px", background: User.backGroundColor }}>

                  {User?.style == "1" ? (
                    <div>
                      <div>
                        {User?.photo ? <img className="mt-3 m-auto" src={config.baseImg + User?.photo} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />}
                      </div>

                      <h4 className="text-center mt-2 mb-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                      <h4 className="text-center" style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor, fontWeight: "500" }}>{User?.bio}</h4>

                      <Row style={{ position: "relative", top: "-13px" }}>


                        {profileInfo ? (
                          profileInfo.map((Links, key) => (
                            <Col md="12" key={"_col_" + key} className="text-center">
                              <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>

                                {Links.info ? (
                                  <i className=
                                    {Links.icon}

                                    style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                                <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor }}>{Links.info}</b>
                              </a>

                            </Col>
                          ))

                        ) : null}

                      </Row>
                    </div>
                  ) : User?.style == "2" ?
                    <Row>
                      <Col md="4" sm="5" xs="5" >

                        {User?.photo ? <img className="mt-3" src={config.baseImg + User?.photo} style={{ width: "80px", height: "80px", display: "block", borderRadius: "50%", position: "relative", top: "22px", left: "32px", objectFit: "cover" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", position: "relative", top: "19px", left: "16px", objectFit: "cover" }} />}
                      </Col>
                      <Col md="8" sm="7" xs="7" style={{ position: "relative", top: "19px" }}>
                        <div>
                          <h4 className=" mt-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                          <h4 style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor }}>{User?.bio}</h4>

                          <Row style={{ position: "relative", top: "-12px" }}>


                            {profileInfo ? (
                              profileInfo.map((Links, key) => (
                                <Col md="12" key={"_col_" + key}>

                                  <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                                    {Links.info ? (
                                      <i className={Links.icon} style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                                    <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor, position: "relative", left: "2px" }}>{Links.info}</b>
                                  </a>
                                </Col>
                              ))

                            ) : null}

                          </Row>
                        </div>
                      </Col>


                    </Row> : (
                      <div>
                        <div>
                          {User?.photo ? <img className="mt-3 m-auto" src={config.baseImg + User?.photo} style={{ objectFit: "contain", width: "100%", height: "96px", display: "block", borderRadius: "13px", paddingRight: "5px", paddingLeft: "5px" }} />
                            : <img className="mt-3 m-auto" src={imgEmpty} style={{ height: "96px", display: "block", borderRadius: "0%" }} />}
                        </div>

                        <h4 className="text-center mt-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                        <h4 className="text-center" style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor, fontWeight: "500" }}>{User?.bio}</h4>

                        <Row style={{ position: "relative", top: "-13px" }}>


                          {profileInfo ? (
                            profileInfo.map((Links, key) => (
                              <Col md="12" key={"_col_" + key} className="text-center">
                                <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>

                                  {Links.info ? (
                                    <i className=
                                      {Links.icon}

                                      style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                                  <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor }}>{Links.info}</b>
                                </a>

                              </Col>
                            ))

                          ) : null}

                        </Row>
                      </div>
                    )}
                  <div style={User?.style == "1" ? { position: "relative", top: "-17px" } : User?.style == "2" ? { position: "relative", top: "3px", paddingLeft: "20px", paddingRight: "20px" } : { position: "relative", top: "-17px" }}>

                    <Row className={User?.style == "1" || User?.style == "3" ? null : "mb-4 mt-3"} style={{ display: "flex", justifyContent: "center" }}>

                      {SocialLinks ? (
                        SocialLinks.filter(x => x.link)?.map((Links, key) => (
                          <Col md="2" key={"_col_" + key} className="text-center">
                            <span onClick={() => window.open(Links.url)} style={{ cursor: "pointer" }}>
                              {Links.link ? (
                                <i className=

                                  {Links.icon}
                                  style={{ fontSize: "30px", position: "relative", top: "9px", color: User?.fontColor }} ></i>) : null}
                            </span>

                          </Col>
                        ))

                      ) : null}

                    </Row>

                  </div>



                  {Links?.map((link, key) => (
                    <Col md="12" className="mt-2" key={"_col_" + key} >
                      {key < 10 ? (
                        <div onClick={() => {
                          if (link.link) {
                            window.open(link?.link)
                          }
                        }


                        }>

                          <Card className={link.link ? "btn-preview-card mini-stats-wid" : "mini-stats-wid"} style={
                            isEmpty(link.link) ? {
                              wordBreak: "break-all", width: "288px", position: "relative", left: "31px", height: "20px", border: "0px solid", borderColor: 'transparent', fontSize: '14px', backgroundColor: "transparent"
                            } :
                              { boxShadow: User?.cardShadow, backgroundColor: User.cardColor, borderRadius: User?.cardRadius, wordBreak: "break-all", width: "288px", position: "relative", left: "31px", height: "50px", cursor: link.link ? "pointer" : null, border: User?.border, borderColor: User?.borderColor }}>
                            <CardBody>
                              <Row md="12">
                                <Media>
                                  <Col md="2">
                                    <div >
                                      {link?.photo ? <img src={config.baseImg + link?.photo} style={User.border == "4px solid black" ? { width: "40px", height: "40px", objectFit: "cover", borderRadius: User?.cardRadius, position: "relative", top: "-19px", right: "19px" }
                                        : User.border == "0px solid black" ?
                                          { width: "40px", height: "40px", objectFit: "cover", borderRadius: User?.cardRadius, position: "relative", top: "-15px", right: "15px" } :
                                          { width: "40px", height: "40px", objectFit: "cover", borderRadius: User?.cardRadius, position: "relative", top: "-17px", right: "17px" }} />
                                        : null}
                                    </div>
                                  </Col>
                                  {link?.link ? (
                                    <Col md="8">
                                      <Media body>
                                        <p style={{ fontFamily: User?.font, position: "relative", top: "-5px", color: User?.fontColor }} className="max-line-1">

                                          {link?.name}
                                        </p>
                                      </Media>
                                    </Col>
                                  ) : <Col md="8">
                                    <Media body>
                                      <p style={{ fontFamily: User?.font, position: "relative", top: "-12px", color: User?.fontColor, fontWeight: "600" }} className="fw-medium text-center">

                                        {link?.name}
                                      </p>
                                    </Media>
                                  </Col>}

                                  <div >

                                  </div>
                                </Media>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      ) : null}


                    </Col>

                  ))}


                </div>


              </Col>
              <Col xl={8}>
                <CashCard />
              </Col>
              {User.corporate && User.corporate==true?(
                <Col className="col-12 mb-5 mt-4" >
              <UserTable/></Col>
              ):null}
              <Col className="col-12 mb-5 mt-4" >
             </Col>
            </Row>
          )}



        <QrCodeModal modal={modalQr} toggle={() => { toggleQr() }} /> 

      </Container>



    </React.Fragment>
  )
}
export default withTranslation()(Dashboard)
