import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Input,
  FormGroup, Label, TabContent,
  TabPane,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"

import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


import {
  getUser as onGetUser,
} from "store/actions"

import { isEmpty, map, update, valuesIn } from "lodash"
import moment from "moment"

import { AvForm, AvField } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import Dropzone from "react-dropzone"
import { authorize, Role } from "./../../components/helpers/authorize"
import "./datatables.scss"
import classnames from "classnames"
import imgEmpty from "./../../assets/images/empty.png"
import config from "config"

const Companies = props => {
  const dispatch = useDispatch()

  const [selectedId, setSelectedId] = useState()
  const [selectedCompany, setSelectedCompany] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_qr, setconfirm_alert_qr] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])

  const { error, User } = useSelector(state => ({
    error: state.Companies?.error,
    User: state.Dashboard?.user,
  }))

  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetUser(user.uid))
  }, [dispatch])


  const toggle = () => {
    setModal(!modal)
  }


  const columns = [
    {
      dataField: "photo",
      text: props.t("Photo"),
      sort: false,
      formatter: (cell, row) => (
        <div>
          {cell ? (
            <img
              src={config.baseImg + cell}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)

                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          ) : (
            <img
              src={imgEmpty}
              onClick={() => {
                setSelectedCompany(row)

                setIsEdit(true)
                toggle()
              }}
              style={{ width: "75px", height: "75px", objectFit: "contain" }}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Mail"
    },
    {
      dataField: "fullName",
      text: "İsim"
    },
    {
      dataField: "domain",
      text: "Domain"
    },



  ]
  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Companies ? Companies.length : 0, // replace later with size(Companies),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Companies ? Companies.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  const handleValidCompanySubmit = (e, values) => {
    const updateCompany = {
      _id: selectedCompany._id,
      name: values.name,
      description: values["description"],
      verDaire: values["verDaire"],
      verNo: values["verNo"],
      userName: values["userName"],
      email: values["email"],
      phone: values["phone"],
      address: values["address"],
      photo: values["photo"],
      sector: values["sector"]
    }

    // update Company
    dispatch(onUpdateCompany(updateCompany, selectedFiles))

    toggle()
  }


  console.log("User", User)

  //IMAGE CONTROL
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <MetaTags>
          <title>{props.t("Şirket Bilgilerim")}</title>
        </MetaTags>
        <Container fluid>
          <h4>Şirket Bilgilerim</h4>

          <Col className="col-3">
            <Card>
              <CardBody>
                <div>
                  <b className="photo-tag-pos">Foto:</b>
                  <span style={{ float: "right" }}>
                    {User?.photo ? (
                      <img
                        src={config.baseImg + User?.photo}

                        style={{ width: "75px", height: "75px", objectFit: "contain" }}
                      />
                    ) : (
                      <img
                        src={imgEmpty}

                        style={{ width: "75px", height: "75px", objectFit: "contain" }}
                      />
                    )}
                  </span>

                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-3">
            <Card>
              <CardBody>
                <div>
                  <b>Email:</b>

                  <span style={{float:"right"}}>
                    {User?.email}
                  </span>
                </div>

              </CardBody>
            </Card>
          </Col>
          <Col className="col-3">
            <Card>
              <CardBody>
              <div>
                  <b>İsim:</b>

                  <span style={{float:"right"}}>
                    {User?.fullName}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-3">
            <Card>
              <CardBody>
              <div>
                  <b>Domain:</b>

                  <span style={{float:"right"}}>
                    {User?.domain}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompany(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {confirm_alert_qr ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert_qr(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteCompanyQRPhoto(selectedId))
                setSelectedId(null)
                setSelectedCompany(null)
                qrTemplateToggle()
              }}
              onCancel={() => setconfirm_alert_qr(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetCompany())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Companies))
