import axios from "axios"
import { del, get, post, put,postX} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

  
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
/*
// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
*/
// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)

const postJwtLoginProfile = data => post(url.POST_LOGIN_PROFILE, data)

export const postJwtRegister = data => post(url.POST_REGISTER, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)


export const forgotSendMail = (email) => post(url.FORGOT_SEND_MAIL, email);

export const postEmailConfirm = (id, hash) => post(url.EMAIL_CONFIRM, id, hash);


/* ####################################################################### */



//USER
export const updateUserCompany = (user) => put(url.GET_USER + "/addCompany/" + user?._id, user);
export const getUser = () => get(url.GET_USER);
export const getUserById = (id) => get(url.GET_USER+ "/" + id);
export const getUserParent = (id) => get(url.GET_USER+ "/getGuest/" + id);
export const getUserByIdAnalytics = (id) => get(url.GET_USER_BYID+ "/" + id);
export const getUserByUsername = (id) => get(url.GET_USER+ "/getByUserName/" + id);
export const delUser = (id) => del(url.GET_USER + "/" + id); 
export const addUser = (user) => post(url.GET_USER, user);
export const addGuest = (guest,file) => postX(url.GET_USER+"/corporateAdd", guest,file);
export const updateGuest = (guest,file) => postX(url.GET_USER+"/corporateUpdate/"+guest?._id, guest,file);
export const getGuest = (user) => get(url.GET_USER+ "/getListCorporate/"+ user); 
export const delGuest = (id) => del(url.GET_USER + "/deleteGuest/" + id); 
export const combineProfileMail = (email) => post(url.GET_USER+"/combineProfileMail", email);
export const combineF = (email) => post(url.GET_USER+"/combineProfile/"+email?._id, email); 
export const addProfile = (profile) => post(url.GET_USER+ "/addProfile", profile);
export const getProfile = (mail) => get(url.GET_USER+ "/getProfiles/"+ mail); 

export const checkUserName = (user) => post(url.GET_USER+ "/userNameCheck" ,user);
export const updateUsers = (user) => put(url.GET_USER + "/" + user?._id, user);
export const addLink = (user, file) => postX(url.GET_USER + "/addLink/" + user?._id, user,file);
export const addSocialLink = (user) => put(url.GET_USER + "/addSocialLink/" + user?._id, user);
export const addStyle = (user) => put(url.GET_USER + "/style/" + user?._id, user);
export const addProfileInfo = (user) => put(url.GET_USER + "/addProfileInfo/" + user?._id, user);
export const getCompanyByEmail = (email)=> get(url.GET_USER + "/getCompanyByEmail/" + email); 
export const changePassword = (obj) => post(url.GET_USER + "/changePassword", obj); 
export const sendMail = (email) => post(url.SEND_MAIL, email);
export const updateProfile = (company, file) => postX(url.GET_USER + "/profileUpdate/" + company?._id, company,file);
export const updateProfilePhoto = (company, file) => postX(url.GET_USER + "/profilePhotoUpdate/" + company?._id, company,file);
export const backGroundPhotoUpdate = (company, file) => postX(url.GET_USER + "/backGroundPhotoUpdate/" + company?._id, company,file); 
export const updateTheme = (company) => put(url.GET_USER + "/themeUpdate/" + company?._id, company);
export const getRoles = () => get(url.GET_ROLES); 
export const getUserRoles = (id) => get(url.GET_USER + "/roles/" + id);  
export const addUserRoles = (userRoles) => post(url.GET_ROLES + "/user", userRoles); 
export const updatePlanActive = (planInput) => post(url.GET_USER + "/updateIsShow/" + planInput?._id, planInput); 

export const addIncrease = (user) => put(url.GET_USER + "/increaseCount/" + user?._id, user);
export const addProfileVisit = (user) => put(url.GET_USER_ANALYTICS + "/profileVisit/" + user?._id, user);
/* ####################################################################### */

/* VCARD*/
export const getVcardsDetail = (id) => get(url.GET_VCARD + "/getByUserId/" + id);
export const getVcards = () => get(url.GET_VCARD); 
export const delVcard = (id) => del(url.GET_VCARD + "/" + id); 
export const addVcard = (planInput) => post(url.GET_VCARD, planInput);
export const updateVcard = (planInput) => put(url.GET_VCARD + "/" + planInput?._id, planInput); 


/* ####################################################################### */









export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd, 
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  postJwtLoginProfile
}
