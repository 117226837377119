import React from "react"

import PropTypes from 'prop-types'
import {
    Card,
    CardBody,

  } from "reactstrap"
  import colorPalette from "./../../../assets/images/renkPaleti3.png";
 
const EmbeddedCard = props => {
  return ( 
    <React.Fragment>
       <Card className="me-3"  
       style={{ borderRadius: "12px", wordBreak: "break-all", backgroundSize: "contain",backgroundRepeat:"no-repeat" ,cursor:"pointer",border:"4px solid",borderColor:"white",boxShadow:"0px 3px 6px",width:"120px",height:"120px"}}>
                      <CardBody onClick={() => props.onClickFunc(true)}>
                        <div>
                        <center style={{position:"relative",bottom:"10px",fontWeight:"600"}}>{props.name}</center>
                      <img src={props.photo} style={{display:"block", marginLeft:"auto",marginRight:"auto" ,height:"60px",width:"60px"}} />
                        </div>
                    
                      </CardBody>
                     
                    </Card>
    </React.Fragment>
  ) 
} 


EmbeddedCard.propTypes = {
  props: PropTypes.func,
  name: PropTypes.string,
  photo: PropTypes.string,
  borderRadius:PropTypes.string,
  borderColor: PropTypes.string,
  price: PropTypes.string,
  fontFamily:PropTypes.string, 
  onClickFunc : PropTypes.func
}


export default EmbeddedCard;
