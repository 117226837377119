import React from "react"

import PropTypes from 'prop-types'
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input
  } from "reactstrap"
  import config from "config"

const ThemeCard = props => {
  return ( 
    <React.Fragment>
      {props.isPhoto?(
        <Card className="mini-stats-wid" style={{ borderRadius: "12px", wordBreak: "break-all", height: "290px",cursor:"pointer",backgroundImage: `url(${config.baseImg + props.backgroundImg})`}}> 
        <CardBody onClick={() => props.onClickFunc(true)}>
          <Row  >
            <Media>
              <Col md="12" sm="12" xs="12">
                <Media body>
                  <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",  height: "31px",position:"relative",top:"119px",borderColor:props.borderColor,border:props.border}}>
                   </Card>
                   <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",   height: "31px",position:"relative",top:"108px",borderColor:props.borderColor,border:props.border }}>
                   </Card>
                   <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",   height: "31px",position:"relative",top:"96px",borderColor:props.borderColor ,border:props.border}}>
                   </Card>
                  
                </Media>
              </Col>


            </Media>
          </Row>
       
        </CardBody>
        <p className="text-center mt-5" style={{position:"relative",bottom:"230px",fontFamily:props.fontFamily,fontSize:"20px",color:props.fontColor}}>{props.Name}</p>
      </Card>
      ):
      <Card className="mini-stats-wid" style={{ background: props.mainColor, borderRadius: "12px", wordBreak: "break-all", height: "290px",cursor:"pointer"}}> 
                      <CardBody onClick={() => props.onClickFunc(true)}>
                        <Row  >
                          <Media>
                            <Col md="12" sm="12" xs="12">
                              <Media body>
                                <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",  height: "31px",position:"relative",top:"119px",borderColor:props.borderColor,border:props.border}}>
                                 </Card>
                                 <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",   height: "31px",position:"relative",top:"108px",borderColor:props.borderColor,border:props.border }}>
                                 </Card>
                                 <Card className="mini-stats-wid" style={{ backgroundColor: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all",   height: "31px",position:"relative",top:"96px",borderColor:props.borderColor ,border:props.border}}>
                                 </Card>
                                
                              </Media>
                            </Col>


                          </Media>
                        </Row>
                     
                      </CardBody>
                      <p className="text-center mt-5" style={{position:"relative",bottom:"230px",fontFamily:props.fontFamily,fontSize:"20px",color:props.fontColor}}>{props.Name}</p>
                    </Card>}
       
    </React.Fragment>
  )
} 



ThemeCard.propTypes = {
  props: PropTypes.func,
  mainColor: PropTypes.string,
  cardColor: PropTypes.string,
  borderRadius:PropTypes.string,
  Name: PropTypes.string,
  price: PropTypes.string,
  fontFamily:PropTypes.string,
  onClickFunc : PropTypes.func,
  borderColor:PropTypes.string,
  border:PropTypes.border,
  isPhoto:PropTypes.bool,
  backgroundImg:PropTypes.any,
  fontColor: PropTypes.string,

}


export default ThemeCard;


