import {
  EMAIL_CONFIRM,
  EMAIL_CONFIRM_SUCCESS,
  EMAIL_CONFIRM_FAIL,
} from "./actionTypes"

const initialState = {
  loading: true,
  data: null,
  error: null
}

const ConfirmReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        loading:false,
        data: action.payload.data,
      }
    case EMAIL_CONFIRM_FAIL:
      return {
        ...state,
        loading:false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default ConfirmReducer
