import {
    GET_VCARDS,
    GET_VCARDS_SUCCESS,
    GET_VCARDS_FAIL,
    GET_VCARD_DETAIL,
    GET_VCARD_DETAIL_SUCCESS,
    GET_VCARD_DETAIL_FAIL,
    ADD_VCARD,
    ADD_VCARD_SUCCESS,
    ADD_VCARD_FAIL, 
    UPDATE_VCARD,
    UPDATE_VCARD_SUCCESS,
    UPDATE_VCARD_FAIL,
    DELETE_VCARD,
    DELETE_VCARD_SUCCESS,
    DELETE_VCARD_FAIL,
    RESET_VCARD,
   
} from "./actionTypes";


export const resetVcard = () => ({
    type: RESET_VCARD, 
});
export const detailVcard = (id) => ({
    type:  GET_VCARD_DETAIL,
    payload: id
});
 
export const detailVcardSuccess = (data) => ({
    type: GET_VCARD_DETAIL_SUCCESS,
    payload: data
});

export const detailVcardFail = (error) => ({
    type: GET_VCARD_DETAIL_FAIL,
    payload: error,
});
export const getVcards = () => ({
    type: GET_VCARDS,
});

export const getVcardsSuccess = (vcard) => ({
    type: GET_VCARDS_SUCCESS,
    payload: vcard,
});


export const getVcardsFail = (error) => ({
    type: GET_VCARDS_FAIL,
    payload: error,
});

export const deleteVcard = (id) => ({
    type:  DELETE_VCARD,
    payload: id
});

export const deleteVcardSuccess = (data) => ({
    type: DELETE_VCARD_SUCCESS,
    payload: data
});

export const deleteVcardFail = (error) => ({
    type: DELETE_VCARD_FAIL,
    payload: error,
});


export const addVcard = (vcard) => ({
    type: ADD_VCARD,
    payload: vcard
});

export const addVcardSuccess = (data) => ({
    type: ADD_VCARD_SUCCESS,
    payload: data
});

export const addVcardFail = (error) => ({
    type: ADD_VCARD_FAIL,
    payload: error,
});


export const updateVcard = (vcard) => ({
    type: UPDATE_VCARD,
    payload: vcard
});

export const updateVcardSuccess = (data) => ({
    type: UPDATE_VCARD_SUCCESS,
    payload: data
});

export const updateVcardFail = (error) => ({
    type: UPDATE_VCARD_FAIL,
    payload: error,
});