

export const GET_USER_ANALYTICS = "GET_USER_ANALYTICS"; 
export const GET_USER_ANALYTICS_SUCCESS = "GET_USER_ANALYTICS_SUCCESS";
export const GET_USER_ANALYTICS_FAIL = "GET_USER_ANALYTICS_FAIL"; 

export const ADD_PROFILE_VISIT = "ADD_PROFILE_VISIT";
export const ADD_PROFILE_VISIT_SUCCESS = "ADD_PROFILE_VISIT_SUCCESS";
export const ADD_PROFILE_VISIT_FAIL = "ADD_PROFILE_VISIT_FAIL"; 

export const ADD_INCREASE = "ADD_INCREASE";
export const ADD_INCREASE_SUCCESS = "ADD_INCREASE_SUCCESS";
export const ADD_INCREASE_FAIL = "ADD_INCREASE_FAIL"; 
