import React, { useState, useEffect } from "react"

import PropTypes from 'prop-types'
import { Container, Row, Col ,Card,CardBody,UncontrolledDropdown, DropdownMenu, DropdownToggle,  DropdownItem} from "reactstrap" 
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator"
import { useSelector, useDispatch } from "react-redux"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import {
    checkUserName as onCheckUserName,
    resetDashboard as onResetDashboard,
    addGuest as onAddGuest,
    getCorporateList as onGetCorporateList,
    deleteGuest as onDeleteGuest,
    updatePlanActive as onUpdatePlanActive
  
  } from "store/actions"
  import config from "config"
  import imgEmpty from "./../../../assets/images/empty.png"
  import AddUserModal from "./AddUserModal"
  import SweetAlert from "react-bootstrap-sweetalert"
  import { ToastContainer, toast } from 'react-toastify';
  import QrCodeUserModal from "./../Modals/QrCodeUserTable"
  import QRCode from 'qrcode.react';
const Table = props => {
  const [selectedUser, setSelectedUser] = useState();
  const [selectedId, setSelectedId] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [modalQr, setModalQr] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clickPassive, setClickPassive] = useState(false);
 
    let user = JSON.parse(localStorage.getItem("authUser"))
    const dispatch = useDispatch() 
    const { User, message,error,GuestList,Guest } = useSelector(state => ({
        error: state.Dashboard?.error,
        message: state.Dashboard?.message,
        User: state.Dashboard?.user,
        GuestList:state?.Dashboard?.CorporateUsers,
        Guest:state.Dashboard?.Guest
       
         
      }))
    useEffect(() => { 
        dispatch(onGetCorporateList(user?.uid));  
        }, [dispatch]);
        useEffect(() => {
    
          dispatch(onGetCorporateList(user?.uid));  
         
        }, [clickPassive])
        
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: props.data ? props.data.length : 0, // replace later with size(customers),
    custom: true,
  }
  const toggle = () => {
    setModal(!modal)
}
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: props.data ? props.data.length : 0 }];
    const copyToClipboard = (txt) => {
      var textField = document.createElement('textarea')
      textField.innerText = txt
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      toast.success(props.t("Copy Success") + ": " + txt)
    }
   
    const handleShareButton = async (txt) => {
      // Check if navigator.share is supported by the browser
      try {
        const shareLink = User?.domain?User.domain+"/qr/"+ shareUrl:"https://card.gotodo.link/qr/" + shareUrl
  
        console.log("Congrats! Your browser supports Web Share API", shareLink)
        await navigator
          .share({
            title: "Dijital Kartvizit",
            text: "Dijital Kartvizit",
            url: txt,
          })
          .then(() => {
            console.log("Sharing successfull")
          })
          .catch(() => {
            console.log("Sharing failed")
          })
      } catch (err) {
        console.log("Sorry! Your browser does not support Web Share API", err)
      }
    }
    const toggleQr = () => {
      setModalQr(!modalQr);
    }
                   
   
    const columns = [
      { 
        dataField: "photo",
        text: props.t("Photo"),
        sort: false,
        formatter: (cell, row) => (
            <div>
                {cell ? (
                    <img
                        src={config.baseImg + cell}
                      
                        style={{ width: "75px", height: "75px", objectFit: "contain" }}
                    />
                ) : (
                    <img
                        src={imgEmpty}
                       
                        style={{ width: "75px", height: "75px", objectFit: "contain" }}
                    />
                )}
            </div> 
        ),
    },
    
      {
        dataField: 'fullName',
        text:  props.t("Name"),
        sort: true
        
    }, 
    { 
      dataField: "qr",
      text: props.t("QR Photo"),
      sort: false,
      formatter: (cell, row) => (
        <div style={{ textAlign: "center" }} onClick={() => {
          setSelectedUserName(row.name)
          toggleQr() 
        }}>
        {/* <ToastContainer
          autoClose={3000}
        /> */}

        <div><QRCode
          id="myQrCode"
          level={"M"}
          size={128}
          fgColor={"#2A3042"}
          value={User?.domain?User.domain+"/p/" +row.name:"http://card.gotodo.Link/p/" +row.name} /></div>
      </div>
      ),
  },
        {
            dataField: 'name',
            text:  props.t("Username"),
            sort: true
            
        },  
        {
          dataField: 'dummy',
          dummy: true,
          text: props.t("Email"),
          formatter: (cell, row) => (
              row.profileInfo.map((name, key) => (
                  <div key={name?.info+"key"}>
                     { name?.name == "Email" ? (
                      <span>{name?.info}</span>
                  ) : null}
                  </div>
                 )))
      },
       
        {
          dataField: 'dummy45',
          dummy: true,
          text: props.t("Phone"),
          formatter: (cell, row) => (
              row.profileInfo.map((name, key) => (
                  <div key={name?.info+"key"}>
                     { name?.name == "phone" ? (
                      <span>{name?.info}</span>
                  ) : null}
                  </div>
                 )))
      },
      { 
          dataField: 'dummy2',
          dummy: true,
          text: props.t("Adres"),
          formatter: (cell, row) => (
              row.profileInfo.map((name, key) => (
                  <div key={name?.info+"key"}>
                     { name?.name == "address" ? (
                      <span>{name?.info}</span>
                  ) : null}
                  </div>
                 )))
      },
      {
        dataField: 'isShow',
        text: props.t("Listede Göster"),
        sort: false,
        formatter: (cell, row) => ( 
        
          <div>
            
          <Col
          
                className="form-check form-switch form-switch-lg mb-3"
              >
                  
                <label
                  className="form-check-label"
                  htmlFor="photo"
                  style={{ marginRight: '54px' }}
                >
                 
                </label>
                <input  style={{backgroundColor:cell?"#34c38f":null,borderColor:cell?"#34c38f":null,position:"relative",bottom:"15px",cursor:"pointer"}}
                  type="checkbox"
                  name="photo"
                  id="photo"
                  className="form-check-input"
                  checked={cell}
                 onChange={(e) => {
                      const updateBrand = {
                          _id: row._id,
                          };
          
                      dispatch(onUpdatePlanActive(updateBrand));
                      setInterval(function () { dispatch(onGetCorporateList(user?.uid))}, 500);
                     
                  }}
                 
                />

              </Col>
      </div>
        ),
  
    },
    
      {
          dataField: 'dummy4',
          dummy: true,
          text: props.t("Şirketteki pozisyon"),
          formatter: (cell, row) => (
              row.profileInfo.map((name, key) => (
                  <div key={name?.info+name.name+"key"}>
                     { name?.name == "jobInCompany" ? (
                      <span>{name?.info}</span>
                  ) : null}
                  </div>
                 )))
      },

          {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                    <DropdownMenu className="dropdown-menu-end mb-3">
                        <DropdownItem href="#"
                            onClick={() => { 
                                setSelectedUser(row); 
                                 setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true); 
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                        <DropdownItem href="#"
                          onClick={async () => {
                            
                             await handleShareButton(User?.domain?User.domain+"/qr/"+ row.name:"https://card.gotodo.link/qr/" + row.name) 
                             }}>
                            <i style={{ color: "#AF05E1", fontSize: "30px" }}  className="bx bx-share-alt font-size-16 me-1"></i>
                            {props.t("Share")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                            
                              copyToClipboard(User?.domain?User.domain+"/qr/"+ row.name:"https://card.gotodo.link/qr/" + row.name)
                            }}>
                            <i style={{ color: "#AF05E1", fontSize: "30px" }}  className="bx bx-copy font-size-16 me-1"></i>
                            {props.t("Copy")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                              setSelectedUserName(row.name)
                              toggleQr() 
                            }}>
                            <i style={{ color: "#AF05E1", fontSize: "30px" }}  className="bx bx-grid-alt font-size-16 me-1"></i>
                            {props.t("Qr Code")}
                        </DropdownItem>
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        } 
    ]; 
    
  return ( 
    <React.Fragment>
      <h4>{props.t("Users")}</h4>
        <Card>
                <CardBody>
                  <h5 className="card-title">{props.title}</h5>
                  <hr />
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    sizePerPage={sizePerPageList}
                    keyField="_id"
                    columns={columns}
                    data={GuestList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={GuestList}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"_id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    noDataIndication={props.emptyMessage}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
              < AddUserModal modal={modal} selectedUser={selectedUser} isEdit={isEdit} toggle={() => { toggle()} }
        save={(e) => {
          if (e == true) {
            toggle()
          }
        }} />
        <QrCodeUserModal urlName={selectedUserName} modal={modalQr} toggle={() => { toggleQr() }} /> 
          {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteGuest(selectedId));
                        setSelectedId(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}
    </React.Fragment>
  )
} 


Table.propTypes = {
  props: PropTypes.func,
  title: PropTypes.string,
  emptyMessage: PropTypes.string,
  columns: PropTypes.array,
  data : PropTypes.array,
}



export default withRouter(withTranslation()(Table))