import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
  Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
  DropdownItem
} from "reactstrap"
import classnames from "classnames"
import {
  getByUsername as onGetByUsername,
  addLink as onAddLink,
  addIncrease as onAddIncrease,
  addProfileVisit as onAddProfileVisit,
  detailVcard as onDetailVcard,
  getParentData as onGetParentData,
  getCorporateList as onGetCorporateList
} from "store/actions"

import "./profile-custom.scss"
//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"

import imgEmpty from "./../../assets/images/default-user-pic.png";
import gotodo from "./../../assets/images/profileLogov2.png";
import { isEmpty, sample } from "lodash"
import vCardsJS from "vcards-js";

import config from "config"
import { parseMarker } from "@fullcalendar/core";
const Dashboard = props => {
  const dispatch = useDispatch()
  const [editLink, setEditLink] = useState([])
  const [modal, setModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("authUser"))
  const {
    match: { params },
  } = props

  const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo, VCardInfo, ParentData, CorporateList } = useSelector(state => ({
    error: state.Dashboard?.error,
    ProfileSuccess: state.Dashboard?.profileUpdateStatus,
    User: state.Dashboard?.user[0],
    Links: state.Dashboard?.user[0]?.Links,
    SocialLinks: state.Dashboard?.user[0]?.SocialLinks,
    profileInfo: state.Dashboard?.user[0]?.profileInfo,
    VCardInfo: state.VCard?.detail[0]?.Infos,
    ParentData: state.Dashboard?.ParentData,
    CorporateList: state.Dashboard?.CorporateUsers
  }))
  useEffect(()=>{
     
    document.body.parentElement.style.height = "100%"
    document.body.style.height = "100%"
    document.getElementById("root").style.height = "100%" 
  },[])
  useEffect(() => {
    const count = {
      _id: User?.id,
    }
    dispatch(onAddProfileVisit(count))
    if (User?.corporateId) {
      dispatch(onGetParentData(User?.corporateId))
    }
    console.log("USer", User)

  }, [User]);
  useEffect(() => {
    if (User?.corporateId) {
      dispatch(onDetailVcard(User?.corporateId))
    }
    else if (User?.id) {
      dispatch(onDetailVcard(User?.id))
    }

  }, [User]);
  useEffect(() => {

    if (User?.corporate == true) {
      dispatch(onGetCorporateList(User?.id))
    }
  }, [User]);
  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    dispatch(onGetByUsername(params.id))

  }, [dispatch]);

  var vCard = vCardsJS();
  if (User?.corporateId) {
    if (ParentData?.photo) {
      axios.get(config.baseImg + ParentData?.photo, { responseType: 'arraybuffer' }).then(response => {
        const base64Image = Buffer.from(response.data, 'binary').toString('base64')

        VCardInfo?.map((Card, key) => (
          Card.name?.includes("birthday") && Card.permission == true ? (

            User?.profileInfo?.map((data, key) => (

              data.name?.includes("birthDay") ? (


                vCard.birthday = new Date(data?.info)
              ) :
                null

            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("phone") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("phone") ? (
                vCard.cellPhone = "+9" + data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("companyName") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("companyName") ? (
                vCard.organization = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("jobInCompany") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("jobInCompany") ? (
                vCard.title = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("email") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("Email") ? (
                vCard.email = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("name") && Card.permission == true ? (
            vCard.firstName = User?.fullName
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("photo") && Card.permission == true ? (
            //vCard.photo.embedFromString(config.baseImg + User?.photo, "image/jpeg")
            vCard.photo.embedFromString(base64Image, "JPEG")
          ) : null))

      })
    } else {
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("birthday") && Card.permission == true ? (

          User?.profileInfo?.map((data, key) => (

            data.name?.includes("birthDay") ? (


              vCard.birthday = new Date(data?.info)
            ) :
              null

          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("phone") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("phone") ? (
              vCard.cellPhone = "+9" + data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("companyName") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("companyName") ? (
              vCard.organization = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("jobInCompany") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("jobInCompany") ? (
              vCard.title = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("email") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("Email") ? (
              vCard.email = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("name") && Card.permission == true ? (
          vCard.firstName = User?.fullName
        ) : null))

    }
  }
  else {
    if (User?.photo) {
      axios.get(config.baseImg + User?.photo, { responseType: 'arraybuffer' }).then(response => {
        const base64Image = Buffer.from(response.data, 'binary').toString('base64')

        VCardInfo?.map((Card, key) => (
          Card.name?.includes("birthday") && Card.permission == true ? (

            User?.profileInfo?.map((data, key) => (

              data.name?.includes("birthDay") ? (


                vCard.birthday = new Date(data?.info)
              ) :
                null

            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("phone") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("phone") ? (
                vCard.cellPhone = "+9" + data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("companyName") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("companyName") ? (
                vCard.organization = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("jobInCompany") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("jobInCompany") ? (
                vCard.title = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("email") && Card.permission == true ? (
            User?.profileInfo?.map((data, key) => (
              data.name?.includes("Email") ? (
                vCard.email = data?.info
              ) : null
            ))
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("name") && Card.permission == true ? (
            vCard.firstName = User?.fullName
          ) : null))
        VCardInfo?.map((Card, key) => (
          Card.name?.includes("photo") && Card.permission == true ? (
            //vCard.photo.embedFromString(config.baseImg + User?.photo, "image/jpeg")
            vCard.photo.embedFromString(base64Image, "JPEG")
          ) : null))

      })
    } else {
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("birthday") && Card.permission == true ? (

          User?.profileInfo?.map((data, key) => (

            data.name?.includes("birthDay") ? (


              vCard.birthday = new Date(data?.info)
            ) :
              null

          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("phone") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("phone") ? (
              vCard.cellPhone = "+9" + data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("companyName") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("companyName") ? (
              vCard.organization = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("jobInCompany") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("jobInCompany") ? (
              vCard.title = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("email") && Card.permission == true ? (
          User?.profileInfo?.map((data, key) => (
            data.name?.includes("Email") ? (
              vCard.email = data?.info
            ) : null
          ))
        ) : null))
      VCardInfo?.map((Card, key) => (
        Card.name?.includes("name") && Card.permission == true ? (
          vCard.firstName = User?.fullName
        ) : null))

    }
  }





  // Links?.map((Links, key) => (
  //  Links.link?.includes("Vcard")?( 
  //   vCard.cellPhone ="+90"+Links.link.slice(6)

  //    ):null))
  //get as formatted string

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([vCard.getFormattedString()], {
      type: "text/vcard"
    });
    element.href = URL.createObjectURL(file);
    element.download = "vcard.vcf";
    document.body.appendChild(element);
    element.click();
  };
  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    try {
      const shareLink = User?.domain?User.domain+"/qr/"+ params.id:`https://card.gotodo.link/qr/${params.id}`

      console.log("Congrats! Your browser supports Web Share API", shareLink)
      await navigator
        .share({
          title: "Dijital Kartvizit",
          text: "Dijital Kartvizit",
          url: shareLink,
        })
        .then(() => {
          console.log("Sharing successfull")
        })
        .catch(() => {
          console.log("Sharing failed")
        })
    } catch (err) {
      console.log("Sorry! Your browser does not support Web Share API", err)
    }
  }

  return (
    <React.Fragment  >
      {User?.corporateId ? (
        <div style={ParentData?.isPhoto ?
          { backgroundSize: "cover", backgroundPosition: "center", backgroundColor: "white", backgroundImage: `url(${config.baseImg + ParentData?.backgroundImage})`, overflowY: "scroll", height: "100% ", backgroundRepeat: "no-repeat" } :
          { background: ParentData?.backGroundColor, overflowY: "scroll", height: "100%" }}>

          <Container fluid >
            <Row  >
              <Col md="2" sm="12" xs="12" lg="2" xl="4"></Col>

              <Col md="8" sm="12" xs="12" lg="8" xl="4" >
                <span onClick={async () => { await handleShareButton() }} style={{ cursor: "pointer", display: "flex", justifyContent: "flex-end" }} >
                  <i className="bx bx-share-alt"
                    style={{ fontSize: "30px", position: "fixed", top: "9px", color: ParentData?.fontColor }} ></i>
                </span>




                {ParentData?.style == "1" ? (
                  <div>
                    <div>
                      {User ? (
                        ParentData?.photo ? <img className="mt-3 m-auto" src={config.baseImg + ParentData?.photo} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                      ) : null}

                    </div>

                    <h4 className="text-center mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                    <h4 className="text-center" style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor }}>{User?.bio}</h4>

                    <Row style={{ position: "relative", top: "-9px" }}>
                      {profileInfo ? (
                        profileInfo.map((Links, key) => (
                          <Col md="12" key={"_col_" + key} className="text-center">
                            <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                              {Links.info ? (
                                <i className=
                                  {Links.icon}

                                  style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                              <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor }}>{Links.info}</b>
                            </a>

                          </Col>
                        ))

                      ) : null}

                    </Row>
                  </div>
                ) : ParentData?.style == "2" ?
                  <Row>
                    <Col md="2" sm="2" xs="3" lg="2" xl="2">
                      {ParentData ? (
                        ParentData?.photo ? <img className="mt-3" src={config.baseImg + ParentData?.photo} style={{ width: "96px", height: "96px", borderRadius: "50%", position: "relative", top: "19px", objectFit: "cover" }} />
                          : <img className="mt-3 m-end" src={imgEmpty} style={{ width: "96px", height: "96px", borderRadius: "50%", objectFit: "cover" }} />
                      ) : null}

                    </Col>
                    <Col md="10" sm="10" xs="9" lg="10" xl="9" style={{ position: "relative", top: "19px" }}>
                      <div>
                        <h4 className=" mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                        <h4 style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor }}>{User?.bio}</h4>

                        <Row style={{ position: "relative", top: "-9px" }}>


                          {profileInfo ? (
                            profileInfo.map((Links, key) => (
                              <Col md="12" key={"_col_" + key}>
                                <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                                  {Links.info ? (
                                    <i className=
                                      {Links.icon}

                                      style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                                  <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor, position: "relative", left: "2px" }}>{Links.info}</b>
                                </a>

                              </Col>
                            ))

                          ) : null}

                        </Row>
                      </div>
                    </Col>


                  </Row> :
                  <div>
                    <div>
                      {ParentData ? (
                        ParentData?.photo ? <img className="mt-3 m-auto" src={config.baseImg + ParentData?.photo} style={{ objectFit: "contain", width: "100%", borderRadius: "13px", paddingRight: "5px", paddingLeft: "5px", height: "96px", display: "block" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ height: "96px", display: "block", borderRadius: "0%" }} />
                      ) : null}

                    </div>

                    <h4 className="text-center mt-2" style={{ fontFamily: ParentData?.font, color: ParentData?.fontColor }}>{User?.fullName}</h4>
                    <h4 className="text-center" style={{ fontSize: "14px", fontFamily: ParentData?.font, position: "relative", top: "-7px", color: ParentData?.fontColor }}>{User?.bio}</h4>

                    <Row style={{ position: "relative", top: "-9px" }}>
                      {profileInfo ? (
                        profileInfo.map((Links, key) => (
                          <Col md="12" key={"_col_" + key} className="text-center">
                            <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                              {Links.info ? (
                                <i className=
                                  {Links.icon}

                                  style={{ fontSize: "20px", position: "relative", top: "5px", color: ParentData?.fontColor }} ></i>) : null}
                              <b style={{ fontFamily: ParentData?.font, fontWeight: "500", color: ParentData?.fontColor }}>{Links.info}</b>
                            </a>

                          </Col>
                        ))

                      ) : null}

                    </Row>
                  </div>}



                <div >
                  <Row className="text-center mt-3" style={{ display: "flex", justifyContent: "center" }}>

                    {ParentData?.SocialLinks ? (
                      ParentData?.SocialLinks.filter(x => x.link)?.map((Links, key) => (
                        <Col md="2" xs="2" key={"_col_" + key} className="text-center">
                          <span onClick={() => window.open(Links.url)} style={{ cursor: "pointer" }}>
                            {Links.link ? (
                              <i className=

                                {Links.icon}
                                style={{ fontSize: "30px", position: "relative", top: "9px", color: ParentData?.fontColor }} ></i>) : null}
                          </span>

                        </Col>
                      ))

                    ) : null}

                  </Row>
                </div>


                <br></br> <br></br>
                {ParentData?.Links?.map((Links, key) => (
                  <Col md="12" sm="12" xs="12" lg="12" xl="12" key={"_col_" + key}>
                    <div
                      onClick={() => {
                        const count = {
                          _id: User.id,
                          index: key
                        }
                        if (Links.link) {
                          dispatch(onAddIncrease(count))
                          if (Links.link.includes("tel") || Links.link.includes("mailto")) {
                            location.href = Links?.link;

                          } else if (Links.isType == "VCard") {
                            downloadTxtFile()
                          }
                          else {
                            window.open(Links?.link)
                          }

                          //window.location.reload();
                        }
                      }}>
                      <Card className={isEmpty(Links.link) ? "mb-5" : "btn-profile-card mini-stats-wid"} style={
                        isEmpty(Links.link) ? {
                          backgroundColor: "transparent",
                          wordBreak: "break-all",
                          height: "0px"
                        } :
                          {
                            boxShadow: ParentData?.cardShadow, backgroundColor: ParentData.cardColor,
                            borderRadius: ParentData?.cardRadius, wordBreak: "break-all", cursor: Links.link ? "pointer"
                              : null, border: ParentData?.border, height: Links.link ? "59px" :
                                "48px", borderColor: ParentData?.borderColor
                          }}>
                        <CardBody>
                          <Media>
                            <Col md="1" sm="1" xs="3" lg="1" xl="1">
                              <div >
                                {Links?.photo ? <img src={config.baseImg + Links?.photo} style={ParentData.border == "4px solid black" ? { width: "49px", height: "49px", objectFit: "cover", borderRadius: User?.cardRadius, position: "absolute", top: "1px", left: "1px" }
                                  : ParentData.border == "0px solid black" ?
                                    { width: "49px", height: "49px", objectFit: "cover", borderRadius: ParentData?.cardRadius, position: "absolute", top: "5px", left: "5px" } :
                                    { width: "49px", height: "49px", objectFit: "cover", borderRadius: ParentData?.cardRadius, position: "absolute", top: "3px", left: "3px" }} />
                                  : null}
                              </div>
                            </Col>
                            {Links.link ? (
                              <Col md="10" sm="9" xs="9" lg="10" xl="10" className="dropLink-absolute">
                                <Media body>
                                  <p className="max-line-1" style={{ fontFamily: ParentData?.font, fontSize: "17px", color: ParentData?.fontColor, position: "relative", top: "-3px" }}>

                                    {Links?.name}
                                  </p>
                                </Media>
                              </Col>
                            ) :
                              <Col md="11" sm="10" xs="12" lg="12" xl="11" className="dropLink-absolute-header">
                                <Media body>
                                  <p className="fw-medium text-center" style={{ fontFamily: ParentData?.font, fontSize: "17px", color: ParentData?.fontColor, position: "relative", top: "-11px" }}>

                                    {Links?.name}
                                  </p>
                                </Media>
                              </Col>}

                            <div >

                            </div>
                          </Media>

                        </CardBody>
                      </Card>
                    </div>

                  </Col>
                ))}
              </Col>
              <Row>
                <Col className="text-end">
                  <p style={{ color: ParentData?.fontColor, fontSize: "14px", cursor: "pointer", position: "relative", bottom: "0px", left: "16px" }}
                    onClick={() => window.open("https://card.gotodo.link")}>Sen de aramıza Katıl</p>
                </Col>
                <Col >
                  <Link target={"_blank"} to={{ pathname: User?.domain?User.domain:"https://card.gotodo.link/" }} >
                    <img src={gotodo} alt="" height="18" style={{ position: "relative" }} />
                  </Link></Col>



              </Row>


            </Row>

          </Container>
        </div>
      ) : (
        <div style={User?.isPhoto ?
          { backgroundSize: "cover", backgroundPosition: "center", backgroundColor: "white", backgroundImage: `url(${config.baseImg + User?.backgroundImage})`, overflowY: "scroll", height: "100% ", backgroundRepeat: "no-repeat" } :
          { background: User?.backGroundColor, overflowY: "scroll", height: "100%" }}>

          <Container fluid >
            <Row  >
              <Col md="2" sm="12" xs="12" lg="2" xl="4"></Col>

              <Col md="8" sm="12" xs="12" lg="8" xl="4" >
                <span onClick={async () => { await handleShareButton() }} style={{ cursor: "pointer", display: "flex", justifyContent: "flex-end" }} >
                  <i className="bx bx-share-alt"
                    style={{ fontSize: "30px", position: "fixed", top: "9px", color: User?.fontColor }} ></i>
                </span>
                {User?.corporate == true ? (
                  <span style={{ cursor: "pointer", display: "flex" }}
                    onClick={
                      async () => {
                        toggle()
                      }}>
                    <i className="bx bx-group"
                      style={{ fontSize: "30px", position: "fixed", top: "9px", color: User?.fontColor }} ></i>
                  </span>
                ) : null}



                {User?.style == "1" ? (
                  <div>
                    <div>
                      {User ? (
                        User?.photo ? <img className="mt-3 m-auto" src={config.baseImg + User?.photo} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ width: "96px", height: "96px", display: "block", borderRadius: "50%", objectFit: "cover" }} />
                      ) : null}

                    </div>

                    <h4 className="text-center mt-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                    <h4 className="text-center" style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor }}>{User?.bio}</h4>

                    <Row style={{ position: "relative", top: "-9px" }}>
                      {profileInfo ? (
                        profileInfo.map((Links, key) => (
                          <Col md="12" key={"_col_" + key} className="text-center">
                            <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                              {Links.info ? (
                                <i className=
                                  {Links.icon}

                                  style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                              <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor }}>{Links.info}</b>
                            </a>

                          </Col>
                        ))

                      ) : null}

                    </Row>
                  </div>
                ) : User?.style == "2" ?
                  <Row>
                    <Col md="2" sm="2" xs="3" lg="2" xl="2">
                      {User ? (
                        User?.photo ? <img className="mt-3" src={config.baseImg + User?.photo} style={{ width: "96px", height: "96px", borderRadius: "50%", position: "relative", top: "19px", objectFit: "cover" }} />
                          : <img className="mt-3 m-end" src={imgEmpty} style={{ width: "96px", height: "96px", borderRadius: "50%", objectFit: "cover" }} />
                      ) : null}

                    </Col>
                    <Col md="10" sm="10" xs="9" lg="10" xl="9" style={{ position: "relative", top: "19px" }}>
                      <div>
                        <h4 className=" mt-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                        <h4 style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor }}>{User?.bio}</h4>

                        <Row style={{ position: "relative", top: "-9px" }}>


                          {profileInfo ? (
                            profileInfo.map((Links, key) => (
                              <Col md="12" key={"_col_" + key}>
                                <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                                  {Links.info ? (
                                    <i className=
                                      {Links.icon}

                                      style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                                  <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor, position: "relative", left: "2px" }}>{Links.info}</b>
                                </a>

                              </Col>
                            ))

                          ) : null}

                        </Row>
                      </div>
                    </Col>


                  </Row> :
                  <div>
                    <div>
                      {User ? (
                        User?.photo ? <img className="mt-3 m-auto" src={config.baseImg + User?.photo} style={{ objectFit: "contain", width: "100%", borderRadius: "13px", paddingRight: "5px", paddingLeft: "5px", height: "96px", display: "block" }} />
                          : <img className="mt-3 m-auto" src={imgEmpty} style={{ height: "96px", display: "block", borderRadius: "0%" }} />
                      ) : null}

                    </div>

                    <h4 className="text-center mt-2" style={{ fontFamily: User?.font, color: User?.fontColor }}>{User?.fullName}</h4>
                    <h4 className="text-center" style={{ fontSize: "14px", fontFamily: User?.font, position: "relative", top: "-7px", color: User?.fontColor }}>{User?.bio}</h4>

                    <Row style={{ position: "relative", top: "-9px" }}>
                      {profileInfo ? (
                        profileInfo.map((Links, key) => (
                          <Col md="12" key={"_col_" + key} className="text-center">
                            <a href={Links?.name == "phone" ? `tel:+9${Links.info})` : Links.name == "Email" ? `mailto:${Links.info})` : Links?.name == "webSite" ? Links.info : "#"} target={Links?.name == "webSite" ? "_blank" : null} style={{ cursor: "pointer" }}>
                              {Links.info ? (
                                <i className=
                                  {Links.icon}

                                  style={{ fontSize: "20px", position: "relative", top: "5px", color: User?.fontColor }} ></i>) : null}
                              <b style={{ fontFamily: User?.font, fontWeight: "500", color: User?.fontColor }}>{Links.info}</b>
                            </a>

                          </Col>
                        ))

                      ) : null}

                    </Row>
                  </div>}



                <div >
                  <Row className="text-center mt-3" style={{ display: "flex", justifyContent: "center" }}>

                    {SocialLinks ? (
                      SocialLinks.filter(x => x.link)?.map((Links, key) => (
                        <Col md="2" xs="2" key={"_col_" + key} className="text-center">
                          <span onClick={() => window.open(Links.url)} style={{ cursor: "pointer" }}>
                            {Links.link ? (
                              <i className=

                                {Links.icon}
                                style={{ fontSize: "30px", position: "relative", top: "9px", color: User?.fontColor }} ></i>) : null}
                          </span>

                        </Col>
                      ))

                    ) : null}

                  </Row>
                </div>


                <br></br> <br></br>
                {Links?.map((Links, key) => (
                  <Col md="12" sm="12" xs="12" lg="12" xl="12" key={"_col_" + key}>
                    <div
                      onClick={() => {
                        const count = {
                          _id: User.id,
                          index: key
                        }
                        if (Links.link) {
                          dispatch(onAddIncrease(count))
                          if (Links.link.includes("tel") || Links.link.includes("mailto")) {
                            location.href = Links?.link;

                          } else if (Links.isType == "VCard") {
                            downloadTxtFile()
                          }
                          else {
                            window.open(Links?.link)
                          }

                          //window.location.reload();
                        }
                      }}>
                      <Card className={isEmpty(Links.link) ? "mb-5" : "btn-profile-card mini-stats-wid"} style={
                        isEmpty(Links.link) ? {
                          backgroundColor: "transparent",
                          wordBreak: "break-all",
                          height: "0px"
                        } :
                          {
                            boxShadow: User?.cardShadow, backgroundColor: User.cardColor,
                            borderRadius: User?.cardRadius, wordBreak: "break-all", cursor: Links.link ? "pointer"
                              : null, border: User?.border, height: Links.link ? "59px" :
                                "48px", borderColor: User?.borderColor
                          }}>
                        <CardBody>
                          <Media>
                            <Col md="1" sm="1" xs="3" lg="1" xl="1">
                              <div >
                                {Links?.photo ? <img src={config.baseImg + Links?.photo} style={User.border == "4px solid black" ? { width: "49px", height: "49px", objectFit: "cover", borderRadius: User?.cardRadius, position: "absolute", top: "1px", left: "1px" }
                                  : User.border == "0px solid black" ?
                                    { width: "49px", height: "49px", objectFit: "cover", borderRadius: User?.cardRadius, position: "absolute", top: "5px", left: "5px" } :
                                    { width: "49px", height: "49px", objectFit: "cover", borderRadius: User?.cardRadius, position: "absolute", top: "3px", left: "3px" }} />
                                  : null}
                              </div>
                            </Col>
                            {Links.link ? (
                              <Col md="10" sm="9" xs="9" lg="10" xl="10" className="dropLink-absolute">
                                <Media body>
                                  <p className="max-line-1" style={{ fontFamily: User?.font, fontSize: "17px", color: User?.fontColor, position: "relative", top: "-3px" }}>

                                    {Links?.name}
                                  </p>
                                </Media>
                              </Col>
                            ) :
                              <Col md="11" sm="10" xs="12" lg="12" xl="11" className="dropLink-absolute-header">
                                <Media body>
                                  <p className="fw-medium text-center" style={{ fontFamily: User?.font, fontSize: "17px", color: User?.fontColor, position: "relative", top: "-11px" }}>

                                    {Links?.name}
                                  </p>
                                </Media>
                              </Col>}

                            <div >

                            </div>
                          </Media>

                        </CardBody>
                      </Card>
                    </div>

                  </Col>
                ))}
              </Col>
              <Row>
                <Col className="text-end">
                  <p style={{ color: User?.fontColor, fontSize: "14px", cursor: "pointer", position: "relative", bottom: "0px", left: "16px" }}
                    onClick={() => window.open("https://card.gotodo.link/")}>Sen de aramıza Katıl</p>
                </Col>
                <Col >
                  <Link target={"_blank"} to={{ pathname: User?.domain?User.domain:"https://card.gotodo.link/" }} >
                    <img src={gotodo} alt="" height="18" style={{ position: "relative" }} />
                  </Link></Col>



              </Row>


            </Row>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                Kullanıcılar
              </ModalHeader>
              <ModalBody>
                <AvForm>
                  <Row>
                   {CorporateList.filter(x=>x.isShow==true)?.map((item, index) => (
                        <>
                        <Col className="col-3" style={{position:"relative",left:"5px"}}>
                        {item?.photo ? <img className="mt-3" src={config.baseImg + item?.photo} style={{ width: "75px", height: "75px", objectFit: "contain" }} />
                          : <img className="mt-3 m-end" src={imgEmpty} style={{ width: "75px", height: "75px", objectFit: "contain",position:"relative",bottom:"10px"}} />
                           }
                        </Col>
                        <Col className="col-6">
                        <p style={{position:"relative",top:"25px",cursor:"pointer",fontSize:"15px",fontWeight:"500"}} onClick={()=>{window.open(User?.domain?User.domain+"/qr/"+item.name:"https://card.gotodo.link/qr/"+item.name)}}>{item.name}</p>
                        <p style={{position:"relative",top:"10px"}} >{item?.profileInfo.find(x => x?.name == 'jobInCompany')?.info}</p>
                        </Col>
                        
                          
                          <hr />
                        </>

                      ))}



                   
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">

                        <Button onClick={() => { toggle() }}>
                          {props.t("Cancel")}
                        </Button>

                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      )}




    </React.Fragment>
  )
}
export default withTranslation()(Dashboard)
