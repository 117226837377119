import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from "./store"
import PWAPrompt from 'react-ios-pwa-prompt'

const app = (
  
  <Provider store={store}>
    <BrowserRouter>
      <App /> <PWAPrompt copyTitle={"Ana Ekrana Ekle"} copyBody={"Gotodo Sitesini tam ekranda uygulama işlevinde kullanmak için ana ekrana ekleyin"} 
      copyShareButtonLabel={"1) Paylaş Butonuna Tıklayın"} copyAddHomeButtonLabel={"2) Ana Ekrana Ekle Butonuna Tıklayın"}/>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

