import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ConfirmReducer from "./auth/confirm/reducer"
import userForgetPassword from "./auth/forgetpwd/reducer"
import changePassword from "./auth/changePassword/reducer"
import Profile from "./auth/profile/reducer"


//Dashboard 
import Dashboard from "./dashboard/reducer";
import UserAnalytics from "./userAnalytics/reducer"

//vcard
import VCard from "./vCard/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  userForgetPassword,
  changePassword,
  ConfirmReducer,
  Profile,
  Dashboard,
  UserAnalytics,
  VCard
 








})

export default rootReducer
