import React from "react"

import PropTypes from 'prop-types'
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input
  } from "reactstrap"

 
const ButtonCard = props => {
  return ( 
    <React.Fragment>
       <Card className="mini-stats-wid" style={{ background: props.cardColor, borderRadius: props.borderRadius, wordBreak: "break-all", height: "50px",width:"100%",cursor:"pointer" ,border:props.border }}>
                      <CardBody onClick={() => props.onClickFunc(true)}>
                      <p className="text-center" style={{fontFamily:props.fontFamily}}></p>
                      </CardBody>
                      
                    </Card>
    </React.Fragment>
  )
} 


ButtonCard.propTypes = {
  props: PropTypes.func,
  mainColor: PropTypes.string,
  cardColor: PropTypes.string,
  borderRadius:PropTypes.string,
  border:PropTypes.string,
  Name: PropTypes.string,
  price: PropTypes.string,
  fontFamily:PropTypes.string,
  onClickFunc : PropTypes.func
}


export default ButtonCard;
