import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Input,
  FormGroup,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from "qrcode.react"
import { ToastContainer, toast } from "react-toastify"

import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import "react-toastify/dist/ReactToastify.css"
import * as htmlToImage from "html-to-image"
import QRCodeStyling from "qr-code-styling"
import qrInImage from "./../../../assets/images/qr-in-logo-1.png"
import renkPaleti from "./../../../assets/images/renkPaleti3.png"
import { ChromePicker } from "react-color"
import "./../datatables.scss"
const QrCodeModal2 = (props, cb) => {
  const defaultQRSize = 256
  const defaultDownloadQRSize = 256
  const [togglePicker, setTogglePicker] = useState(false)
  const [dotColor, setDotColor] = useState("#6a1a4c")
  const [cornerColor, setCornerColor] = useState("#0041c2")
  const defaultQRPosition = {
    l: 256, //left
    tQR: 50 + 128 + 32,
    tImg: 70,
    t: 100, //top
    s: 256, //size
    p: 1024, //pagesize
    w: "100%",
    h: 128,
    pH: 512,
    pW: 768,
    x: 32,
    bgColor: "#ffffff",
    color: "#4267b2",
    cornerDotColor: "#696969",
  }
  const defaultDownloadQRPosition = {
    l: 256 * 2, //left
    tQR: (544 + 128 + 32) * 2,
    tImg: (1088 / 2) * 2,
    t: 100 * 2, //top
    s: 256 * 2, //size
    p: 1024 * 2, //pagesize
    w: "100%",
    h: 128 * 2,
    pH: 1088 * 2,
    pW: 768 * 2,
    x: 32 * 2,
    bgColor: "#ffffff",
    color: "#4267b2",
    cornerDotColor: "#696969",
  }
  const [qrPosition, setQRPosition] = useState(defaultQRPosition)
  const [qrDownloadSize, setQRDownloadSize] = useState(256)
  const ref = useRef(null)
  const ref2 = useRef(null)

  const qrCode = new QRCodeStyling({
    width: defaultQRPosition.s,
    height: defaultQRPosition.s,
    data: props.infoQr,
    margin: 0,
    image: qrInImage,
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 1,
      margin: 0,
      crossOrigin: "anonymous",
    },
    dotsOptions: {
      color: dotColor,
      type: "extra-rounded",
      gradient: {
        type: "radial", // 'radial'
        rotation: 0,
        colorStops: [
          { offset: 0, color: "#9e9e9e" },
          { offset: 1, color: "#525252" },
        ],
      },
    },
    cornersSquareOptions: {
      color: cornerColor,
      type: "extra-rounded", //extra-rounded
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
      // },
    },
    cornersDotOptions: {
      color: defaultQRPosition.cornerDotColor,
      //type: "square",
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
      // },
    },
  })
  const qrCode2 = new QRCodeStyling({
    width: defaultDownloadQRPosition.s,
    height: defaultDownloadQRPosition.s,
    data: props.infoQr,
    margin: 0,
    image: qrInImage,
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 1,
      margin: 0,
      crossOrigin: "anonymous",
    },
    dotsOptions: {
      color: dotColor,
      type: "extra-rounded",
      gradient: {
        type: "radial", // 'radial'
        rotation: 0,
        colorStops: [
          { offset: 0, color: "#9e9e9e" },
          { offset: 1, color: "#525252" },
        ],
      },
    },
    cornersSquareOptions: {
      color: cornerColor,
      type: "extra-rounded", //extra-rounded
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
      // },
    },
    cornersDotOptions: {
      color: defaultDownloadQRPosition.cornerDotColor,
      //type: "square",
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
      // },
    },
  })
  useEffect(() => {
    // Dynamically import qr-code-styling only client-side
    reloadQR()
  }, [props])

  const clearQR = () => {
    let qr_preview1 = document.getElementById('qr_1')
    if (qr_preview1) {
      qr_preview1.innerHTML = ""
    }
    let qr_preview2 = document.getElementById("qr_2")
    if (qr_preview2) {
      qr_preview2.innerHTML = ""
    }
  }
  const reloadQR = () => {
    clearQR()
    setTimeout(() => { 
      qrCode.append(ref.current)
      qrCode2.append(ref2.current)
    }, 100)
  }
  // useEffect(() => {
  //   if (qrDownloadSize == defaultDownloadQRSize) {
  //     downloadQR()
  //   }
  // }, [qrDownloadSize])

  let user = JSON.parse(localStorage.getItem("authUser"))

  function random(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const downloadQR = () => {
    const canvas = document.getElementById("myQrCode")
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
      if (pngUrl) {
        let downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = (props.title || random(8)) + ".png"
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        toast.success(props.t("Downloaded"))
        setQRDownloadSize(defaultQRSize)
      }
    }
  }

  const copyToClipboard = txt => {
    var textField = document.createElement("textarea")
    textField.innerText = txt
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
    toast.success(props.t("Copy Success") + ": " + txt)
  }

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a")
    elem.href = blob
    elem.download = fileName
    elem.style = "display:none;"
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === "function") {
      elem.click()
    } else {
      elem.target = "_blank"
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      )
    }
    URL.revokeObjectURL(elem.href)
    elem.remove()
  }
  const onCapture = id => {
    toast.success(props.t("Downloading"))
    document.getElementById(id).style.display = "block" 
    htmlToImage
      .toPng(document.getElementById(id))
      .then(function (dataUrl) {
        saveAs(dataUrl, (props.title || random(8)) + ".png")
      })
      .then(() => {
        document.getElementById(id).style.display = "none"
      })
  }
  console.log("qr codedan user",props.user?.corporateId
  )
  return (
    <Modal isOpen={props.modal} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Qr Code")}
      </ModalHeader>
      <ModalBody>
        <div style={{ textAlign: "center" }}>
          {/* <ToastContainer autoClose={3000} /> */}
          <div className="mb-3">
            <h5>
              {props.infoQr}{" "}
              <a
                className="me-1"
                style={{ fontSize: 24 }}
                onClick={() => {
                  copyToClipboard(props.infoQr)
                }}
              >
                <i className="bx bx-copy text-success"></i>
              </a>{" "}
            </h5>
          </div>
          <div className="color-palette-pos"> 
            <img
              src={renkPaleti}
              style={{
                borderRadius: "120px",
                wordBreak: "break-all",
                height: "60px",
                width: "60px",
                cursor: "pointer",
              }}
              onClick={() => {
                setTogglePicker(!togglePicker)
              }}
            />
          </div>
          {togglePicker && togglePicker ? (
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "90px",
                zIndex: "99",
              }}
            >
              <ChromePicker
                color={cornerColor}
                disableAlpha={true}
                onChangeComplete={data => {
                  setCornerColor(data.hex)
                }}
              />
            </div>
          ) : null}
          {props.user?.corporate ==true || props.user?.corporateId?(
             <div
             id="qr-preview-a1" 
             style={{
               position: "relative",
               height: `${qrPosition.pH}px`,
               width: "480px",
               backgroundColor: `${qrPosition.bgColor}`,
             }}
             onClick={() => {
               if (togglePicker == true) {
                 //qrCode.removeChild(ref.current)
                 qrCode.update({
                   cornersSquareOptions: {
                     color: cornerColor,
                   },
                 })
                 qrCode2.update({
                   cornersSquareOptions: {
                     color: cornerColor,
                   },
                 })
                 reloadQR()
                 setTogglePicker(false)
               }
             }}
           >
             <div className="qr-photo-pos-1"
               id={"qr_1"}
               ref={ref}
               style={
                 !isEmpty(props.QRphoto) 
                   ? {
                       position: "absolute",
                       zIndex: 9,
                       left: qrPosition.l,
                       top: qrPosition.tQR,
                       // boxShadow: "0px 0px 13px 10px #888888a3",
                       width: qrPosition.s,
                       height: qrPosition.s,
                     }
                   : {}
               }
             />
 
            
             {!isEmpty(props.QRphoto) ? (
               <img className="text-center qr-photo-pos"
                 src={`${props.QRphoto}`}
                 style={{
                   
                   width: qrPosition.w,
                   height: qrPosition.h,
                   objectFit: "contain",
                   marginTop: qrPosition.tImg,
                 }}
               />
             ) : null}
           </div>
          ):(
            <div
            id="qr-preview-a1" className="text-center"
            style={{
              position: "relative",
              height: `${qrPosition.pH}px`,
              width: "460px",
              backgroundColor: `${qrPosition.bgColor}`,
            }}
            onClick={() => {
              if (togglePicker == true) {
                //qrCode.removeChild(ref.current)
                qrCode.update({
                  cornersSquareOptions: {
                    color: cornerColor,
                  },
                })
                qrCode2.update({
                  cornersSquareOptions: {
                    color: cornerColor,
                  },
                })
                reloadQR()
                setTogglePicker(false)
              }
            }}
          >
            <div className="qr-photo-pos-1"
              id={"qr_1"}
              ref={ref}
              style={
                !isEmpty(props.QRphoto)
                  ? {
                      position: "absolute",
                      zIndex: 9,
                      left: qrPosition.l,
                      top: "140px",
                      // boxShadow: "0px 0px 13px 10px #888888a3",
                      width: qrPosition.s,
                      height: qrPosition.s,
                    }
                  : {}
              }
            />

           
          
          </div>
          )}
         

          <div
            id="qr-preview-a2"
            style={{
              position: "relative",
              height: `${defaultDownloadQRPosition.pH}px`,
              width: `${defaultDownloadQRPosition.pW}px`,
              display: "none",
              backgroundColor: `${defaultDownloadQRPosition.bgColor}`,
            }}
          >
            <div
              id={"qr_2"}
              ref={ref2}
              style={
                !isEmpty(props.QRphoto)
                  ? {
                      position: "absolute",
                      zIndex: 9,
                      left: defaultDownloadQRPosition.l,
                      top: defaultDownloadQRPosition.tQR,
                      // boxShadow: "0px 0px 13px 10px #888888a3",
                      width: defaultDownloadQRPosition.s,
                      height: defaultDownloadQRPosition.s,
                    }
                  : {}
              }
            />
            {/* <QRCode
              id="myQrCode2"
              level={"M"}
              size={!isEmpty(props.QRphoto) ? defaultDownloadQRPosition.s : 512}
              fgColor={"#2A3042"}
              value={props.infoQr}
              style={
                !isEmpty(props.QRphoto)
                  ? {
                      position: "absolute",
                      zIndex: 9,
                      left: defaultDownloadQRPosition.l,
                      top: defaultDownloadQRPosition.tQR,
                    }
                  : {}
              }
            /> */}
            {!isEmpty(props.QRphoto) ? (
              <img 
                src={`${props.QRphoto}`}
                style={{
                  width: defaultDownloadQRPosition.w,
                  height: defaultDownloadQRPosition.h,
                  objectFit: "contain",
                  marginTop: defaultDownloadQRPosition.tImg,
                }}
              />
            ) : null}
          </div>
        </div>
        {!isEmpty(props.QRphoto) ? (
          <a
            onClick={() => {
              //setQRPosition(defaultDownloadQRPosition)

              onCapture("qr-preview-a2")
            }}
            className="btn btn-success float-end ms-3"
          >
            {props.t("Download")}
          </a>
        ) : null}

        <a
          onClick={() => {
            //downloadQR()
            qrCode2.download({name:(props.title || random(8)), extension:'png'})
          }}
          className="btn btn-success float-end"
        >
          {props.t("DownloadOnlyQR")}
        </a>
      </ModalBody>
    </Modal>
  )
}

QrCodeModal2.propTypes = {
  modal: PropTypes.bool,
  t: PropTypes.any,
  toggle: PropTypes.func,
  infoQr: PropTypes.string,
  QRphoto: PropTypes.string,
  title: PropTypes.string,
  user:PropTypes.any
}

export default withRouter(withTranslation()(QrCodeModal2))
