import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
    Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
    FormGroup
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

    addLink as onAddLink,

} from "store/actions"

import { isEmpty, map, property } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const AddHeaderModal = (props, cb) => {

    const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo } = useSelector(state => ({
        error: state.Dashboard?.error,
        ProfileSuccess: state.Dashboard?.profileUpdateStatus,
        User: state.Dashboard?.user,
        Links: state.Dashboard?.user?.Links,
        SocialLinks: state.Dashboard?.user?.SocialLinks,
        profileInfo: state.Dashboard?.user?.profileInfo,
    }))
    const dispatch = useDispatch()
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);

    const handleValidHeaderSubmit = (e, values) => {
        if (props.isEdit) {
            Links[props.editLink[0]?.index] = { name: values.title, link: values.url }
            const Link = {
                _id: user.uid,
                links: JSON.stringify(Links)
            }
            dispatch(onAddLink(Link))
        } else {
            Links.push.apply(Links, [{ name: values.title, link: values.url }]);
            const Link = {
                _id: user.uid,
                links: JSON.stringify(Links)
            }
            dispatch(onAddLink(Link))
        }
        props.save(true)
    }

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} style={{ position: "relative", top: "123px" }}>
            <ModalHeader toggle={props.toggle} tag="h4">

                {!!props.isEdit ? props.t("Edit Header") : props.t("Add Header")}
            </ModalHeader>
            <ModalBody>
                <AvForm onValidSubmit={handleValidHeaderSubmit} autoComplete={"off"}>
                    <Row form>

                        <div className="mb-3">
                            <AvField
                                name="title"
                                label={props.t("Title")}
                                type="text"
                                errorMessage={props.t("InvalidInput")}

                                validate={{
                                    required: { value: true },
                                }}
                                value={props.editLink[0]?.name || ""}
                            />
                        </div>

                        <Row className="text-end">
                            <Col md="12">
                                <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                    type="submit"
                                    className="btn  save-user me-3"
                                >
                                    {props.t("Save")}
                                </button>
                                {!!props.isEdit ? (<button style={{ backgroundColor: "#rgb(255, 81, 81)", borderRadius: "7px" }}
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        props.confirmAlert(true)

                                    }}
                                >
                                    {props.t("Delete")}
                                </button>
                                ) : null}

                            </Col>
                        </Row>
                    </Row>
                </AvForm>
            </ModalBody>
        </Modal>

    )

}


AddHeaderModal.propTypes = {

    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    editLink: PropTypes.array,
    continue: PropTypes.func,
    payment: PropTypes.func,
    back: PropTypes.func,
    t: PropTypes.any,
    toggle: PropTypes.func,
    save: PropTypes.func,
    confirmAlert: PropTypes.func
}


export default withRouter(
    (withTranslation()(AddHeaderModal))
)
