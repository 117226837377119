import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent,
  TabPane, Modal, Alert, ModalHeader, ModalBody, InputGroup, Media, Input, Form, Label, FormGroup,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import AddPhotoModal from "./AddPhotoModal.js"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

  addProfileInfo as onAddProfileInfo,
  updateProfile as onUpdateProfile,
  addGuest as onAddGuest,
  updateGuest as onUpdateGuest

} from "store/actions"
import Dropzone from "react-dropzone"
import imgEmpty from "./../../../assets/images/default-user-pic.png";
import moment from "moment"
import config from "config"
import { isEmpty, map, property } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const AddInfoModal = (props, cb) => {
  const [addPhotoModal, setAddPhotoModal] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])

  const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo } = useSelector(state => ({
    error: state.Dashboard?.error,
    ProfileSuccess: state.Dashboard?.profileUpdateStatus,
    User: state.Dashboard?.user,
    Links: state.Dashboard?.user?.Links,
    SocialLinks: state.Dashboard?.user?.SocialLinks,
    profileInfo: state.Dashboard?.user?.profileInfo,
  }))
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [userNameEng,setUserNameEng] = useState()

  let MailInfo = props.selectedUser?.profileInfo?.filter(function (mail) {
    return mail.name == "Email";
  });
  let PhoneInfo = props.selectedUser?.profileInfo?.filter(function (phone) {
    return phone.name == "phone";
  });
  let addressInfo = props.selectedUser?.profileInfo?.filter(function (address) {
    return address.name == "address";
  });
  let faxInfo = props.selectedUser?.profileInfo?.filter(function (fax) {
    return fax.name == "fax";
  });
  let birthDayInfo = props.selectedUser?.profileInfo?.filter(function (birthDay) {
    return birthDay.name == "birthDay";
  });
  let companyNameInfo = props.selectedUser?.profileInfo?.filter(function (companyName) {
    return companyName.name == "companyName";
  });
  let jobInCompanyInfo = props.selectedUser?.profileInfo?.filter(function (jobInCompany) {
    return jobInCompany.name == "jobInCompany";
  });
  let webSiteInfo = props.selectedUser?.profileInfo?.filter(function (webSite) {
    return webSite.name == "webSite";
  });

  const addPhotoToggle = () => {
    setAddPhotoModal(!addPhotoModal)
  }

  const handleValidProfileInfoSubmit = (e, values) => {
    const RealInfos = []
    const ProfileInfos = [

      {
        name: "Email",
        info: document.getElementById("email").value,
        icon: "bx bxs-envelope me-1",
      },
      {
        name: "phone",
        info: document.getElementById("phone").value,
        icon: "bx bx-phone-call",

      },
      {
        name: "address",
        info: document.getElementById("address").value,
        icon: "bx bx-home-circle me-1",

      },
      {
        name: "fax",
        info: document.getElementById("fax").value,
        icon: "bx bx-printer me-1",
      },
      // {
      //   name: "birthDay",
      //   info: handleValidDateAvField(document.getElementById("birthDay").value),
      //   icon: "bx bx-calendar me-1",
      // },
      // {
      //   name: "companyName",
      //   info: document.getElementById("companyName").value,
      //   icon: "bx bx-buildings me-1",
      // },
      {
        name: "jobInCompany",
        info: document.getElementById("jobInCompany").value,
        icon: "bx bx-chevron-left-circle me-1",
      },
      // {
      //   name: "webSite",
      //   info: document.getElementById("webSite").value,
      //   icon: "bx bx-world me-1",
      // },

    ]
    ProfileInfos.filter(x => x.info)?.map((Links, key) => (
      RealInfos.push(Links)

    ))
    if (props.isEdit) {
      const updateUser = {
        _id: props.selectedUser._id,
        email: document.getElementById("email").value,
        userId: user?.uid,
        ProfileInfo: JSON.stringify(RealInfos),
        userName: user?.userName,
        fullName:userNameEng,
        //bio:values["bio"],
        photo: values["photo"],
      }
      dispatch(onUpdateGuest(updateUser, selectedFiles))
    } else {
      const newUser = {
        email: document.getElementById("email").value,
        userId: user?.uid,
        ProfileInfo: JSON.stringify(RealInfos),
        userName: user?.userName,
        fullName: userNameEng,
        //bio:values["bio"],
        photo: values["photo"],
      }
      dispatch(onAddGuest(newUser, selectedFiles))
    }
    props.save(true)

  }

  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD-MM-YYYY") || "";

      return date1;
    } else {

      return "";
    }
  }
  function handleAcceptedFiles(files) {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <Modal isOpen={props.modal} size="lg" toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Add User")}

      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidProfileInfoSubmit} autoComplete={"off"}>
          <Row>
            <Col md="12">
              <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", position: "relative" }}>
                <div>

                  {props.selectedUser?.photo ? <img src={config.baseImg + props.selectedUser?.photo} style={{ width: "182px", height: "108px", objectFit: "contain" }} />
                    : <img src={imgEmpty} style={{ width: "182px", height: "108px", objectFit: "contain" }} />}
                </div>
                <div style={{ width: "65%", border: "1px solid #ced4da", borderRadius: "10px", height: "150px" }} >
                  <Dropzone
                    multiple={false}
                    accept="image/jpeg, image/png"
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone-min" style={{ wordBreak: "break-all" }}>
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick" style={{ padding: "0px" }}>
                            <div>
                              <i className="display-5 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>{props.t("Drop Image")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className="dropzone-previews"
                    id="file-previews"
                    style={{ position: "absolute", zIndex: 99, left: 0, top: -5 }}
                  >
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <div className="align-items-center">
                              <div className="col-12">
                                <img
                                  data-dz-thumbnail=""
                                  style={{ width: "182px", height: "140px", objectFit: "contain" }}
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </div>

                            </div>
                          </div>
                        </Card>
                      )
                    })}
                  </div>

                </div>
              </div>
            </Col>

            <Col md="12" sm="7" xs="7" className="mt-3" >
              <div className="mb-3" >
                <AvField
                  name="fullName"
                  placeholder={props.t("Full Name")}
                  type="text"
                  onChange={(e) => {
                    let word = e.target.value
                    var g = ""
                    for (const harf of e.target.value) {
                      g += harf.split("ı").join("i").split("İ").join("I").toLowerCase().replace("ğ", "g").replace("ç", "c").replace("ü", "u").replace("ö", "o").replace("ş", "s").replace(" ","-")
                    }
                    setUserNameEng(g)
                    console.log(g)
                  }}
                  required
                  errorMessage={props.t("Bu alan boş bırakılamaz")}

                  value={props.selectedUser?.fullName}

                />
              </div>


            </Col>
            <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-chevron-left-circle"></i></div>
                <input type="text" className="form-control" id="jobInCompany" placeholder={props.t("Job In the Company")}
                  defaultValue={companyNameInfo ? (
                    jobInCompanyInfo[0]?.info
                  ) : ""} />
                <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px", cursor: "pointer", left: "3px" }} className="bx bx-trash " onClick={() => document.getElementById("jobInCompany").value = ""}></i>
              </InputGroup>

            </div>
            <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"><i className="bx bxs-envelope"></i></div>
                <input type="text" className="form-control" id="email" placeholder={props.t("Email")}

                  defaultValue={MailInfo ? (
                    MailInfo[0]?.info
                  ) : ""} />
                <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px", cursor: "pointer", left: "3px" }} className="bx bx-trash " onClick={() => document.getElementById("email").value = ""}></i>
              </InputGroup>
            </div>

            <div className="mb-3" >
              <InputGroup >
                <div className="input-group-text" ><i className="bx bx-phone-call"></i></div>
                <input type="number" className="form-control" id="phone" placeholder={props.t("Phone")}
                  defaultValue={PhoneInfo ? (
                    PhoneInfo[0]?.info
                  ) : ""} />
                <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px", cursor: "pointer", left: "3px" }} className="bx bx-trash " onClick={() => document.getElementById("phone").value = ""}></i>
              </InputGroup>
            </div>

            <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-printer"></i></div>
                <input type="number" className="form-control" id="fax" placeholder={props.t("Fax")}
                  defaultValue={faxInfo ? (
                    faxInfo[0]?.info
                  ) : ""} />
                <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px", cursor: "pointer", left: "3px" }} className="bx bx-trash " onClick={() => document.getElementById("fax").value = ""}></i>
              </InputGroup>

            </div>
            <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-home-circle"></i></div>
                <input type="text" className="form-control" id="address" placeholder={props.t("Address")}
                  defaultValue={addressInfo ? (
                    addressInfo[0]?.info
                  ) : ""} />
                <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px", cursor: "pointer", left: "3px" }} className="bx bx-trash " onClick={() => document.getElementById("address").value = ""}></i>
              </InputGroup>

            </div>
            {/* <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-calendar"></i></div>
                <input type="date" className="form-control" id="birthDay" placeholder={props.t("Date Of Birth")}
                  defaultValue={birthDayInfo ? (
                    birthDayInfo[0]?.info
                  ) : ""} />
                   <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px",cursor:"pointer",left:"3px" }} className="bx bx-trash " onClick={() =>  document.getElementById("birthDay").value=""}></i>
              </InputGroup>

            </div> */}
            {/* <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-buildings"></i></div>
                <input type="text" className="form-control" id="companyName" placeholder={props.t("CompanyName")}
                  defaultValue={companyNameInfo ? (
                    companyNameInfo[0]?.info
                  ) : ""} />
                   <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px",cursor:"pointer",left:"3px"}} className="bx bx-trash " onClick={() =>  document.getElementById("companyName").value=""}></i>
              </InputGroup>

            </div> */}

            {/* <div className="mb-3">
              <InputGroup>
                <div className="input-group-text"> <i className="bx bx-world"></i></div>
                <input type="text" className="form-control" id="webSite" placeholder={props.t("Website")}
                  defaultValue={companyNameInfo ? (
                    webSiteInfo[0]?.info
                  ) : ""} />
                   <i style={{ color: "red", fontSize: "25px", position: "relative", top: "5px",cursor:"pointer",left:"3px" }} className="bx bx-trash " onClick={() =>  document.getElementById("webSite").value=""}></i>
              </InputGroup>

            </div> */}


            <Col md="12">
              <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                type="submit"
                className="btn save-user text-end"
              >
                {props.t("Save")}
              </button>
            </Col>




          </Row>
        </AvForm>
      </ModalBody>
      < AddPhotoModal modal={addPhotoModal} size="lg" className="modal-width" toggle={() => { addPhotoToggle() }}
        save={(e) => {
          if (e == true) {
            addPhotoToggle()
          }
        }} />
    </Modal>


  )

}


AddInfoModal.propTypes = {

  modal: PropTypes.bool,
  isEdit: PropTypes.bool,
  editLink: PropTypes.array,
  continue: PropTypes.func,
  payment: PropTypes.func,
  back: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,
  save: PropTypes.func,
  confirmAlert: PropTypes.func,
  selectedUser: PropTypes.any
}


export default withRouter(
  (withTranslation()(AddInfoModal))
)
