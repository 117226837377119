import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    ADD_LINK_SUCCESS,
    ADD_LINK_FAIL,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    ADD_SOCIAL_LINK_SUCCESS,
    ADD_SOCIAL_LINK_FAIL,
    RESET_DASHBOARD,
    CHECK_USER_NAME_SUCCESS,
    CHECK_USER_NAME_FAIL,
    GET_BY_USERNAME_SUCCESS,
    GET_BY_USERNAME_FAIL, 
    UPDATE_THEME_SUCCESS,
    UPDATE_THEME_FAIL, 
    GET_LIST_SUCCESS, 
    GET_LIST_FAIL,
    UPDATE_PROFILE_PHOTO_SUCCESS,
    UPDATE_PROFILE_PHOTO_FAIL,
    UPDATE_BACKGROUND_PHOTO_SUCCESS,
    UPDATE_BACKGROUND_PHOTO_FAIL,
    UPDATE_THEME,
    ADD_PROFILE_INFO_SUCCESS, 
    ADD_PROFILE_INFO_FAIL,
    ADD_STYLE_SUCCESS, 
    ADD_STYLE_FAIL,
    ADD_PROFILE_SUCCESS, 
    ADD_PROFILE_FAIL,
    GET_PROFILES_SUCCESS, 
    GET_PROFILES_FAIL,
    ADD_GUEST_SUCCESS,
    ADD_GUEST_FAIL,
    GET_CORPORATE_LIST_SUCCESS, 
    GET_CORPORATE_LIST_FAIL,
    GET_PARENT_DATA_SUCCESS, 
    GET_PARENT_DATA_FAIL, 
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_FAIL,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_FAIL,
    COMBINE_PROFILE_SUCCESS, 
    COMBINE_PROFILE_FAIL,
    COMBINE_SUCCESS, 
    COMBINE_FAIL, 
    UPDATE_PLAN_ACTIVE_SUCCESS,
    UPDATE_PLAN_ACTIVE_FAIL,
} from "./actionTypes";



const INIT_STATE = { 
    chartsData: [], 
     
    error: null,
    data: [], 
    user:[],
    socialLink:[],
    profileUpdateStatus: false, 
    message:null,
    profile:[],
    newProfileSuccess: false,
    profiles:[],
    Users:[],
    Guest:[],
    CorporateUsers:[],
    ParentData:[],
    mailSend:false,
    profileCombined:false,
    isActive:[]
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_PLAN_ACTIVE_SUCCESS:
            return {
                ...state,
                isActive: state.data.map(plan =>
                    plan._id.toString() === action.payload.data._id.toString() ?
                        { plan, ...action.payload.data } :
                        plan
                )
            }
        case COMBINE_SUCCESS:
            
                return {
                    ...state,
                    profileCombined:true,
                    data: action.payload.data
                }
        case COMBINE_PROFILE_SUCCESS:
            
                return {
                    ...state,
                    mailSend:true,
                    data: action.payload.data
                }
        case GET_PARENT_DATA_SUCCESS:
            
                return {
                    ...state,
                    ParentData: action.payload.data
                }
        case ADD_GUEST_SUCCESS:
            return {
                ...state,
                Guest: action.payload.data
            }
            case DELETE_GUEST_SUCCESS:
                return {
                    ...state,
                    Guest: state.data.filter(brand => brand._id.toString() !== action.payload.data.toString())
                }
            case UPDATE_GUEST_SUCCESS:
               
                return {
                    
                    ...state,
                    Guest: action.payload.data
                }
            case GET_CORPORATE_LIST_SUCCESS:
                return {
                    ...state,
                    CorporateUsers: action.payload.data
                }
        case GET_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: action.payload.data
            }
        case ADD_PROFILE_SUCCESS:
            return {
                ...state,
                newProfileSuccess:true,
                profile:action.payload.data
            }
        case UPDATE_PROFILE_PHOTO_SUCCESS:
            return {
                ...state,
                profileUpdateStatus:true,
               
                user:action.payload.data
              }
         case UPDATE_BACKGROUND_PHOTO_SUCCESS:
           return {
               ...state,
               profileUpdateStatus:true,
              
               user:action.payload.data
             }
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                profileUpdateStatus:true,
               
                user:action.payload.data
              }
        case UPDATE_THEME_SUCCESS:
            return {
                ...state,
                user:action.payload.data
                  }
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state, 
                        chartsData: action.payload.data 
                    }; 
                default:
                    return state;
            }
        case ADD_LINK_SUCCESS:
            return {
                ...state,
                user:action.payload.data
            }
        case CHECK_USER_NAME_SUCCESS:
                return {
                    ...state,
                    message:"Kullanıcı İsmi Uygundur",
                    data:action.payload.data
                }
          case ADD_PROFILE_INFO_SUCCESS:
              return {
                  ...state,
                  user:action.payload.data
              }
        case ADD_STYLE_SUCCESS:
          return {
              ...state,
              user:action.payload.data
          }
        case ADD_SOCIAL_LINK_SUCCESS:
                return {
                    ...state,
                    user:action.payload.data
                }
       
        case GET_USER_SUCCESS:
            
                return {
                    ...state,
                    user: action.payload.data
                }
         case GET_LIST_SUCCESS:
    
             return {
                 ...state,
                 Users: action.payload.data
             }
        case GET_BY_USERNAME_SUCCESS:
            
                return {
                    ...state,
                    user: action.payload.data
                }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };


                default:
                    return state;
            }
           
                 //FAILS
        case UPDATE_PLAN_ACTIVE_FAIL: 
        case UPDATE_PROFILE_FAIL:
        case GET_USER_FAIL: 
        case ADD_LINK_FAIL:
        case ADD_SOCIAL_LINK_FAIL:
        case CHECK_USER_NAME_FAIL:
        case GET_BY_USERNAME_FAIL:
        case UPDATE_THEME_FAIL:
        case GET_LIST_FAIL:
        case UPDATE_PROFILE_PHOTO_FAIL:
        case ADD_PROFILE_INFO_FAIL:
        case ADD_STYLE_FAIL:
        case UPDATE_BACKGROUND_PHOTO_FAIL:
        case ADD_PROFILE_FAIL: 
        case GET_PROFILES_FAIL:
        case ADD_GUEST_FAIL:
        case GET_CORPORATE_LIST_FAIL: 
        case GET_PARENT_DATA_FAIL:
        case UPDATE_GUEST_FAIL:
        case DELETE_GUEST_FAIL:
        case COMBINE_PROFILE_FAIL:
        case COMBINE_FAIL:
        
                return { 
                    ...state,
                    error: action.payload
                }
    
         case RESET_DASHBOARD:
             return {
               ...state,
             message:null,
             error:null,
             Users:null,
             profileUpdateStatus: false,
             newProfileSuccess:false,
             mailSend:false,
             profileCombined:false
             }   
        default:
            return state;
    }
}


export default Dashboard;