import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
    Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
    FormGroup,Alert
} from "reactstrap"
import {
    AvForm, 
    AvField,
    AvGroup,
    AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

    addLink as onAddLink,
    combineProfile as onCombineProfile,
    resetDashboard as onResetDashboard

} from "store/actions" 

import { isEmpty, map, property } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const CombineProfileModal = (props, cb) => {

    const { error, User, Links, SocialLinks, ProfileSuccess, profileInfo,mailSend } = useSelector(state => ({
        error: state.Dashboard?.error,
        ProfileSuccess: state.Dashboard?.profileUpdateStatus,
        User: state.Dashboard?.user,
        Links: state.Dashboard?.user?.Links,
        SocialLinks: state.Dashboard?.user?.SocialLinks,
        profileInfo: state.Dashboard?.user?.profileInfo,
        mailSend:state.Dashboard?.mailSend 
    }))
    const dispatch = useDispatch()
    let user = JSON.parse(localStorage.getItem("authUser"))
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    useEffect(() => { 
        dispatch(onResetDashboard());  
        }, [dispatch]);
    const handleValidHeaderSubmit = (e, values) => {
      
           
            const Link = {
                
                email:values["email"],
                UserId:user.uid
            }
            dispatch(onCombineProfile(Link))
       
        props.save(true)
    }
 
    return (
        <div>
             {mailSend == true ? (
                            <Alert color="success">
                              Mail Gönderildi
                            </Alert>
                          ) : null}
                          {error ? (
                            <Alert color="danger">{error || data?.message}</Alert>
                          ) : null}
                          <Modal isOpen={props.modal} toggle={props.toggle} style={{ position: "relative", top: "123px" }}>
            <ModalHeader toggle={props.toggle} tag="h4">

                {props.t("Combine Profile")}
                
            </ModalHeader>
            
            <ModalBody>
           
            <small style={{position:"relative",bottom:"10px"}}>Birleştirmek istediğiniz hesap Mailini Girin ve Mail Adresinizi Kontrol Edin</small>
                <AvForm onValidSubmit={handleValidHeaderSubmit} autoComplete={"off"}>
                    <Row form>
                   
                        <div className="mb-3">
                            <AvField
                                name="email"
                                label={props.t("Email")}
                                type="text"
                                errorMessage={props.t("InvalidInput")}

                                validate={{
                                    required: { value: true },
                                }}
                                value={""}
                            />
                        </div>

                        <Row className="text-end">
                            <Col md="12">
                                <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                                    type="submit"
                                    className="btn  save-user me-3"
                                >
                                    {props.t("Send")}
                                </button>
                                {!!props.isEdit ? (<button style={{ backgroundColor: "#rgb(255, 81, 81)", borderRadius: "7px" }}
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        props.confirmAlert(true)

                                    }}
                                >
                                    {props.t("Delete")}
                                </button>
                                ) : null}

                            </Col>
                        </Row>
                    </Row>
                </AvForm>
            </ModalBody>
        </Modal>
        </div>
        

    )

}


CombineProfileModal.propTypes = {

    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    editLink: PropTypes.array,
    continue: PropTypes.func,
    payment: PropTypes.func,
    back: PropTypes.func,
    t: PropTypes.any,
    toggle: PropTypes.func,
    save: PropTypes.func,
    confirmAlert: PropTypes.func
}


export default withRouter(
    (withTranslation()(CombineProfileModal))
)
