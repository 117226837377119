import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {Card, CardBody, Col, Container, Media, Row, UncontrolledDropdown, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody, Button,
  DropdownItem,  InputGroup,Alert
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import {
  checkUserName as onCheckUserName,
  resetDashboard as onResetDashboard,
  addGuest as onAddGuest,
  getCorporateList as onGetCorporateList

} from "store/actions"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"




const UserProfile = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const dispatch = useDispatch() 
  const [selectedWorkType, setSelectedWorkType] = useState()
  const [selectedId, setSelectedId] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { SearchBar } = Search;
 
  const { User, message,error,GuestList,Guest } = useSelector(state => ({
    error: state.Dashboard?.error,
    message: state.Dashboard?.message,
    User: state.Account.user,
    GuestList:state?.Dashboard?.CorporateUsers,
    Guest:state.Dashboard?.Guest
   
    
  }))
  useEffect(() => { 
    dispatch(onGetCorporateList(user?.uid));  
    }, [dispatch,Guest]);

  const columns = [
    {
        dataField: 'name',
        text:  props.t("Name"),
        sort: true
        
    }, 
    {
        dataField: 'email',
        text:  props.t("Email"),
        sort: true
        
    }, 
    //   {
    //     dataField: '_id',
    //     text: props.t("Action"),
    //     sort: false,
    //     formatter: (cell, row) => (
    //         <UncontrolledDropdown style={{ position: "unset" }}>
    //             <DropdownToggle href="#" className="card-drop" tag="i">
    //                 <i className="mdi mdi-dots-horizontal font-size-18"></i>
    //             </DropdownToggle>
    //             <div className="drop-absolute">
    //             <DropdownMenu className="dropdown-menu-end">
    //                 <DropdownItem href="#"
    //                     onClick={() => { 
    //                         setSelectedPayment(row); 
    //                         console.log("22row",row)
                           
    //                         setIsEdit(true);
    //                         toggle();
    //                     }}
    //                 >
    //                     <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
    //                     {props.t("Edit")}
    //                 </DropdownItem>
    //                 <DropdownItem href="#"
    //                     onClick={() => {
    //                         setSelectedId(cell);
    //                         setconfirm_alert(true);
    //                     }}>
    //                     <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
    //                     {props.t("Delete")}
    //                 </DropdownItem>
    //             </DropdownMenu>
    //             </div>
    //         </UncontrolledDropdown>
    //     )
    // }
];
   
    const defaultSorted = [{ 
        dataField: 'createdAt',
        order: 'desc'
      }];
      
      const pageOptions = {
        sizePerPage: 10,
        totalSize:  GuestList?.size, // replace later with size(customers),
        custom: true,
      }
    const toggle = () => {
        setModal(!modal)
    }
    const checkToggle = () => {
        setCheckModal(!checkModal)
    }
 
  const handleValidProductSubmit = (e, values) => {
    if (isEdit) {
        const updateBrand = {
            _id: selectedPayment._id,
            name: values["name"],
         
            
        };
        
        dispatch(onUpdateBrand(updateBrand)); 
    } else {
        const newBrand = {
            
          name: document.getElementById("userName").value,
          email: values.email,
          userId:user?.uid
          }
       
        dispatch(onAddGuest(newBrand))
    } 
    dispatch(onResetDashboard())
    
    toggle()
    
}
   
  return (
    <React.Fragment>
    <div className="page-content" style={{marginTop:"15px"}}>
        <MetaTags>
            <title>{props.t("Users")}</title>
        </MetaTags>
        <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title={props.t("Users")} breadcrumbItem={props.t("Users")} addBtn={true} addBtnCallBack={() => {
                setIsEdit(false);
                setSelectedPayment(
                    {
                        companyId: "", 
                        name: "",
                        description: "" ,
                        amount: "",
                       // PaymentPlanObjects: "",
                       
                    }
                );
              
                     
                toggle();

            }} />

            {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

            <Card>
                <CardBody>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={GuestList}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField='_id'
                                columns={columns}
                                data={GuestList}
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>

                                        <Row className="mb-2">
                                            <Col md="4">
                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitProps.searchProps}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div> 
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        keyField={"_id"}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                                }
                            </ToolkitProvider>
                        )
                        }</PaginationProvider>

                    <Modal isOpen={modal}  toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Kullanıcı Düzenle") : props.t("Kullanıcı Ekle")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm  onValidSubmit={handleValidProductSubmit} >
                                <Row form>
                                    <Col xs={12}>
                                        <Row>
                                        <Col md="12"> 
                                        <div className="mb-3">
                              <AvField
                                name="email"

                                className="form-control"
                                placeholder={props.t("Email")}
                                type="email"
                                required
                              />
                            </div>

                            <InputGroup className="mb-3">
                              <div className="input-group-text">bio.gotodo.Link/</div>
                              <input
                                type="text"
                                className="form-control"
                                id="userName"
                                placeholder={props.t("UserName")}
                                onChange={e => {
                                  //const myTimeout = setTimeout(myGreeting, 5000);

                                  dispatch(onResetDashboard())

                                  const userData = {
                                    name: e.target.value
                                  }
                                  dispatch(onCheckUserName(userData))
                                }}
                              />
                              {error && document.getElementById("userName")?.value ? (

                                <Alert id="TooltipBottom" color="danger"

                                  style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99", cursor: "pointer" }}>

                                  <i className="bx bx-error-circle" style={{position:"relative", bottom:"3px"}}></i>

                                </Alert>
                              ) : message && document.getElementById("userName").value ? (
                                <Alert id="TooltipBottom" color="success" style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99" }}>
                                  <i className="bx bx-comment-check" style={{position:"relative",bottom:"3px"}}></i>
                                </Alert>
                              ) :   <Alert id="TooltipBottom" color="danger"

                              style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99", cursor: "pointer" }}>

                              <i className="bx bx-error-circle" style={{position:"relative",bottom:"3px"}}></i>

                            </Alert>}
                            </InputGroup>
                                        </Col>
                                       
                                       
                                        </Row>
                                       
                                       
                                        

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <div className="text-end">
                                    <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button>
                                    &nbsp;&nbsp;
                                    <Button onClick={()=>{ toggle() }}>
                                            {props.t("Cancel")}
                                            </Button>       
                                    
                                </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={checkModal} size="lg" toggle={checkToggle}>
                        <ModalHeader toggle={checkToggle} tag="h4">
                            {!!isEdit ? props.t("Ürün") : props.t("Ürün")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm   >
                                <Row form>
                                    <Col xs={12}>

                                             <Row>
                                                 <Col md="5">
                                                 <div className="mb-3">
                                            <AvField
                                                name="brand"
                                                label={props.t("Brand")}
                                                type="text"
                                                disabled
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedPayment?.brand || ""}
                                            />
                                        </div>
                                                 </Col>
                                                 <Col md="5">
                                                 <div className="mb-3">
                                            <AvField
                                                name="model"
                                                label={props.t("model")}
                                                type="text"
                                                disabled
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedPayment?.model || ""}
                                            />
                                        </div>
                                                 </Col>
                                                 </Row>                     
                                       
                                           
                                       
                                       
                                           
                                       
                                        

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <div className="text-end">
                                    {/* <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        {props.t("Save")}
                                    </button> */}
                                    &nbsp;&nbsp;
                                    <Button onClick={()=>{ toggle() }}>
                                            {props.t("Cancel")}
                                            </Button>       
                                    
                                </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                </CardBody>
            </Card>

            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are You Sure")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Delete")}
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title(props.t("Deleted"));
                        setdynamic_description("");
                        dispatch(onDeleteBrand(selectedId));
                        setSelectedId(null);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    {props.t("DeleteWarning")}
                </SweetAlert>
            ) : null}


            {/* {error && error ? (
                <SweetAlert
                    title={props.t("Error")}
                    warning
                    confirmBtnText={props.t("Ok")}
                    onConfirm={() => {
                        dispatch(onResetPayment())
                    }}
                >
                    {JSON.stringify(error)}
                </SweetAlert>
            ) : null} */}

        </Container>
    </div>
</React.Fragment>
  )
}


export default withRouter(withTranslation()(UserProfile))
