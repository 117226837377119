import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Col, Container, Form,Alert, FormGroup, Label, Row, Input } from "reactstrap"


import logodark from "../../assets/images/dijital-kartvizit.png"
import logolight from "../../assets/images/dijital-kartvizit.png"
import CarouselPage from "./CarouselPage"
 
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"  

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  const dispatch = useDispatch()

  const { error,Company,user,registrationError} = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    error: state.Login.error,
    Company : state.Users?.User?.companyId,
    
  }))
  
  // handleValidSubmit
  const handleValidSubmit = async(event, values) => {
  
    dispatch(loginUser(values, props.history))
 

  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
    <div>
   
       <Container fluid className="p-0">
         <Row className="g-0">
           <CarouselPage />

           <Col xl={3}>
             <div className="auth-full-page-content p-md-5 p-4">
               <div className="w-100">
                 <div className="d-flex flex-column h-100">
                   <div className="mb-4 mb-md-5">
                     
                   </div>
                   <div className="my-auto">
                     <div>
                       <h5 style={{color:"#AF05E1" }}>Merhabalar</h5>
                       <p className="text-muted">
                       Kullanmaya başlamak için oturum açın.
                       </p>
                     </div>

                     <div className="mt-4">

                       
                    <AvForm
                   className="form-horizontal"
                   onValidSubmit={(e, v) => {
                     handleValidSubmit(e, v)
                   }}
                 >
                    

                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                     <div className="mb-3">
                       <AvField
                         name="email"
                         label="Email veya Kullanıcı İsmi"
                         className="form-control"
                         placeholder="Enter email"
                         type="text"
                         required
                       />
                     </div> 
                     <div className="mb-3">
                     <div className="float-end">
                             <Link
                               to="forgot-password"
                               className="text-muted"
                             >
                              Şiremi Unuttum
                             </Link>
                           </div>
                       <AvField
                         name="password"
                         label="Şifre"
                         className="form-control"
                         placeholder="Enter password"
                         type="password"
                         required
                       />
                     </div>

                     <div className="form-check">
                           <Input
                             type="checkbox"
                             className="form-check-input"
                             id="auth-remember-check"
                           />
                           <label
                             className="form-check-label"
                             htmlFor="auth-remember-check"
                           >
                             Şifremi Hatırla
                           </label>
                         </div>

                         <div className="mt-3 d-grid">
                           <button style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }}
                             className="btn save-user btn-block "
                             type="submit"
                           >
                            Devam
                           </button>
                         </div>
                     
                     </AvForm>

                       
                       <div className="mt-5 text-center">
                         <p>
                         Hesabınız yok mu?{" "}
                           <Link
                             to="register"
                             className="fw-medium text-primary"
                           >
                             {" "}
                             Kayıt Ol{" "}
                           </Link>{" "}
                         </p>
                       </div>
                     </div>
                   </div>

                  
                 </div>
               </div>
             </div>
           </Col>
         </Row>
       </Container>
     </div>
 </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
