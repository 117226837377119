/** Get VCARD */
export const GET_VCARDS = "GET_VCARDS";
export const GET_VCARDS_SUCCESS = "GET_VCARDS_SUCCESS";
export const GET_VCARDS_FAIL = "GET_VCARDS_FAIL";

export const GET_VCARD_DETAIL = "GET_VCARD_DETAIL";
export const GET_VCARD_DETAIL_SUCCESS = "GET_VCARD_DETAIL_SUCCESS";
export const GET_VCARD_DETAIL_FAIL = "GET_VCARD_DETAIL_FAIL";


export const ADD_VCARD = "ADD_VCARD";
export const ADD_VCARD_SUCCESS = "ADD_VCARD_SUCCESS";
export const ADD_VCARD_FAIL = "ADD_VCARD_FAIL";

export const UPDATE_VCARD = "UPDATE_VCARD";
export const UPDATE_VCARD_SUCCESS = "UPDATE_VCARD_SUCCESS";
export const UPDATE_VCARD_FAIL = "UPDATE_VCARD_FAIL";

export const DELETE_VCARD = "DELETE_VCARD";
export const DELETE_VCARD_SUCCESS = "DELETE_VCARD_SUCCESS";
export const DELETE_VCARD_FAIL = "DELETE_VCARD_FAIL"; 



export const RESET_VCARD= "RESET_VCARD";