import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import QRCode from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import {

  getProductsByBarcode as onGetProductsByBarcode,

} from "store/actions"

import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const QrCodeModal = (props, cb) => {
  const {
    error,
    User,
    Product,  
    ProductId,
    Customer
  
  } = useSelector(state => ({
    error: state.Expenses?.error, 
    User: state.Dashboard?.user,
    Product: state.ProcessList?.Product,
    ProductId: state.ProcessList?.ProductId,
    
  }))
  const dispatch = useDispatch()
    let user = JSON.parse(localStorage.getItem("authUser"))
  
  function random(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
  const downloadQR = () => {
    const canvas = document.getElementById("myQrCode");
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      if (pngUrl) {
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = random(8) + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
       // toast.success(props.t("Downloaded"))
      }
    }
  };
  return (
    <Modal isOpen={props.modal} toggle={props.toggle} size="md">
    <ModalHeader toggle={props.toggle} tag="h4">
      {props.t("Qr Code")}
    </ModalHeader>
    <ModalBody>
      <div style={{ textAlign: "center" }}>
        <ToastContainer
          autoClose={3000}
        />

        <div><QRCode
          id="myQrCode"
          level={"M"}
          size={256}
          fgColor={"#2A3042"}
          value={User.domain?User.domain+"/p/"+ user.userName:"http://card.gotodo.Link/p/" + user.userName} /></div>
      </div>
      <a  style={{ backgroundColor: "#AF05E1", borderRadius: "7px", color: "white" }} onClick={downloadQR} className="btn save-user float-end">{props.t("Download")}</a>
    </ModalBody>
  </Modal>
  )
}


QrCodeModal.propTypes = {
 
  modal: PropTypes.bool,
  continue:PropTypes.func,
   payment:PropTypes.func,
   back:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
   customerName :PropTypes.string,
}


export default withRouter(
    (withTranslation()(QrCodeModal))
  )
