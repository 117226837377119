import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal, ModalHeader, ModalBody, Row, InputGroup, Alert, Col, Button
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import {
  getUser as onGetUser,
  checkUserName as onCheckUserName,
  resetDashboard as onResetDashboard,
  addProfile as onAddProfile,
  getProfiles as onGetProfiles,
  loginProfile as onLoginProfile


} from "store/actions"
import "./datatables.scss"
// users

import { useSelector, useDispatch } from "react-redux"
import user1 from "../../../assets/images/User-Avatar-Profile-PNG-Free-File-Download.png"
import { authorize,Role} from "components/helpers/authorize"
import { isEmpty } from "lodash"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [addProfileModal, setAddProfileModal] = useState(false)
  const { error, User, message, newProfileSuccess, Profiles, changeProfile } = useSelector(state => ({
    error: state.Dashboard?.error,
    User: state.Dashboard?.user,
    message: state.Dashboard?.message,
    newProfileSuccess: state?.Dashboard?.newProfileSuccess,
    Profiles: state.Dashboard?.profiles,
    changeProfile: state.Login?.changeProfile
  }))
  useEffect(() => {
    dispatch(onGetUser(user?.uid))
    
      // dispatch(onGetProfiles(User?.email))
     
    
      // dispatch(onGetProfiles(user?.email))
   

 
  }, [dispatch, newProfileSuccess]);
  useEffect(() => {
   if(isEmpty(Profiles)){
    dispatch(onGetProfiles(User?.email))
   }
  
  }, [User]);


  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
 
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setusername(obj.userName)
      }
    }
  }, [props.success])
  const profileLogin = (mail, name) => {

    const newProfiles = {
      email: mail,
      name: name
    }

    dispatch(onLoginProfile(newProfiles))



  }

  if (changeProfile == true) {
    setTimeout(function () {
      location.reload()
    }, 2300);

  }
  const addProfileToggle = () => {
    setAddProfileModal(!addProfileModal)
  }
  const handleValidProductSubmit = (e, values) => {

    const newProfile = {
      name: document.getElementById("userName").value,
      email: user.email,
    }

    dispatch(onAddProfile(newProfile))

    addProfileToggle()

  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />

          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          
          {User?.corporate && User?.corporate == true ? (
            <DropdownItem tag="a" href="/companyInfo">
              {" "}
              <i className="bx bx-buildings font-size-16 align-middle me-1" />
              {props.t("Şirket Bilgilerim")}{" "}
            </DropdownItem>
          
          ) : Profiles?.map((profile, index) => (
            <DropdownItem 
            //style={{ backgroundColor: profile?.name == user.userName ? "#c3f1c3" : null }} 
            onClick={() => ( 
              profileLogin(profile.email, profile.name))}>
              {" "}
              {profile?.name == user.userName ?(<i style={{color:"#AF05E1"}} className="bx bx-check-circle font-size-20 align-middle me-1" /> ):null}
              
              <i className="bx bx-user font-size-16 align-middle me-1" /> 
             {profile?.name}{" "}
            </DropdownItem> 
          ))}

          {User?.corporate && User?.corporate == true ? (null) :
            Profiles?.length < 5 ? (
              <DropdownItem onClick={() => (
                addProfileToggle())}>
                {" "}
                <i className="bx bx-user font-size-16 align-middle me-1" />
                + Profile Ekle{" "}
              </DropdownItem>
            ) : null}


          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={addProfileModal} toggle={addProfileToggle}>
        <ModalHeader toggle={addProfileToggle} tag="h4">
          Profil Ekle
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidProductSubmit} >
            <Row form>
              <InputGroup className="mb-3">
                <div className="input-group-text">bio.gotodo.Link/</div>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  placeholder={props.t("UserName")}

                  onChange={e => {
                    //const myTimeout = setTimeout(myGreeting, 5000);

                    dispatch(onResetDashboard())

                    const userData = {
                      name: e.target.value
                    }
                    dispatch(onCheckUserName(userData))
                  }}
                />
                {error && document.getElementById("userName")?.value ? (

                  <Alert id="TooltipBottom" color="danger" className="alert-pos"

                    style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99", cursor: "pointer" }}>

                    <i className="bx bx-error-circle"></i>

                  </Alert>
                ) : message && document.getElementById("userName")?.value ? (
                  <Alert id="TooltipBottom" className="alert-pos" color="success" style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99" }}>
                    <i className="bx bx-comment-check"></i>
                  </Alert>
                ) : <Alert id="TooltipBottom" color="danger" className="alert-pos"

                  style={{ position: "absolute", height: "36px", left: "411px", zIndex: "99", cursor: "pointer" }}>

                  <i className="bx bx-error-circle"></i>

                </Alert>}

              </InputGroup>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    {props.t("Profil Ekle")}
                  </button>
                  &nbsp;&nbsp;
                  <Button onClick={() => { addProfileToggle() }}>
                    {props.t("Cancel")}
                  </Button>

                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
